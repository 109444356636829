import React, { useState } from "react";
import "../demandletter.css";
import { Button, Steps } from "antd";
import MaterialIcon from "material-icons-react";
import UploadedFile from "./UploadedFile";
 

function DemandViewStepper({title}) {
    const [current, setCurrent] = useState(0);
    const steps = [
        {
            title: "Step 1",
            subTitle: "Demand Letter",
            description: "Uploaded",
            key: 1,
            content:<UploadedFile title={title}/>,
            icon: <MaterialIcon color="#147CBC" icon="keyboard_alt" />,
        },

        {
            title: "Step 2",
            subTitle: "Job Positions",
            description: "PENDING",
            key: 2,
            content: <div>sddds</div>,
            icon: <MaterialIcon color="#147CBC" icon="keyboard_alt" />,
        },


    ];

    const items = steps.map((item) => ({
        key: item.key,
        title: item.title,
        subTitle: <div className="">{item.subTitle}</div>,
        icon: item.icon,
        description: (
            <div className="">
                <div className="bg-lightPurple rounded-[63px] text-center px-5 py-1 mr-2 border-[1px] border-[#ECECEC]">
                    <span className="prof-card-badge">{item.description}</span>
                </div>
            </div>
        ),
    }));

    return (
        <>
            <div className="mr-3 items-center flex h-[235px]  bg-white border border-gray-200 mt-[10px]">
                <Steps
                    className="w-[50%] ml-[54px]"
                    current={current}
                    size="small"
                    labelPlacement="vertical"
                    items={items}
                /> 
            </div>
            <div className="mr-3  flex h-auto bg-white border border-gray-200 mt-[10px]">
                <div className="w-full">{steps[current].content}</div>
            </div>
        </>
    );
}

export default DemandViewStepper;
