import { Table, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatImgPath } from "../../../../utils";
import {
  cleanUpClients,
  cleanUpEditBasic,
  fetchClients,
  fetchClientsByUser,
  setClientEditId,
} from "../../../../features/client/clientSlice";
import { setClientAdminView } from "../../../../features/admin/adminSlice";

function ClientTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { clientData, clientLoading, clientCount } = useSelector(
    (state) => state.client
  );
  const { user } = useSelector((state) => state.auth);
  const { singleAdminUser, userEditId } = useSelector((state) => state.admin);
  const { agencyObj } = useSelector((state) => state.agency);

  const [searchText, setSearchText] = useState("");

  const handleView = async (id) => {
    await dispatch(cleanUpEditBasic());
    await dispatch(cleanUpClients());
    await dispatch(setClientEditId(id));
    navigate("/client-tabs");
    dispatch(setClientAdminView(true));
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchClientDetails(page, size) {
    const res = await dispatch(
      fetchClientsByUser({
        cntInstId: user?.usrInstId,
        cntCreatedBy: userEditId,
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }

  useEffect(() => {
    fetchClientDetails();
  }, []);
  const columns = [
    {
      title: "Client Name",
      render: (item) => (
        <>
          <div className="grid grid-cols-2">
            <div className="flex items-center">
              <img
                className="w-9 h-9 rounded-full object-cover"
                src={
                  item?.cntIcon
                    ? formatImgPath(item?.cntIcon)
                    : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
                }
                alt="Avatar"
              />
              <span className="table-name mx-3">{item?.cntName}</span>
            </div>
            <div className="flex items-center">
              <button className="p-0 mx-4 flex items-end" type="button">
                <MaterialIcon
                  color="#000"
                  icon="visibility"
                  onClick={() => handleView(item?.cntId)}
                />
              </button>
              <button className="p-0 m-0 flex items-end" type="button">
                <MaterialIcon color="#000" icon="more_vert" />
              </button>
            </div>
          </div>
        </>
      ),
      width: "300px",
    },
    {
      title: "No. of jobs",
      dataIndex: "jobCount",
      width: "120px",
    },
    {
      title: "Country",
      dataIndex: "cntCountry",
      width: "280px",
    },
    {
      title: "Location",
      dataIndex: "cntLocation",
      width: "280px",
    },
    {
      title: "Owner",
      dataIndex: "uploadedBy",
      width: "200px",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "280px",
    },
    {
      title: "Phone",
      dataIndex: "mobileNumber",
      width: "280px",
      ellipsis: true,
      tooltip: { placement: "topLeft" },
    },
  ];

  //  const clienstByUser =  clientData?.filter((item)=>item?.)
  const filteredData = searchText
    ? clientData?.filter((item) =>
        item?.cntName?.toLowerCase().includes(searchText.toLowerCase())
      )
    : clientData;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));
  return (
    <>
      <div className="min-h-[895px] bg-white border border-gray-200  mt-[1%] p-[39px]">
        <div className="text-neutral-900 text-2xl font-medium font-dmSans leading-9">
          These are the clients {singleAdminUser?.usrFirstName} has added
        </div>
        <div className="h-24 bg-[#FBFBFB] rounded-sm pt-[14px] pb-[19px] ">
          <div className="h-[63px] bg-white rounded-[3px] flex text-center items-center">
            <form class="flex items-center w-full px-10">
              <div class="relative w-full">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 mr-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="voice-search"
                  className="bg-gray-50 !border-none text-black1 text-[19px] font-normal font-dmSans leading-7 rounded-lg  block w-full pl-10 p-2.5  h-[52px] px-[18px]
                        dark:placeholder-gray-400 dark:text-white "
                  placeholder={`Search  ${
                    agencyObj?.licenseName == "LOCAL LICENSE"
                      ? "Local"
                      : "Foreign"
                  } employer name or keyword`}
                  required
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="text-blueDark text-[1rem] font-medium font-dmSans leading-normal
                    w-[194px] h-[52px] px-[18px] py-[19px] bg-blue-50 rounded shadow border border-[#F6F6F6] justify-center items-center gap-2 inline-flex"
              >
                Search
              </button>
            </form>
          </div>
        </div>

        <div className="mt-[20px]">
          {clientLoading ? (
            <Skeleton />
          ) : (
            <Table
              rowSelection={true}
              size={"middle"}
              bordered={true}
              pagination={{
                position: ["bottomCenter"],
                current: pageIndex + 1,
                total: clientCount,
                pageSize: pageSize,
                onChange: (page, size) => {
                  setPageIndex(page - 1);
                  setPageSize(size);
                  fetchClientDetails(page - 1, size);
                },
                showSizeChanger: false,
                hideOnSinglePage: true,
              }}
              rowKey={(record) => record?.cntId}
              columns={tableColumns}
              dataSource={filteredData}
              scroll={"scroll"}
              className="overflow-x-scroll"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ClientTable;
