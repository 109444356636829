import React, { useEffect, useRef, useState } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import {
  fetchSingleJob,
  saveJob,
  setEditJobDescription,
  setViewJobDescription,
} from "../../../../features/jobs/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Form, Input, Spin } from "antd";
import { checkEditPermission } from "../../../../utils";

const TextEditor = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { menuSidebarData } = useSelector((state) => state.admin);
  const { singleCreateJob, jobEditId, jobLoading, singleJob } = useSelector(
    (state) => state.jobs
  );
  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const handleCancel = () => {
    dispatch(setEditJobDescription(false));
    dispatch(setViewJobDescription(true));
  };

  const [formData, setFormData] = useState(singleJob);

  useEffect(() => {
    setFormData(singleJob);
  }, [singleJob]);

  function onChangeBrief(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobBrief: value,
    }));
  }
  function onChangeRequirement(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobRequirements: value,
    }));
  }
  function onChangeResponsibilities(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobResponsibilities: value,
    }));
  }
  function onChangeApplication(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobApplicationProcess: value,
    }));
  }

  const onFinish = async (data) => {
    const res = await dispatch(
      saveJob({
        jobId: formData?.jobId,
        jobBrief: formData?.jobBrief,
        jobRequirements: formData?.jobRequirements,
        jobResponsibilities: formData?.jobResponsibilities,
        jobInstId: user?.usrInstId,
      })
    );
    if (res?.payload?.success) {
      await dispatch(setViewJobDescription(true));
      await dispatch(setEditJobDescription(false));
      await dispatch(
        fetchSingleJob({
          jobId: jobEditId ? jobEditId : singleCreateJob?.jobId,
        })
      );
      toast.success("Details saved successfully");
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  return (
    <div>
      <div className=" text-black1 text-[22px] font-normal font-dmSans leading-[33px] mb-[2%]">
        Briefly describe what the job entails and specify the job requirements.
      </div>
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-1 ">
          <Form.Item
            className="w-full"
            label="Job summary"
            rules={[
              {
                required: true,
                message: "Please add job summary",
              },
            ]}
          >
            <ReactQuill
              required
              value={formData?.jobBrief}
              onChange={(value) => {
                onChangeBrief(value);
              }}
              name="jobBrief"
              theme="snow"
              modules={modules}
              formats={formats}
              placeholder="Add description"
              style={{ height: "220px", marginBottom: "5%" }}
            />
          </Form.Item>

          <Form.Item
            className="w-full"
            label="Job requirements"
            rules={[
              {
                required: true,
                message: "Please add  job requirements",
              },
            ]}
          >
            <ReactQuill
              required
              value={formData?.jobRequirements}
              onChange={(value) => {
                onChangeRequirement(value);
              }}
              name="jobRequirements"
              theme="snow"
              modules={modules}
              formats={formats}
              placeholder="Add description"
              style={{ height: "220px", marginBottom: "5%" }}
            />
          </Form.Item>

          <Form.Item
            className="w-full"
            label="Job responsibilities"
            rules={[
              {
                required: true,
                message: "Please add  job responsibilities",
              },
            ]}
          >
            <ReactQuill
              required
              value={formData?.jobResponsibilities}
              onChange={(value) => {
                onChangeResponsibilities(value);
              }}
              name="jobResponsibilities"
              theme="snow"
              modules={modules}
              formats={formats}
              placeholder="Add description"
              style={{ height: "220px", marginBottom: "5%" }}
            />
          </Form.Item>
        </div>

        <div className="flex items-center justify-between mt-[35px]">
          <div className="flex items-start"></div>
          <div className="flex gap-5 items-center">
            <button
              type="button"
              className="w-[200px] cancel-btn border border-blueDark flex items-center justify-center"
              onClick={handleCancel}
            >
              Cancel
            </button>
            {checkEditPermission(menuSidebarData, "JOB_ADVERTS") && (
              <button
                className="save-btn w-[200px]"
                type="submit"
                disabled={jobLoading}
              >
                {jobLoading ? <Spin /> : "Save"}
              </button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TextEditor;
