import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import "../../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { rolesOptions } from "../../../Gender";
import SearchField from "../SearchField";
import {
  cleanMemberDetails,
  fetchPanelistTitles,
  fetchPanels, 
  savePanelist,
  setMemberName,
} from "../../../features/panel/panelSlice";

const AddPanelMembersModal = ({
  isModalOpen,
  setIsModalOpen,
  prodd,
  panelId,
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ ...prodd });

  function handleSelectChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      pmeRole: value,
    }));
  }

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleNumberChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      pmePhone: String(e),
    }));
  };

  const [data, setdata] = useState({});
  const {
    panelActionLoading,
    memberName,
    memberEmail,
    memberPhone,
    memberRole,
    memberText,
    panelTitle,
  } = useSelector((state) => state.panel);

  async function fetchPanelDetailsData() {
    await dispatch(
      fetchPanels({
        paInstId: user?.usrInstId,
      })
    );
  }

  const onFinish = async (data) => {
    if (!memberName && !formData?.pmeName) {
      toast.error("Please fill the member name");
      return;
    }
    const res = await dispatch(
      savePanelist({
        pmePanId: panelId ? panelId : formData.panPaId,
        pmeId: memberText
          ? memberName
          : formData?.pmeId
          ? formData?.pmeId
          : null,
        // pmeName: formData?.pmeName ? formData?.pmeName :!memberText ? memberName  : memberText,
        pmeName: !memberText ? memberName : memberText,
        pmeRole: formData?.pmeRole ? formData?.pmeRole : memberRole,
        pmeEmail: formData?.pmeEmail ? formData?.pmeEmail : memberEmail,
        pmePhone: formData?.pmePhone ? formData?.pmePhone : memberPhone,
        pmeInstId: user?.usrInstId,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      fetchPanelDetailsData();
      setFormData("");
      setFormData({
        pmeName: "",
      });
      cleanMemberDetailsData();
      form.resetFields();
      handleCancel();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  async function cleanMemberDetailsData() {
    dispatch(cleanMemberDetails());
  }

  async function fetchPanelTitleData() {
    await dispatch(fetchPanelistTitles());
  }

  useEffect(() => {
    setFormData(prodd);
    if (prodd?.pmeId) {
      dispatch(setMemberName(prodd?.pmeName));
    }
  }, [prodd]);

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title="Add Panel Member"
        open={isModalOpen}
        onCancel={handleCancel}
        width={850}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=""
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="grid grid-cols-2 gap-x-5">
            <Form.Item
              label="Member Name"
              rules={[
                {
                  required: true,
                  message: "Please add member name",
                },
              ]}
            >
              <SearchField formData={formData?.pmeName} />
            </Form.Item>

            <Form.Item
              label="Member Role"
              rules={[
                {
                  required: true,
                  message: "Please add member role",
                },
              ]}
            >
              <Select
                className="activity-select-tag activity-select-icon"
                allowClear
                style={{
                  width: "100%",
                  height: "44px",
                }}
                placeholder="Please select Member Role"
                onChange={(value) => {
                  handleSelectChange(value);
                }}
                value={formData?.pmeRole ? formData?.pmeRole : memberRole}
                name="pmeRole"
                options={
                  panelTitle?.length > 0 &&
                  panelTitle?.map((item) => ({
                    value: item?.patName,
                    label: item?.patName,
                  }))
                }
                onDropdownVisibleChange={() => {
                  fetchPanelTitleData();
                }}
              />
            </Form.Item>

            <Form.Item
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please add email address",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input
                required
                name="pmeEmail"
                value={formData?.pmeEmail ? formData?.pmeEmail : memberEmail}
                onChange={onChange}
                type="email"
                className="input"
              />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please input phone number",
                },
              ]}
            >
              <InputNumber
                required
                name="pmePhone"
                value={formData?.pmePhone ? formData?.pmePhone : memberPhone}
                maxLength={10}
                minLength={9}
                onChange={handleNumberChange}
                className="input"
              />
            </Form.Item>
          </div>

          <div className="flex justify-between py-[50px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center">
              <button
                type="button"
                key="back"
                onClick={handleCancel}
                className="w-[200px] cancel-btn"
              >
                Cancel
              </button>

              <button
                type="submit"
                key="submit"
                className="w-[200px] save-btn"
                disabled={panelActionLoading}
              >
                {panelActionLoading ? <Spin /> : "Save"}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddPanelMembersModal;
