import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  referallData: [],
  referalLoading: false,
  refetch: false,
  applicantRecommendedData: [],
};

export const saveJobRecommendation = createAsyncThunk(
  "cv/saveJobRecommendation",
  async (data) => {
    const res = await axiosInstance.post(
      `${url}/nea/saveJobRecommendation.action`,
      data
    );
    return res.data;
  }
);

export const fetchJobRecommendations = createAsyncThunk(
  "recommedations/fetchJobRecommendations",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobRecommendations.action?jbrUsrId=${data?.jbrUsrId}&jbrJobId=${data?.jbrJobId}&jbrType=REFERRAL`
    );
    return res.data?.data?.result;
  }
);
export const fetchApplicantRecommendations = createAsyncThunk(
  "recommedations/fetchApplicantRecommendations",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobRecommendations.action?jbrJobId=${data?.jbrJobId}`
    );
    return res.data?.data?.result;
  }
);

export const recommedationsSlice = createSlice({
  name: "recommedations",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    setRefetch: (state) => {
      state.refetch = !state.refetch;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveJobRecommendation.pending, (state) => {
        state.referalLoading = true;
      })
      .addCase(saveJobRecommendation.fulfilled, (state, action) => {
        state.referalLoading = false;
      })
      .addCase(saveJobRecommendation.rejected, (state) => {
        state.referalLoading = false;
      })

      .addCase(fetchJobRecommendations.pending, (state) => {
        state.referalLoading = true;
      })
      .addCase(fetchJobRecommendations.fulfilled, (state, action) => {
        state.referalLoading = false;
        state.referallData = action.payload;
      })
      .addCase(fetchJobRecommendations.rejected, (state) => {
        state.referalLoading = false;
      })

      .addCase(fetchApplicantRecommendations.pending, (state) => {
        state.referalLoading = true;
      })
      .addCase(fetchApplicantRecommendations.fulfilled, (state, action) => {
        state.referalLoading = false;
        state.applicantRecommendedData = action.payload;
      })
      .addCase(fetchApplicantRecommendations.rejected, (state) => {
        state.referalLoading = false;
        state.applicantRecommendedData =[];
      });
  },
});

export default recommedationsSlice.reducer;
export const { handleSidenavFullWidth } = recommedationsSlice.actions;
