import { Tabs } from "antd";
import React, { useState } from "react";
import MyEmployeesTable from "./my-employees/MyEmployeesTable";
import LocalPlacementTable from "./LocalPlacementTable";
import ForeignPlacementTable from "./ForeignPlacementTable";
import {
  setRefetch,
  setRefetchKey,
} from "../../features/myEmployees/myEmployeesSlice";
import { useDispatch } from "react-redux";

function EmployeesTabs() {
  const { TabPane } = Tabs;

  const [active, setactive] = useState("1");
  const dispatch = useDispatch();

  function refetching(activeKey) {
    dispatch(setRefetch());
    dispatch(setRefetchKey(activeKey));
    setactive(activeKey);
  }

  const tabConfigurations = [
    {
      label: (
        <span className="flex items-center">
          <span className="">My Employees</span>
        </span>
      ),
      key: "1",
      children: <MyEmployeesTable />,
    },
    {
      label: (
        <span className="flex items-center">
          <span className="">Local placements</span>
        </span>
      ),
      key: "2",
      children: <LocalPlacementTable />,
    },
    {
      label: (
        <span className="flex items-center">
          <span className="">Foreign Placements</span>
        </span>
      ),
      key: "3",
      children: <ForeignPlacementTable />,
    },
  ];
  return (
    <div className="h-auto">
      <Tabs
        activeKey={active}
        onChange={refetching}
        items={tabConfigurations}
        defaultActiveKey="1"
        className="employees-bar employees-tab"
      />
    </div>
  );
}

export default EmployeesTabs;
