import React from 'react';
import { Checkbox, Divider } from 'antd';
import { Radio } from 'antd';
import { cashConverter } from '../../../utils';

const LicenseCard = ({ style, licenseType, price, selectedButton, hide }) => {
	return (
		<div className='flex flex-col border-2 border-blueDark rounded-[8px] w-full h-[350px] items-start p-5'>
			{hide ? (
				<div className='mt-5'></div>
			) : (
				<Radio className='' value={selectedButton}></Radio>
			)}

			<div className='mt-5'>
				<div
					style={style}
					className='text-lg font-[600] w-[100%] rounded px-2 py-1'
				>
					{licenseType}
				</div>
				<div className='text-lg font-[500] mt-3'>Agent license</div>
				<Divider className='my-5 w-[100%]' />
				<div className='font-bold text-3xl'>{cashConverter(price)}</div>
				<p className='text-sm mt-3 text-black'>First time purchase</p>
				<Divider className='' />
			</div>
		</div>
	);
};

export default LicenseCard;
