import {
  Button,
  Collapse,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Spin,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleLicenseStep } from "../../../../features/license/licenseSlice";
import {
  mpesaPush,
  savePaymentOption,
} from "../../../../features/payment/paySlice";
import toast from "react-hot-toast";
import {
  fetchAgencyData,
  saveAgency,
} from "../../../../features/agency/agencySlice";
import FileUploader from "../../../../components/FileUploader";
import { customToast } from "../../../../notifications";
import { CloudUploadOutlined } from "@ant-design/icons";

const { Option } = Select;
const url = process.env.REACT_APP_API_BASE_URL;

export default function PaymentOptions({ option, amount }) {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const { agencyObj } = useSelector((state) => state.agency);
  const { user } = useSelector((state) => state.auth);
  const { payLoading, newInvoice, payMethods } = useSelector(
    (state) => state.pay
  );
  const { agentLicence, licenseLoading } = useSelector(
    (state) => state.license
  );

  const [value, setValue] = useState(1);
  const [submitted, setsubmitted] = useState(false);
  const [data, setdata] = useState({});
  const [fileUrl, setfileUrl] = useState("");

  let selectedOption = payMethods?.find((item) => item?.payId === option);
  const license = agentLicence?.find(
    (item) => item?.licId === agencyObj?.instLicId
  );

  const handleSuccess = (response) => {
    setfileUrl(response.jsonData);
  };

  const handleError = (response) => {
    // Handle error logic
    console.error("File upload failed:", response);
  };

  const onChange = (e) => {
    console.log("radio checked", e);
    // setValue(e.target.value);
  };

  async function onFinish(val) {
    const payObj = {
      phone: val?.phone,
      amount: amount,
      accountref: newInvoice?.invoCode,
    };
    const res = await dispatch(mpesaPush(payObj));
    if (res?.payload?.success) {
      setsubmitted(true);
      toast.success(res?.payload?.messages?.message);
    }
  }

  async function handleCancel() {
    await setfileUrl("");
  }

  async function onFinish2(val) {
    val.rcptChqDrawer = fileUrl;
    val.rcptBankName = selectedOption?.bankName;
    val.rcptAmount = license?.licAmount;
    val.rcptInstId = user?.usrInstId;
    val.rcptValueDate = new Date();

    if (!val.rcptChqDrawer) {
      return await customToast({
        content: "Please upload a receipt proof of payment",
        bdColor: "info",
        header: "Missing File",
      });
    }
    const res = await dispatch(savePaymentOption(val));

    if (res?.payload?.success) {
      let instCopy = {
        instId: agencyObj?.instId,
        instStatus: "PAYMENT_PENDING_CONFIRMATION",
      };

      await dispatch(saveAgency(instCopy));
      await handleFetchInstitutionObj();
    } else {
      await customToast({
        content: res?.payload?.messages?.message,
        bdColor: "error",
        header: "Error",
      });
    }
  }

  async function handleFetchInstitutionObj() {
    await dispatch(fetchAgencyData(user?.usrInstId));
  }

  async function handleCheckPayment() {
    const res = await dispatch(fetchAgencyData(user?.usrInstId));
    console.log(res?.payload);
    if (res?.payload?.length && res?.payload[0].instStatus === 'LICENSE_PAID') {
    	await customToast({
        content: "Payment verified",
        bdColor: "success",
        header: "Payment Successful",
      });
    }else{
      await customToast({
        content: "Your payment could not be verified",
        bdColor: "",
        header: "Info",
      });
    }
  }

  function handleSelectChange2(value) {
    setdata((prevData) => ({
      ...prevData,
      blkBeneficiaryBank: value,
    }));
  }

  useEffect(() => {}, [agencyObj]);

  return (
    <>
      {option === 1 ? (
        <>
          <div className="mt-10">
            <span className="license-mpesa-choice">M-PESA STK Push</span>
          </div>

          <div className="my-10 w-full">
            <Radio.Group className="w-full" value={value}>
              <Radio value={1} className="license-mpesa-choice">
                Send Money
              </Radio>
            </Radio.Group>
          </div>

          {value === 1 ? (
            <div>
              {submitted ? (
                <>
                  <div className="p-3 bg-white license-pay-submit-text">
                    You will receive a prompt on your mobile phone. Once you
                    have completed the payment, click “Next” to proceed.
                  </div>

                  <button
                    // disabled={loading}
                    type="button"
                    onClick={handleCheckPayment}
                    className="bg-blueDark rounded-[35px] text-white w-[200px] p-2 mt-10"
                  >
                    Next
                  </button>
                </>
              ) : (
                <Form
                  layout="vertical"
                  ref={formRef}
                  name="control-ref"
                  onFinish={onFinish}
                  style={{
                    maxWidth: "100%",
                  }}
                  form={form}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please add mpesa number",
                      },
                    ]}
                    name="phone"
                    label="M-PESA Phone Number"
                  >
                    <InputNumber
                      maxLength={10}
                      className="input"
                    />
                  </Form.Item>

                  <button
                    disabled={payLoading}
                    type="submit"
                    className="bg-blueDark rounded-[35px] text-white w-[200px] p-2 mt-5"
                  >
                    {payLoading ? <Spin /> : "Next"}
                  </button>
                </Form>
              )}
            </div>
          ) : null}
        </>
      ) : option === 0 ? (
        <>
          <div className="mt-10">
            <span className="license-mpesa-choice">
              Please fill in the below details
            </span>

            <Form
              layout="vertical"
              ref={formRef}
              name="control-ref"
              onFinish={onFinish2}
              style={{
                maxWidth: "100%",
                marginTop: "30px",
              }}
              form={form1}
              initialValues={{
                blkPaymentAmount: amount,
              }}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Reference number from the bank slip is required",
                  },
                ]}
                name={"rcptReference"}
                label="Reference No."
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Upload your deposit slip/ proof of purchase here",
                  },
                ]}
                name={"rcptChqDrawer"}
                label="Upload Bank Slip"
              >
                {fileUrl ? (
                  <>
                    <div className="border-dashed border-2 border-[#BDBDBD] w-full h-auto flex justify-center items-center flex-col my-5">
                      <div className="flex flex-col">
                        <span className="text-black333 font-medium text-[24px] my-5 flex items-center">
                          <CloudUploadOutlined
                            style={{
                              fontSize: 27,
                              color: "#333",
                              marginRight: 10,
                            }}
                          />
                          {fileUrl?.split("_").pop()}
                        </span>

                        <div className="flex items-center">
                          <button
                            // disabled={loading}
                            type="button"
                            onClick={handleCancel}
                            className="border-2 border-blueDark rounded-[35px] text-blueDark w-[200px] mb-5 p-2"
                          >
                            Change Document
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="border-dashed border-2 border-[#BDBDBD] w-full h-[330px] flex justify-center items-center flex-col mt-5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="169"
                        height="162"
                        viewBox="0 0 169 162"
                        fill="none"
                      >
                        <g opacity="0.5">
                          <path
                            d="M17.0703 80.7396C17.0703 89.2003 18.8145 97.5781 22.2032 105.395C25.592 113.211 30.559 120.314 36.8206 126.296C43.0822 132.279 50.5159 137.025 58.6971 140.262C66.8783 143.5 75.6468 145.167 84.5021 145.167C93.3573 145.167 102.126 143.5 110.307 140.262C118.488 137.025 125.922 132.279 132.184 126.296C138.445 120.314 143.412 113.211 146.801 105.395C150.19 97.5781 151.934 89.2003 151.934 80.7396C151.934 72.2789 150.19 63.9011 146.801 56.0844C143.412 48.2678 138.445 41.1654 132.184 35.1828C125.922 29.2002 118.488 24.4545 110.307 21.2167C102.126 17.979 93.3573 16.3125 84.5021 16.3125C75.6468 16.3125 66.8783 17.979 58.6971 21.2167C50.5159 24.4545 43.0822 29.2002 36.8206 35.1828C30.559 41.1654 25.592 48.2678 22.2032 56.0844C18.8145 63.9011 17.0703 72.2789 17.0703 80.7396Z"
                            fill="#D9EDFF"
                          />
                          <path
                            d="M44.6133 48.3984H107.805V105.223H44.6133V48.3984Z"
                            fill="white"
                          />
                          <path
                            d="M44.6133 48.3984H107.805V105.223H44.6133V48.3984Z"
                            stroke="#020064"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M60.4078 54.6091C60.4169 54.9644 60.3516 55.3178 60.2156 55.6486C60.0797 55.9793 59.8758 56.2808 59.6161 56.5351C59.3563 56.7895 59.0459 56.9916 58.7032 57.1296C58.3604 57.2676 57.9923 57.3387 57.6203 57.3387C57.2484 57.3387 56.8802 57.2676 56.5375 57.1296C56.1947 56.9916 55.8844 56.7895 55.6246 56.5351C55.3649 56.2808 55.161 55.9793 55.025 55.6486C54.8891 55.3178 54.8237 54.9644 54.8329 54.6091V41.2921C54.8237 40.9368 54.8891 40.5834 55.025 40.2526C55.161 39.9219 55.3649 39.6204 55.6246 39.3661C55.8844 39.1117 56.1947 38.9096 56.5375 38.7716C56.8802 38.6336 57.2484 38.5625 57.6203 38.5625C57.9923 38.5625 58.3604 38.6336 58.7032 38.7716C59.0459 38.9096 59.3563 39.1117 59.6161 39.3661C59.8758 39.6204 60.0797 39.9219 60.2156 40.2526C60.3516 40.5834 60.4169 40.9368 60.4078 41.2921V54.6091Z"
                            fill="#B0D9FF"
                          />
                          <path
                            d="M60.4078 54.6091C60.4169 54.9644 60.3516 55.3178 60.2156 55.6486C60.0797 55.9793 59.8758 56.2808 59.6161 56.5351C59.3563 56.7895 59.0459 56.9916 58.7032 57.1296C58.3604 57.2676 57.9923 57.3387 57.6203 57.3387C57.2484 57.3387 56.8802 57.2676 56.5375 57.1296C56.1947 56.9916 55.8844 56.7895 55.6246 56.5351C55.3649 56.2808 55.161 55.9793 55.025 55.6486C54.8891 55.3178 54.8237 54.9644 54.8329 54.6091V41.2921C54.8237 40.9368 54.8891 40.5834 55.025 40.2526C55.161 39.9219 55.3649 39.6204 55.6246 39.3661C55.8844 39.1117 56.1947 38.9096 56.5375 38.7716C56.8802 38.6336 57.2484 38.5625 57.6203 38.5625C57.9923 38.5625 58.3604 38.6336 58.7032 38.7716C59.0459 38.9096 59.3563 39.1117 59.6161 39.3661C59.8758 39.6204 60.0797 39.9219 60.2156 40.2526C60.3516 40.5834 60.4169 40.9368 60.4078 41.2921V54.6091Z"
                            fill="#B0D9FF"
                            stroke="#020064"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M78.9937 54.6091C79.0029 54.9644 78.9375 55.3178 78.8016 55.6486C78.6656 55.9793 78.4617 56.2808 78.202 56.5351C77.9422 56.7895 77.6319 56.9916 77.2891 57.1296C76.9464 57.2676 76.5782 57.3387 76.2063 57.3387C75.8343 57.3387 75.4662 57.2676 75.1234 57.1296C74.7807 56.9916 74.4703 56.7895 74.2106 56.5351C73.9508 56.2808 73.7469 55.9793 73.611 55.6486C73.475 55.3178 73.4097 54.9644 73.4188 54.6091V41.2921C73.4097 40.9368 73.475 40.5834 73.611 40.2526C73.7469 39.9219 73.9508 39.6204 74.2106 39.3661C74.4703 39.1117 74.7807 38.9096 75.1234 38.7716C75.4662 38.6336 75.8343 38.5625 76.2063 38.5625C76.5782 38.5625 76.9464 38.6336 77.2891 38.7716C77.6319 38.9096 77.9422 39.1117 78.202 39.3661C78.4617 39.6204 78.6656 39.9219 78.8016 40.2526C78.9375 40.5834 79.0029 40.9368 78.9937 41.2921V54.6091Z"
                            fill="#B0D9FF"
                          />
                          <path
                            d="M78.9937 54.6091C79.0029 54.9644 78.9375 55.3178 78.8016 55.6486C78.6656 55.9793 78.4617 56.2808 78.202 56.5351C77.9422 56.7895 77.6319 56.9916 77.2891 57.1296C76.9464 57.2676 76.5782 57.3387 76.2063 57.3387C75.8343 57.3387 75.4662 57.2676 75.1234 57.1296C74.7807 56.9916 74.4703 56.7895 74.2106 56.5351C73.9508 56.2808 73.7469 55.9793 73.611 55.6486C73.475 55.3178 73.4097 54.9644 73.4188 54.6091V41.2921C73.4097 40.9368 73.475 40.5834 73.611 40.2526C73.7469 39.9219 73.9508 39.6204 74.2106 39.3661C74.4703 39.1117 74.7807 38.9096 75.1234 38.7716C75.4662 38.6336 75.8343 38.5625 76.2063 38.5625C76.5782 38.5625 76.9464 38.6336 77.2891 38.7716C77.6319 38.9096 77.9422 39.1117 78.202 39.3661C78.4617 39.6204 78.6656 39.9219 78.8016 40.2526C78.9375 40.5834 79.0029 40.9368 78.9937 41.2921V54.6091Z"
                            fill="#B0D9FF"
                            stroke="#020064"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M97.5797 54.6091C97.5888 54.9644 97.5235 55.3178 97.3875 55.6486C97.2515 55.9793 97.0477 56.2808 96.7879 56.5351C96.5282 56.7895 96.2178 56.9916 95.8751 57.1296C95.5323 57.2676 95.1641 57.3387 94.7922 57.3387C94.4203 57.3387 94.0521 57.2676 93.7094 57.1296C93.3666 56.9916 93.0562 56.7895 92.7965 56.5351C92.5367 56.2808 92.3329 55.9793 92.1969 55.6486C92.0609 55.3178 91.9956 54.9644 92.0047 54.6091V41.2921C91.9956 40.9368 92.0609 40.5834 92.1969 40.2526C92.3329 39.9219 92.5367 39.6204 92.7965 39.3661C93.0562 39.1117 93.3666 38.9096 93.7094 38.7716C94.0521 38.6336 94.4203 38.5625 94.7922 38.5625C95.1641 38.5625 95.5323 38.6336 95.8751 38.7716C96.2178 38.9096 96.5282 39.1117 96.7879 39.3661C97.0477 39.6204 97.2515 39.9219 97.3875 40.2526C97.5235 40.5834 97.5888 40.9368 97.5797 41.2921V54.6091Z"
                            fill="#B0D9FF"
                          />
                          <path
                            d="M97.5797 54.6091C97.5888 54.9644 97.5235 55.3178 97.3875 55.6486C97.2515 55.9793 97.0477 56.2808 96.7879 56.5351C96.5282 56.7895 96.2178 56.9916 95.8751 57.1296C95.5323 57.2676 95.1641 57.3387 94.7922 57.3387C94.4203 57.3387 94.0521 57.2676 93.7094 57.1296C93.3666 56.9916 93.0562 56.7895 92.7965 56.5351C92.5367 56.2808 92.3329 55.9793 92.1969 55.6486C92.0609 55.3178 91.9956 54.9644 92.0047 54.6091V41.2921C91.9956 40.9368 92.0609 40.5834 92.1969 40.2526C92.3329 39.9219 92.5367 39.6204 92.7965 39.3661C93.0562 39.1117 93.3666 38.9096 93.7094 38.7716C94.0521 38.6336 94.4203 38.5625 94.7922 38.5625C95.1641 38.5625 95.5323 38.6336 95.8751 38.7716C96.2178 38.9096 96.5282 39.1117 96.7879 39.3661C97.0477 39.6204 97.2515 39.9219 97.3875 40.2526C97.5235 40.5834 97.5888 40.9368 97.5797 41.2921V54.6091Z"
                            fill="#B0D9FF"
                            stroke="#020064"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M45.875 119.489C45.875 120.431 49.1059 121.334 54.8569 122C60.608 122.666 68.4081 123.041 76.5413 123.041C84.6745 123.041 92.4746 122.666 98.2256 122C103.977 121.334 107.208 120.431 107.208 119.489C107.208 118.547 103.977 117.644 98.2256 116.978C92.4746 116.312 84.6745 115.938 76.5413 115.938C68.4081 115.938 60.608 116.312 54.8569 116.978C49.1059 117.644 45.875 118.547 45.875 119.489Z"
                            fill="#B0D9FF"
                          />
                          <path
                            d="M91.5547 103.623C91.5547 109.746 94.1002 115.617 98.6312 119.946C103.162 124.275 109.308 126.708 115.715 126.708C122.123 126.708 128.269 124.275 132.8 119.946C137.331 115.617 139.876 109.746 139.876 103.623C139.876 100.592 139.251 97.59 138.037 94.7893C136.823 91.9886 135.043 89.4438 132.8 87.3003C130.556 85.1567 127.893 83.4563 124.961 82.2962C122.03 81.1362 118.888 80.5391 115.715 80.5391C112.543 80.5391 109.401 81.1362 106.47 82.2962C103.538 83.4563 100.875 85.1567 98.6312 87.3003C96.3877 89.4438 94.608 91.9886 93.3938 94.7893C92.1796 97.59 91.5547 100.592 91.5547 103.623Z"
                            fill="#B0D9FF"
                          />
                          <path
                            d="M115.717 119.178C114.83 119.177 113.979 118.84 113.352 118.24C112.725 117.641 112.373 116.828 112.372 115.981V99.8437L108.417 103.621C107.79 104.22 106.939 104.557 106.052 104.557C105.165 104.557 104.314 104.22 103.687 103.621C103.059 103.021 102.707 102.209 102.707 101.361C102.707 100.513 103.059 99.7005 103.687 99.1012L113.351 89.8671C113.662 89.57 114.03 89.3342 114.436 89.1734C114.842 89.0125 115.277 88.9297 115.717 88.9297C116.156 88.9297 116.591 89.0125 116.997 89.1734C117.403 89.3342 117.771 89.57 118.082 89.8671L127.746 99.1012C128.057 99.3979 128.303 99.7502 128.471 100.138C128.64 100.526 128.726 100.941 128.726 101.361C128.726 101.781 128.64 102.196 128.471 102.584C128.303 102.972 128.057 103.324 127.746 103.621C127.436 103.917 127.067 104.153 126.661 104.313C126.255 104.474 125.82 104.557 125.381 104.557C124.942 104.557 124.507 104.474 124.101 104.313C123.695 104.153 123.327 103.917 123.016 103.621L119.059 99.8437V115.981C119.058 116.828 118.706 117.64 118.079 118.239C117.453 118.838 116.603 119.176 115.717 119.178Z"
                            fill="white"
                            stroke="#020064"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M91.5547 103.623C91.5547 109.746 94.1002 115.617 98.6312 119.946C103.162 124.275 109.308 126.708 115.715 126.708C122.123 126.708 128.269 124.275 132.8 119.946C137.331 115.617 139.876 109.746 139.876 103.623C139.876 100.592 139.251 97.59 138.037 94.7893C136.823 91.9886 135.043 89.4438 132.8 87.3003C130.556 85.1567 127.893 83.4563 124.961 82.2962C122.03 81.1362 118.888 80.5391 115.715 80.5391C112.543 80.5391 109.401 81.1362 106.47 82.2962C103.538 83.4563 100.875 85.1567 98.6312 87.3003C96.3877 89.4438 94.608 91.9886 93.3938 94.7893C92.1796 97.59 91.5547 100.592 91.5547 103.623Z"
                            stroke="#020064"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </svg>

                      <span className="text-black333 font-medium text-[21px] mb-10">
                        .pdf, png, or jpeg
                      </span>

                      <FileUploader
                        blueBtn={true}
                        fileName={fileUrl?.split("_").pop()}
                        name="fileName"
                        url={url}
                        onSuccess={handleSuccess}
                        onError={handleError}
                      />
                    </div>
                  </>
                )}
              </Form.Item>

              <button
                // disabled={loading}
                type="submit"
                className="bg-blueDark rounded-[35px] text-white w-[200px] p-2 mt-5"
              >
                {!"loading" ? <Spin /> : "Next"}
              </button>
            </Form>
          </div>
        </>
      ) : (
        <div className="mt-10">
          <span className="text-[18px] border-2 border-[#ff000091] p-3 text-center text-[red]">
            Payment option not available
          </span>
        </div>
      )}

      {/* <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish2}
        style={{
          maxWidth: "100%",
          marginTop: "30px",
        }}
        form={form1}
        initialValues={{
          blkPaymentAmount: amount,
        }}
      >
        <div className="mb-4">
          <label className="mb-2" for="blkBeneficiaryBank">
            Bank Name
          </label>
          <Select
            name={"blkBeneficiaryBank"}
            defaultValue="Choose"
            style={{
              width: "100%",
            }}
            className="input"
            onChange={(e) => handleSelectChange2(e)}
          >
            <Option value={"KCB"}>KCB</Option>
            <Option value={"EQUITY"}>EQUITY</Option>
          </Select>
        </div>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Enter branch",
            },
          ]}
          name={"blkBeneficiaryBranch"}
          label="Bank Branch"
        >
          <Input
            name={"blkBeneficiaryBranch"}
            className="input"
          />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Enter receiver account number",
            },
          ]}
          name={"blkBeneficiaryAccount"}
          label="Account No."
        >
          <Input
            name={"blkBeneficiaryAccount"}
            type="number"
            className="input"
          />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Enter receiver name",
            },
          ]}
          name={"blkBeneficiaryDetails"}
          label="Account Name"
        >
          <Input
            name={"blkBeneficiaryDetails"}
            className="input"
          />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Enter amount",
            },
          ]}
          name={"blkPaymentAmount"}
          label="Amount (KES)"
        >
          <InputNumber
            readOnly
            name={"blkPaymentAmount"}
            type="number"
            className="input"
          />
        </Form.Item>

        <button
          // disabled={loading}
          type="submit"
          className="bg-blueDark rounded-[35px] text-white w-[200px] p-2 mt-5"
        >
          {!"loading" ? <Spin /> : "Next"}
        </button>
      </Form> */}
    </>
  );
}
