import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  demandLetters: [],
  approvedDemandLetters:[],
  demandLoading: false,
  demandLetterEditMode: false,
  demandLetterEditId:'', 
  demandLetterCount:0,
};

export const saveDemandLetter = createAsyncThunk(
  "demandLetter/saveDemandLetter",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveDemandLetter.action`, data);
    return res.data;
  }
);

export const fetchDemandLetters = createAsyncThunk(
  "demandLetter/fetchDemandLetters",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchDemandLetters.action?dmlUsrId=${data?.dmlUsrId}&dmlCntId=${data?.dmlCntId}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}`
    );
    return res.data?.data;
  }
);

export const fetchApprovedDemandLetters = createAsyncThunk(
  "demandLetter/fetchApprovedDemandLetters",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchDemandLetters.action?dmlUsrId=${data?.dmlUsrId}&dmlCntId=${data?.dmlCntId}&dmlStatus=${data?.dmlStatus}`
    );
    return res.data?.data?.result;
  }
);

export const demandLetterSlice = createSlice({
  name: "letter",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    setDemandLetterEditMode: (state, action) => {
      state.demandLetterEditMode = action.payload;
    },
    cleanDemandLetterEditMode: (state) => {
      state.demandLetterEditMode = initialState.demandLetterEditMode;
      state.demandLetterEditId = initialState.demandLetterEditId;
    },
    setDemandLetterEditId:(state,action)=>{
      state.demandLetterEditId = action.payload;
    },
    
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveDemandLetter.pending, (state) => {
        state.demandLoading = true;
      })
      .addCase(saveDemandLetter.fulfilled, (state, action) => {
        state.demandLoading = false;
      })
      .addCase(saveDemandLetter.rejected, (state) => {
        state.demandLoading = false;
      })

      .addCase(fetchDemandLetters.pending, (state) => {
        state.demandLoading = true;
      })
      .addCase(fetchDemandLetters.fulfilled, (state, action) => {
        state.demandLoading = false;
        state.demandLetters = action.payload?.result;
        state.demandLetterCount = action.payload?.count
      })
      .addCase(fetchDemandLetters.rejected, (state) => {
        state.demandLoading = false;
        state.demandLetters = [];
        state.demandLetterCount = 0;
      })

      .addCase(fetchApprovedDemandLetters.pending, (state) => {
        state.demandLoading = true;
      })
      .addCase(fetchApprovedDemandLetters.fulfilled, (state, action) => {
        state.demandLoading = false;
        state.approvedDemandLetters = action.payload;
      })
      .addCase(fetchApprovedDemandLetters.rejected, (state) => {
        state.demandLoading = false;
        state.approvedDemandLetters = [];
      });
  },
});

export default demandLetterSlice.reducer;
export const {
  handleSidenavFullWidth,
  setDemandLetterEditMode,
  cleanDemandLetterEditMode,
  setDemandLetterEditId, 
} = demandLetterSlice.actions;
