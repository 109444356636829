import React from 'react';
import { Modal } from 'antd';

const AccountClosedModal = () => {
	return (
		<>
			<Modal
				centered
				className='modal-btn mod-height mod-footer'
				title='Account de-registered'
				open={true}
				// onCancel={handleCancel}
				width={850}
			>
				<p className='text-black3 text-[24px] w-full font-medium p-7'>
					This account has been de-registered and is currently inactive. To
					register your account with NEA, you must submit a registration
					request.
				</p>
			</Modal>
		</>
	);
};
export default AccountClosedModal;
