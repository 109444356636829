import { notification } from "antd";
import toast from "react-hot-toast";

export const customToast = (toastObj) => {
  toast.custom((t) => (
    <div
      style={{
        minHeight: "fit",
        height: "100px",
        borderRadius: "8px",
        background: "white",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        borderLeft: `4px solid ${
          toastObj.bdColor === "error"
            ? "#C8001B"
            : toastObj.bdColor === "success"
            ? "#02A548"
            : "#285ff6"
        }`,
      }}
      className={`max-w-full min-w-full lg:min-w-[900px] w-auto flex flex-col`}
    >
      <div className="flex-1 w-full p-1">
        <div className="flex items-start">
          <div className="ml-3 flex-1">
            {toastObj?.header ? (
              <>
                <p className="mt-1 toast-header">{toastObj?.header}</p>
              </>
            ) : null}
            <p className="my-1 text-[16px] text-blk3 font-semibold pb-1">
              {toastObj?.content}
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center my-3">
        <button onClick={() => toast.dismiss(t.id)} className="toast-okay">
          Okay
        </button>
      </div>
    </div>
  ));
};

export const openNotificationWithIcon = (alertObj) => {
	notification[alertObj.type]({
		duration: 3,
		placement: 'topRight',
		stack: true,
		threshold: 1,
		key: alertObj.key,
		message: alertObj.title,
		description: alertObj.content,
	});
};
