import { Button, Form, Input, Checkbox } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchApplicantsDetails,
  fetchUserDisabilitys,
} from "../../../../../features/applicant/applicantSlice";
import { dateForHumans } from "../../../../../utils";

function DisabilityView() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setdata] = useState({});
  const onFinish = async (data) => {};

  const { user } = useSelector((state) => state.auth);
  const { refetch, refetchKey } = useSelector((state) => state.cv);
  const { singleCreateApplicant, applicantEditId, applicants, disability } =
    useSelector((state) => state.applicant);

  const [applicantsList, setApplicantsList] = useState(disability);

  useEffect(() => {
    setApplicantsList(disability);
  }, [disability]);

  useEffect(() => {
    if (refetchKey == 1) {
      dispatch(
        fetchUserDisabilitys({
          disUsrId: singleCreateApplicant?.usrId
            ? singleCreateApplicant?.usrId
            : applicantEditId,
        })
      );
    }
  }, [refetch, refetchKey, applicantEditId]);

  return (
    <div className="mt-[38px]">
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-2">
          <div className="grid grid-cols-2">
            <div>
              {" "}
              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px] justify-start">
                Disability Present
              </div>
            </div>
            <div>
              <Checkbox checked={applicantsList?.disIsPresent}>Yes</Checkbox>
              <Checkbox
                className="!ml-7"
                checked={!applicantsList?.disIsPresent}
              >
                No
              </Checkbox>
            </div>

            {applicantsList?.disIsPresent && (
              <>
                <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
                  Disability Type
                </div>

                <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
                  {applicantsList?.disType ?? "N/A"}
                </div>

                <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
                  NCPWD No.
                </div>
                <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
                  {applicantsList?.disCertNumber ?? "N/A"}
                </div>
              </>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
}

export default DisabilityView;
