import { Button, Form, Input, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchClients,
  saveClient,
} from "../../../../features/client/clientSlice";
import { fetchJobs } from "../../../../features/jobs/jobSlice";
import { cashConverter, numberWithCommas } from "../../../../utils";

function TermsEmploymentView() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setdata] = useState({});
  const onFinish = async (data) => {};

  const { user } = useSelector((state) => state.auth);

  const { singleCreateJob, jobEditId, jobData, singleJob } = useSelector(
    (state) => state.jobs
  );
  // const { clientEditId } = useSelector((state) => state.client);

  // const [jobDataList, setJobDataList] = useState(jobData);
  // const [receivedId, setReceivedId] = useState(
  //   jobEditId ? jobEditId : singleCreateJob?.jobId
  // );
  // const [singleJob, setSingleJob] = useState("");

  // useEffect(() => {
  //   setReceivedId(jobEditId ? jobEditId : singleCreateJob?.jobId);
  // }, [jobEditId, singleCreateJob?.jobId]);

  // useEffect(() => {
  //   const singleItem = jobDataList?.find((item) => item?.jobId == receivedId);
  //   setSingleJob(singleItem);
  // }, [receivedId, jobDataList]);

  // useEffect(() => {
  //   setJobDataList(jobData);
  // }, [jobData]);

  // useEffect(() => {

  // }, [singleJob]);

  // useEffect(() => {
  //   dispatch(fetchJobs({
  //     jobInstId:user?.usrInstId,
  //     jobCntId:''
  //   }));
  // }, []);

  return (
    <div className="mt-[38px]">
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-2">
          <div className="grid grid-cols-2">
            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Experience Required
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobExperience}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Gross Pay
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobCurrency}-
              {numberWithCommas(singleJob?.jobGrossPay)}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Salary Confidentiality
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobSalaryConfidentiality ? "True" : "False"}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Payment Frequency
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobPayFrequency}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Terms of Employment
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobEmploymentTerms}
            </div>
          </div>

          <div className="grid grid-cols-2">
            {/* <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Job Type
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobType}
            </div> */}

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Work Style
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobWorkStyle}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Length of Employment <small>(months)</small>
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobDuration}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Start period
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobStartPeriod}
            </div>
            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Work type
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {singleJob?.jobCategory}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]"></div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]"></div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default TermsEmploymentView;
