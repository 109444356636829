import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Tabs } from "antd";
import { 
  cleanAttestationTabs,
  setRefetchKeyParent,
  setRefetchParent,
  setStage,
} from "../../features/attestation/attestationSlice";
import { useDispatch } from "react-redux";
import AwaitingAttestationTab from "./awaiting-attestation/AwaitingAttestationTab";
import PastAttestationTab from "./past-attestation/PastAttestationTab";

function AttestationList() {
  const dispatch = useDispatch();
  const [active, setactive] = useState("1");

  const [activeAwaitingTab, setactiveAwaitingTab] = useState("1");
  const [activePastTab, setactivePastTab] = useState("1");

  async function refetching(activeKey) {
    if (activeKey == "1") {
      await dispatch(setStage("awaiting"));
    } else {
      await dispatch(setStage("past"));
    }
    await dispatch(setRefetchParent());
    await dispatch(setRefetchKeyParent(activeKey));
    await setactive(activeKey);
    await setactiveAwaitingTab("1");
    await setactivePastTab("1");
  }

  async function cleanAttestatinTabsData() {
    dispatch(cleanAttestationTabs());
  }
  useEffect(() => {
    cleanAttestatinTabsData();
  }, []);

  const { TabPane } = Tabs;
  const tabConfigurations = [
    {
      label: ( 
          <span className="">Awaiting attestation</span> 
      ),
      key: "1",
      children: (
        <AwaitingAttestationTab
          active={activeAwaitingTab}
          setactive={setactiveAwaitingTab}
        />
      ),
    },

    {
      label: ( 
          <span className="ml-2">Attestation Results</span> 
      ),
      key: "2",
      children: (
        <PastAttestationTab
          activePast={activePastTab}
          setactivePast={setactivePastTab}
        />
      ),
    },
  ];

  return (
    <>
      <Header />
      <div className="mt-[0.5%] px-10">
        <Tabs
          activeKey={active}
          onChange={refetching}
          items={tabConfigurations}
          defaultActiveKey="1"
          className="attestaion-tab attestaion-bar attestaion-tab-wrap"
        />
      </div>
    </>
  );
}

export default AttestationList;
 