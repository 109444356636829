import { Radio, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import {
  fetchJobApplicantAttestation,
  setRefetch,
  setRefetchKey,
  setStage,
} from "../../../features/attestation/attestationSlice";
import { useDispatch, useSelector } from "react-redux";
import DocumentsTable from "./DocumentsTable";
import NoDocumentsTable from "./NoDocumentsTable";
import ScrollToTop from "../../../ScrollToTop";
import SentTable from "./SentTable";
import ReturnedTable from "./ReturnedTable";

function AwaitingAttestationTab({ active, setactive }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  // function refetching(activeKey) {
  //   console.log("activeKeyawaitng", activeKey);
  //   dispatch(setRefetch());
  //   dispatch(setRefetchKey(activeKey));
  //   setactive(activeKey);
  //   dispatch(setStage("awaiting"));
  // }

  // const { TabPane } = Tabs;
  // const tabConfigurations = [
  //   {
  //     label: (
  //       <span className="flex items-center">
  //         <span className="ml-2 ">Documents Uploaded</span>
  //       </span>
  //     ),
  //     key: "1",
  //     children: <DocumentsTable />,
  //   },

  //   {
  //     label: (
  //       <span className="flex items-center">
  //         <span className="ml-2">No Documents Uploaded</span>
  //       </span>
  //     ),
  //     key: "2",
  //     children: <NoDocumentsTable />,
  //   },
  // ];

  const [value, setValue] = useState(1);
  const onChange = async(e) => { 
   await setValue(e.target.value);
  };

   
  return (
    <> 
      <div className="">
        <h3 className="font-[700] text-[1.5rem] text-black1 dash-title ">
          List of applicants
        </h3>

        <Radio.Group
          onChange={onChange}
          value={value}
          className="gap-x-10 flex mt-[1.94rem]"
        >
          <Radio
            value={1}
            className={`${value === 1 ? "!text-blueDark" : "inherit"}`}
          >
            All applicants
          </Radio>
          {/* <Radio
            value={2}
            className={`${value === 2 ? "!text-blueDark" : "inherit"}`}
          >
            Returned for corrections
          </Radio> */}
          <Radio
            value={3}
            className={`${value === 3 ? "!text-blueDark" : "inherit"}`}
          >
            Sent for attestation
          </Radio>
        </Radio.Group>

        <div className="mt-[3.69rem]">
          {value === 1 ? (
            <DocumentsTable />
          ) 
          // : value === 2 ? (
          //   <ReturnedTable />
          // ) 
          : value === 3 ? (
            <SentTable />
          ) : null}
        </div>
        
      </div>
    </>
  );
}

export default AwaitingAttestationTab;
