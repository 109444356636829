import React, { useEffect, useRef, useState } from "react";
import { Form,  Breadcrumb } from "antd";
import "../clients/client.css";
import { useDispatch, useSelector } from "react-redux"; 
import { dateForHumans, timeAmPm } from "../../utils";
import PanelTable from "./view/PanelTable";
import ApplicantTable from "./view/ApplicantTable";
import { fetchSingleJobActivity, setActivityEditId, setEditMode } from "../../features/activities/activitySlice";
import InsideHeader from "../../components/InsideHeader";
import { useNavigate } from "react-router-dom";

const ActivityView = ({}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { activityLoading, activityEditId, singleActivityData } = useSelector(
    (state) => state.activity
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [receivedId, setreceivedId] = useState(activityEditId);

  async function fetchSingleActivity() {
    const res = await await dispatch(
      fetchSingleJobActivity({
        jaaId: receivedId?.jaaId,
      })
    );
  }

  const handleEdit=async()=>{
    await dispatch(setEditMode(true));
    await dispatch(setActivityEditId(receivedId));
    await navigate('/activity-add')
  }

  useEffect(() => {
    fetchSingleActivity();
  }, [receivedId]);

  useEffect(() => {
    setreceivedId(activityEditId);
  }, [activityEditId]);


  return (
    <>
      <div className="bg-white">
        <InsideHeader
          title="Activities"
          subtitle="Manage your activities such as meetings and interviews"
          back={false}
        />
        <div className="mt-[40px] px-10 font-dmSans text-[18px]">
          <Breadcrumb
            items={[
              {
                title: (
                  <span
                    className="font-dmSans cursor-pointer "
                    onClick={() => navigate("/activity-list")}
                  >
                    Activities
                  </span>
                ),
              },
              {
                title: (
                  <span className="text-blueDark font-dmSans">
                    View activity
                  </span>
                ),
              },
            ]}
          />
        </div>
        <div className="flex justify-between items-center px-10 mt-[42px]">
          <div className="text-black1 text-[24px] dash-title font-bold  leading-[32.4px]">
            {" "}
            {singleActivityData?.jaaTitle}
          </div>
          <div onClick={handleEdit}> 
            <button
              className="w-[200px] h-[50px] px-[24px] py-3 bg-blueDark rounded-[28px] justify-center items-center gap-1 inline-flex
          text-white text-[18px] leading-normal"
            >
              Edit activity
            </button>
          </div>
        </div>

        <div className="px-10 mt-[29px]">
          <div className="text-black2 text-[20px] font-medium leading-[24px]">
            Interview date: {dateForHumans(singleActivityData?.jaaStartDate)}
            {
              singleActivityData?.jaaMeetingUrl && (
                <>
                {singleActivityData?.jaaMeetingUrl}
                </>
              )
            }
          </div>
          <div className="text-black2 text-[20px] font-medium leading-[24px] mt-[26px]">
            {" "}
            {timeAmPm(singleActivityData?.jaaStartTime)} -{" "}
            {timeAmPm(singleActivityData?.jaaEndTime)}
          </div>

          <div className="text-black2 text-[20px] font-medium leading-relaxed py-[24px] mt-[16px]">
            Participants
          </div>
          <div className="text-black2 text-[20px] font-medium leading-relaxed">
            Panel
          </div>
          <PanelTable panelId={singleActivityData?.jaaPaId} />
          <div className="text-black2 text-[20px] font-medium leading-relaxed mt-[52px]">
            Applicants
          </div>
          <ApplicantTable japJaaId={singleActivityData?.jaaId} />
        </div>
      </div>

      {/* <ActivityEditModal
        isModalOpen={isModalOpenEdit}
        setIsModalOpen={setIsModalOpenEdit}
        prodd={activityList}
        setIsModalOpenActivity={setIsModalOpen}
      /> */}
    </>
  );
};
export default ActivityView;
