import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTimeOfDay } from "../../utils";
import frame from "../../assets/rafiki.svg";
import frame2 from "../../assets/bro.svg";
import add from "../../assets/add-24px.svg";
import AddJobModal from "../jobs/job-add-modal/AddJobModal";
import { useEffect, useState } from "react";
import { cleanupJob, cleanUpJobEditId } from "../../features/jobs/jobSlice";
import { cleanUpEditId, fetchClients } from "../../features/client/clientSlice";
import { fetchApprovedDemandLetters } from "../../features/demand/demandLetterSlice";

export default function ActiveDashBoard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [openEmployee, setopenEmployee] = useState(false);
  const { approvedDemandLetters } = useSelector((state) => state.letter);
  const { clientData } = useSelector((state) => state.client);
  const handleEmployees = () => {
    navigate("/client-list");
  };

 
  const handleJobs = () => {
    if (approvedDemandLetters?.length === 0) {
      navigate("/letter-list");
    }
    else if (clientData?.length === 0) {
      navigate("/client-list");
    } else {
      showModal();
    }
  };

  function handleOpenEmployee() {
    setopenEmployee(true);
  }
  function handleCancelEmployee() {
    setopenEmployee(false);
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = async () => {
    await dispatch(cleanupJob());
    dispatch(cleanUpJobEditId());
    await dispatch(cleanUpEditId());
    setIsModalOpen(true);
  };

  async function fetchLetters() {
    const res = await dispatch(
      fetchApprovedDemandLetters({
        dmlUsrId: user?.usrInstId,
        dmlStatus: "APPROVED",
      })
    );
  }

  async function fetchClientDetails() {
    const res = await dispatch(
      fetchClients({
        cntInstId: user?.usrInstId,
      })
    );
  }

  useEffect(() => {
    fetchLetters();
    fetchClientDetails();
  }, []);

  return (
    <>
      <div className="px-[0px]">
        <div className="px-[40px] py-[60px] flex flex-col justify-center items-center h-auto bg-lightBlue">
          <div className="w-full">
            <span className="text-blackest text-[45px] font-bold dash-title leading-[60.75px]">
              Good {getTimeOfDay()},
            </span>
            <span className="text-blueDark text-[45px] font-bold dash-title leading-[60.75px]">
              {" "}
              {user?.usrFullNames ?? user?.usrFirstName}.
            </span>
          </div>

          <div className="text-black21 text-lg font-normal leading-normal w-full">
            Here are a few steps to get you started.
          </div>
        </div>
      </div>

      <div className="px-[40px] mt-[52px]">
        <div className="text-black21 text-2xl font-bold dash-title leading-loose mb-[10px]">
          Add your users or create a job vacancy.
        </div>

        <div className="flex justify-start items-center gap-x-6 w-full">
          <div
            className="card !w-[376px] h-auto flex justify-center !rounded-md border p-[50px] cursor-pointer"
            onClick={handleEmployees}
          >
            <div className="flex justify-center items-center flex-col gap-x-5">
              <img
                src={frame}
                className="h-[149.55px] w-[201px] object-contain"
                alt="frame"
              />
              <div className="flex flex-col ">
                <div className="text-black1 text-[20px] font-medium leading-normal my-2">
                  Add user
                </div>
                <div className="text-black1 text-[18px] font-normal leading-normal w-[216px]">
                  You may start by adding your list of users
                </div>

                <button
                  onClick={handleEmployees}
                  type="button"
                  className="w-[200px] h-[50px] px-[74px] py-3 mt-[32px] bg-blueDark rounded-[28px] flex justify-center items-center gap-1
                    text-white  text-[18px] leading-normal"
                >
                  <img src={add} alt="add" className="h-[24px] w-[24px]" />
                  Add
                </button>
              </div>
            </div>
          </div>

          <div
            className="card !w-[400px] h-auto flex justify-center !rounded-md border p-[50px] cursor-pointer"
            onClick={handleJobs}
          >
            <div className="flex justify-center items-center flex-col gap-x-5">
              <img
                src={frame2}
                className="h-[152px] w-[161.33px] object-contain"
                alt="frame2"
              />
              <div className="flex flex-col ">
                <div className="text-black1 text-[20px] font-medium my-2">
                  Create a job vacancy
                </div>
                <div className="text-black1 text-[18px] font-normal w-[216px]">
                  Have a new position to advertise? Add a vacancy
                </div>

                <button
                  onClick={handleJobs}
                  type="button"
                  className="w-[200px] h-[50px] px-[74px] py-3 mt-[32px] bg-blueDark rounded-[28px] flex justify-center items-center gap-1
                    text-white  text-[18px]  leading-normal"
                >
                  <img src={add} alt="add" className="h-[24px] w-[24px]" />
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddJobModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        showModal={showModal}
      />
    </>
  );
}
