import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  activityData: [],
  attendeesData:[],
  activityLoading: false, 
  attendeesLoading: false, 
  refetch:false,
  refetchKey:'', 
  singleActivity:{},
  selectedRowId:[],
  selectedRow:[],
  interviewLoading:false,
  interviewBlock:'',
  sessionBlock:'',
  durations:'',
  gapTimes:'', 
  typeDetails:'', 
  activityCount:0,
  activitySessionData: [{ jaaTitle: "", jaaStartTime: "", jaaEndTime: "" }],
 
  activitySessionDataAuto: {
    startTimeAuto: null,
    durationAuto: null,
    gapTimeAuto: null,
  },
  interviewBlockTimes:'Block',
  activityEditId:'',

  singleActivityData:{},
  interviewBlockTimeData:[],
  editMode:false,

  upcomingActivityData:[],
  pastActivityData:[],
 
};

export const saveJobActivity = createAsyncThunk(
  "activity/saveJobActivity",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveJobActivity.action`, data);
    return res.data;
  }
);

export const fetchJobActivitys = createAsyncThunk(
  "activity/fetchJobActivitys",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobActivitys.action?jaaInstId=${data?.jaaInstId}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}`
    );
    return res.data?.data;
  }
);

export const deleteJobActivity = createAsyncThunk(
  "activity/deleteJobActivity",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/deleteJobActivity.action?jaaId=${data?.jaaId}`
    );
    return res.data;
  }
);
 
export const saveActivityAttendee = createAsyncThunk(
  "activity/saveActivityAttendee",
  async (data) => {
    const res = await axiosInstance.post(
      `${url}/nea/saveActivityAttendee.action`,data
    );
    return res.data?.jsonData;
  }
);
export const fetchActivityAttendees = createAsyncThunk(
  "activity/fetchActivityAttendees",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchActivityAttendees.action?addJaaId=${data?.addJaaId}`
    );
    return res.data?.data?.result;
  }
);

export const deleteActivityAttendee = createAsyncThunk(
  "activity/deleteActivityAttendee",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/deleteActivityAttendee.action?addJaaId=${data?.addJaaId}&aadUsrId=${data?.aadUsrId}`
    );
    return res.data;
  }
);

export const saveInterViewDetail = createAsyncThunk(
  "activity/saveInterViewDetail",
  async (data) => {
    const res = await axiosInstance.post(
      `${url}/nea/saveInterViewDetail.action`,data
    );
    return res.data;
  }
);

export const fetchSingleJobActivity = createAsyncThunk(
  "activity/fetchSingleJobActivity",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobActivitys.action?jaaId=${data?.jaaId}`
    );
    return res.data?.data;
  }
);

export const fetchUpcomingJobActivitys = createAsyncThunk(
  "activity/fetchUpcomingJobActivitys",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobActivitys.action?jaaInstId=${data?.jaaInstId}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}&activityStatus=${data?.activityStatus}`
    );
    return res.data?.data;
  }
);

export const fetchPastJobActivitys = createAsyncThunk(
  "activity/fetchPastJobActivitys",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobActivitys.action?jaaInstId=${data?.jaaInstId}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}&activityStatus=${data?.activityStatus}`
    );
    return res.data?.data;
  }
);

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    cleanupSingleActivity:(state)=>{
      state.singleActivity = initialState.singleActivity
    },
    setSelectedRowId:(state,action)=>{
      state.selectedRowId = action?.payload
    },
    setSelectedRow:(state,action)=>{
      state.selectedRow = action?.payload
    },
    setInterviewBlock:(state,action)=>{
      state.interviewBlock = action?.payload
    },
    setSessionBlock:(state,action)=>{
      state.sessionBlock = action?.payload
    },

    setDurations:(state,action)=>{
      state.durations = action?.payload
    },

    setGapTimes:(state,action)=>{
      state.gapTimes = action?.payload
    },

    setActivityDetails:(state,action)=>{
      state.singleActivity = action?.payload
    },
    setTypeDetails:(state,action)=>{
      state.typeDetails = action?.payload
    },
  
    

    setActivitySessionData:(state,action)=>{
      state.activitySessionData = action?.payload
    },

    setActivitySessionDataAuto:(state,action)=>{
      state.activitySessionDataAuto = action?.payload
    },
    // setStartTimeManual:(state,action)=>{
    //   state.startTimeManual ={
    //     ...state.startTimeManual,
    //     [action.payload.id]: {
    //       id: action.payload.id,
    //       startTime: action.payload.startTime,
    //     },
    //   }
    // },

    //   setStartTimeManual:(state,action)=>{
    //   state.startTimeManual ={
    //     ...state.startTimeManual,
    //     [action.payload.id]: action.payload 
    //   }
    // },

    setInterviewBlockTimes:(state,action)=>{
      state.interviewBlockTimes = action?.payload
    },

    setActivityEditId:(state,action)=>{
      state.activityEditId = action?.payload
    },

    setEditMode:(state,action)=>{
      state.editMode = action?.payload
    },
    setRefetchKey: (state, action) => {
      state.refetchKey = action.payload;
    },
    setRefetch:(state)=>{
      state.refetch = !state.refetch;
    },
    
    cleanActivityEditId:(state,action)=>{
      state.activityEditId = initialState.activityEditId  
    },
    cleanInterviewBlockTimes:(state,action)=>{
      state.interviewBlockTimes = initialState.interviewBlockTimes  
    },
    
    cleanActivitySessionDataBlock:(state,action)=>{
      state.activitySessionData = initialState.activitySessionData  
    },
    cleanActivitySessionDataAuto:(state,action)=>{
      state.activitySessionDataAuto = initialState.activitySessionDataAuto  
    },
     
     
    cleanupSelectedRowId:(state,action)=>{
      state.selectedRowId = initialState.selectedRowId
      state.selectedRow = initialState.selectedRow
    },

    cleanupInterviewBlock:(state,action)=>{ 
      state.interviewBlock =  initialState.interviewBlock 
      state.sessionBlock =  initialState.sessionBlock 
      state.durations = initialState.durations
      state.gapTimes = initialState.gapTimes
      state.typeDetails = initialState.typeDetails
    },

    clearActivityObj: () => {
      return { ...initialState };
    },
     
    
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveJobActivity.pending, (state) => {
        state.activityLoading = true;
      })
      .addCase(saveJobActivity.fulfilled, (state, action) => {
        state.activityLoading = false;
        state.singleActivity =  action?.payload?.jsonData
      })
      .addCase(saveJobActivity.rejected, (state) => {
        state.activityLoading = false;
      })

      .addCase(fetchJobActivitys.pending, (state) => {
        state.activityLoading = true;
      })
      .addCase(fetchJobActivitys.fulfilled, (state, action) => {
        state.activityLoading = false;
        state.activityData = action.payload?.result;
        state.activityCount = action.payload?.count;
      })
      .addCase(fetchJobActivitys.rejected, (state) => {
        state.activityLoading = false;
        state.activityData = [];
        state.activityCount = 0;
      })
 
      .addCase(deleteJobActivity.pending, (state) => {
        state.activityLoading = true;
      })
      .addCase(deleteJobActivity.fulfilled, (state, action) => {
        state.activityLoading = false;
        state.activityData = action.payload;
      })
      .addCase(deleteJobActivity.rejected, (state) => {
        state.activityLoading = false;
      })

      .addCase(saveActivityAttendee.pending, (state) => {
        state.attendeesLoading = true;
      })
      .addCase(saveActivityAttendee.fulfilled, (state, action) => {
        state.attendeesLoading = false;
      })
      .addCase(saveActivityAttendee.rejected, (state) => {
        state.attendeesLoading = false;
      })

      .addCase(fetchActivityAttendees.pending, (state) => {
        state.attendeesLoading = true;
      })
      .addCase(fetchActivityAttendees.fulfilled, (state, action) => {
        state.attendeesLoading = false;
        state.attendeesData = action.payload;
      })
      .addCase(fetchActivityAttendees.rejected, (state) => {
        state.attendeesLoading = false;
      })

      .addCase(deleteActivityAttendee.pending, (state) => {
        state.attendeesLoading = true;
      })
      .addCase(deleteActivityAttendee.fulfilled, (state, action) => {
        state.attendeesLoading = false;
        state.attendeesData = action.payload;
      })
      .addCase(deleteActivityAttendee.rejected, (state) => {
        state.attendeesLoading = false;
      })
      .addCase(saveInterViewDetail.pending, (state) => {
				state.interviewLoading = true;
			})
			.addCase(saveInterViewDetail.fulfilled, (state, action) => {
				state.interviewLoading = false;
			})
			.addCase(saveInterViewDetail.rejected, (state) => {
				state.interviewLoading = false;
			})

      .addCase(fetchSingleJobActivity.pending, (state) => {
        state.activityLoading = true;
      })
      .addCase(fetchSingleJobActivity.fulfilled, (state, action) => {
        state.activityLoading = false;
        state.singleActivityData = action.payload?.result?.[0]; 
      })
      .addCase(fetchSingleJobActivity.rejected, (state) => {
        state.activityLoading = false;
        state.singleActivityData = {}; 
      })

      .addCase(fetchUpcomingJobActivitys.pending, (state) => {
        state.activityLoading = true;
      })
      .addCase(fetchUpcomingJobActivitys.fulfilled, (state, action) => {
        state.activityLoading = false;
        state.upcomingActivityData = action.payload?.result;
        state.activityCount = action.payload?.count;
      })
      .addCase(fetchUpcomingJobActivitys.rejected, (state) => {
        state.activityLoading = false;
        state.upcomingActivityData = [];
        state.activityCount = 0;
      })


      .addCase(fetchPastJobActivitys.pending, (state) => {
        state.activityLoading = true;
      })
      .addCase(fetchPastJobActivitys.fulfilled, (state, action) => {
        state.activityLoading = false;
        state.pastActivityData = action.payload?.result;
        state.activityCount = action.payload?.count;
      })
      .addCase(fetchPastJobActivitys.rejected, (state) => {
        state.activityLoading = false;
        state.pastActivityData = [];
        state.activityCount = 0;
      })
      
  
      
  },
});

export default activitySlice.reducer;
export const {
  handleSidenavFullWidth, 
  cleanupSingleActivity,
  setSelectedRowId,
  cleanupSelectedRowId,
  setSelectedRow,
  setInterviewBlock,
  cleanupInterviewBlock,
  setSessionBlock,
  setDurations,
  setGapTimes,
  setActivityDetails,
  setTypeDetails, 

  setActivitySessionData,
  setActivitySessionDataAuto,
  cleanActivitySessionDataBlock,

  setInterviewBlockTimes,
  cleanInterviewBlockTimes,

  setActivityEditId,
  cleanActivityEditId,
  cleanActivitySessionDataAuto,
  setEditMode,
  setRefetchKey,
  setRefetch,
  clearActivityObj
   
  
} = activitySlice.actions;
