import { Table, Skeleton, Dropdown, Spin, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkEditPermission, dateForHumans, timeAmPm } from "../../utils";
import {
  cleanActivityEditId,
  cleanActivitySessionDataBlock,
  cleanupInterviewBlock,
  cleanupSelectedRowId,
  cleanupSingleActivity,
  fetchJobActivitys,
  setActivityEditId,
  setEditMode,
} from "../../features/activities/activitySlice";
import ActivityDeleteModal from "./ActivityDeleteModal";
import activytEmpty from "../../assets/Container.png";
import morevert from "../../assets/morevert.svg";
import toast from "react-hot-toast";
import { fetchAdminUsers } from "../../features/admin/adminSlice";
import { fetchPanels } from "../../features/panel/panelSlice";
import UsersAddModal from "../admin/UsersAddModal";
import AddPanelUserModal from "../../components/AddPanelUserModal";
import { cleanUpJobEditId } from "../../features/jobs/jobSlice";

function ActivityTable({ clientEditId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { usersData } = useSelector((state) => state.admin);
  const { activityData, activityLoading, activityCount, refetchKey, refetch } =
    useSelector((state) => state.activity);
  const { panelDetails } = useSelector((state) => state.panel);
  const { menuSidebarData } = useSelector((state) => state.admin);

  const filteredActivityData =
    activityData?.length > 0 &&
    activityData?.filter((item) => item?.jaaUsrId === clientEditId);

  const [searchText, setSearchText] = useState("");

  const [open, setOpen] = useState(false);
  const [prodd, setProdd] = useState("");

  const [isModalOpenNavigate, setIsModalOpenNavigate] = useState(false);
  const showModalNavigate = () => {
    setIsModalOpenNavigate(true);
  };

  const handleAddActivity = async () => {
    if (!checkEditPermission(menuSidebarData,"ACTIVITY")) {
      toast.error("You dont have right to perform this action");
      return
    } 
    await cleanActivityId();
    if (
      panelDetails?.length === 0 ||
      panelDetails?.every((item) => item?.children === null) ||
      usersData?.length === 0
    ) {
      await showModalNavigate();
    } else {
      await navigate("/activity-add");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = async (item) => {
    await dispatch(setActivityEditId(item));
    await navigate("/activity-view");
    // setIsModalOpen(true);
  };

  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };

  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const showModalEdit = async () => {
    setIsModalOpenEdit(true);
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchActivities(page, size) {
    const res = await await dispatch(
      fetchJobActivitys({
        jaaInstId: user?.usrInstId,
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }

  const handleEdit = async () => {
    if (prodd?.jaaStartTime || prodd?.jaaEndTime) {
      toast.error(
        "Activity has applicants already, edit by viewing the activity"
      );
      return;
    } else {
      await dispatch(setActivityEditId(prodd));
      await navigate("/activity-add");
    }
  };

  async function cleanActivityId() {
    await dispatch(setEditMode(false));
    await dispatch(cleanupSingleActivity());
    await dispatch(cleanActivityEditId());
  }
  async function cleanInterviewBlockData() {
    await dispatch(cleanupInterviewBlock());
  }

  async function cleanupSelectedRowIdData() {
    await dispatch(cleanupSelectedRowId());
  }

  async function fetchUsersDetails() {
    const res = await dispatch(
      fetchAdminUsers({
        usrInstId: user?.usrInstId,
      })
    );
  }

  async function fetchPanelDetailsData() {
    await dispatch(
      fetchPanels({
        paInstId: user?.usrInstId,
      })
    );
  }

  useEffect(() => {
    fetchPanelDetailsData();
    fetchUsersDetails();
  }, []);
  useEffect(() => {
    cleanActivityId();
    cleanInterviewBlockData();
    cleanupSelectedRowIdData();
    dispatch(cleanActivitySessionDataBlock());
    dispatch(cleanUpJobEditId());
  }, []);

  useEffect(() => {
    fetchActivities();
  }, []);

  useEffect(() => {
    if (refetchKey == 1) {
      fetchActivities();
    }
  }, [refetch, refetchKey]);

  const columns = [
    {
      title: "Activity Title",
      render: (item) => (
        <>
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => setProdd(item)}
          >
            <div className="flex justify-start">
              <span
                className="table-name mx-3 underline"
                onClick={() => showModal(item)}
              >
                {item?.jaaTitle}
              </span>
            </div>
            <button className="p-0 m-0 flex items-end" type="button">
              <Dropdown
                overlayStyle={{
                  width: "250px",
                }}
                trigger={"click"}
                menu={{ items: settingItems }}
                placement="bottom"
              >
                <img src={morevert} alt="morevert" />
              </Dropdown>
            </button>
          </div>
        </>
      ),
    },
    {
      title: "Activity Type",
      dataIndex: "jaaType",
    },
    {
      title: "Date",
      render: (item) => <div>{dateForHumans(item?.jaaStartDate)}</div>,
    },
    {
      title: "Start time",
      render: (item) => <div>{timeAmPm(item?.jaaStartTime)}</div>,
    },
    {
      title: "End time",
      render: (item) => <div>{timeAmPm(item?.jaaEndTime)}</div>,
    },
  ];
  const settingItems = [
    {
      key: "0",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={handleEdit}
        >
          Edit
        </div>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12.5362 19.2433L13.2784 18.4114C14.1892 17.3907 15.8232 17.5234 16.5574 18.6777C17.2413 19.753 18.727 19.9568 19.6753 19.1054L21.0208 17.8974M2.97852 19.4704L7.3445 18.5907C7.57628 18.544 7.78909 18.4298 7.95623 18.2626L17.7299 8.48356C18.1985 8.01471 18.1982 7.25472 17.7292 6.78626L15.6588 4.71818C15.19 4.24991 14.4304 4.25023 13.962 4.71889L4.18728 14.4989C4.02046 14.6658 3.90656 14.8782 3.85982 15.1095L2.97852 19.4704Z"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      key: "1",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={showModalDelete}
        >
          Delete
        </div>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M2.40039 5.39688H21.6004M8.40039 1.79688H15.6004M9.60039 17.3969V10.1969M14.4004 17.3969V10.1969M16.2004 22.1969H7.80039C6.47491 22.1969 5.40039 21.1224 5.40039 19.7969L4.85247 6.64683C4.82407 5.96508 5.36909 5.39688 6.05143 5.39688H17.9493C18.6317 5.39688 19.1767 5.96508 19.1483 6.64683L18.6004 19.7969C18.6004 21.1224 17.5259 22.1969 16.2004 22.1969Z"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  ];

  const filteredData = clientEditId
    ? filteredActivityData &&
      filteredActivityData?.length > 0 &&
      Array.isArray(filteredActivityData)
      ? filteredActivityData.filter((item) =>
          item?.jaaTitle
            ?.toLowerCase()
            .includes((searchText || "").toLowerCase())
        )
      : []
    : activityData && activityData?.length > 0 && Array.isArray(activityData)
    ? activityData.filter((item) =>
        item?.jaaTitle?.toLowerCase().includes((searchText || "").toLowerCase())
      )
    : [];

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  return (
    <>
      {activityData && activityData?.length > 0 ? (
        <>
          <div className="flex flex-col  w-full mt-[51px]">
            <h3 className="font-[700] text-[24px] text-black1 dash-title ">
              List of activities
            </h3>
            <div className="mt-5 flex justify-between items-center w-full">
              <div className="flex items-center w-full">
                <Input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search activity title"
                  className="text-[16px] font-[400] flex-row-reverse"
                  prefix={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                        fill="#333333"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                        fill="#333333"
                      />
                    </svg>
                  }
                />
              </div>
              {checkEditPermission(menuSidebarData, "ACTIVITY") && (
                <button
                  onClick={() => handleAddActivity()}
                  className="w-[250px] save-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                      fill="#EDF8FF"
                    />
                  </svg>
                  Add activity
                </button>
              )}
            </div>
          </div>

          <div className="mt-[46px]  ">
            {activityLoading ? (
              <Skeleton />
            ) : (
              <Table
                rowSelection={true}
                size={"middle"}
                bordered={true}
                pagination={{
                  position: ["bottomCenter"],
                  current: pageIndex + 1,
                  total: activityCount,
                  pageSize: pageSize,
                  onChange: (page, size) => {
                    setPageIndex(page - 1);
                    setPageSize(size);
                    fetchActivities(page - 1, size);
                  },
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                }}
                rowKey={(record) => record?.jaaId}
                columns={tableColumns}
                dataSource={filteredData}
                scroll={{
                  x: 800,
                }}
              />
            )}
          </div>
        </>
      ) : (
        <div
          className="w-full h-[471.33px] rounded-[15px] py-[60px] flex flex-col justify-center items-center cursor-pointer"
          onClick={handleAddActivity}
        >
          <div>
            <img
              src={activytEmpty}
              alt="group-pic"
              className="object-contain h-[291.33px] w-[292px]"
            />
            <div className="empty-desc flex items-center justify-center">
              Activities you add will be displayed here
            </div>
          </div>

          <div className="mt-[2.63rem]">
            <button
              className={`w-[250px] save-btn ${!checkEditPermission(menuSidebarData, "ACTIVITY") ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                  fill="#EDF8FF"
                />
              </svg>
              Add activity
            </button>
          </div>
        </div>
      )}

      <ActivityDeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        prodd={prodd}
        setIsModalOpenActivity={setIsModalOpen}
        fromTable={true}
      />

      <AddPanelUserModal
        isModalOpen={isModalOpenNavigate}
        setIsModalOpen={setIsModalOpenNavigate}
      />
    </>
  );
}

export default ActivityTable;
