import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	skillLoading: false,
	mySkills: [],
	globalSkills: [],
};

export const fetchSkillCategory = createAsyncThunk(
	'skillSlice/fetchSkillCategory',
	async () => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchSkillCategorys.action`,
		);
		return res.data;
	},
);

export const saveSkill = createAsyncThunk(
	'skillSlice/saveSkill',
	async (skillObj) => {
		const res = await axiosInstance.post(`${url}/nea/saveSkill.action`, skillObj);
		return res.data;
	},
);

export const fetchSkills = createAsyncThunk(
	'skillSlice/fetchSkills',
	async (usrObj) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchSkills.action?sklUsrId=${usrObj?.usrId}&sklType=${usrObj?.usrType}`,
		);
		return res.data;
	},
);

export const deleteSkill = createAsyncThunk(
	'skillSlice/deleteSkill',
	async (sklId) => {
		const res = await axiosInstance.get(
			`${url}/nea/deleteSkill.action?sklId=${sklId}`,
		);
		return res.data;
	},
);

export const fetchGlobalSkills = createAsyncThunk(
	'skillSlice/fetchGlobalSkills',
	async (sklId) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchGlobalSkills.action?gsklSklcatId=${sklId ?? ''}`,
		);
		return res.data;
	},
);

export const skillSlice = createSlice({
	name: 'skill',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
        builder

        .addCase(saveSkill.pending, (state) => {
            state.skillLoading = true;
        })
        .addCase(saveSkill.fulfilled, (state, action) => {
            state.skillLoading = false;

        })
        .addCase(saveSkill.rejected, (state) => {
            state.skillLoading = false;
        })

		.addCase(fetchSkillCategory.pending, (state) => {
            state.skillLoading = true;
        })
        .addCase(fetchSkillCategory.fulfilled, (state, action) => {
            state.skillLoading = false;

        })
        .addCase(fetchSkillCategory.rejected, (state) => {
            state.skillLoading = false;
        })

		.addCase(fetchSkills.pending, (state) => {
            state.skillLoading = true;
        })
        .addCase(fetchSkills.fulfilled, (state, action) => {
            state.skillLoading = false;
			if (action?.payload?.success) {
				state.mySkills = action?.payload?.data?.result
			}else{
				state.mySkills = []
			}

        })
        .addCase(fetchSkills.rejected, (state) => {
            state.skillLoading = false;
        })

		.addCase(fetchGlobalSkills.pending, (state) => {
            state.skillLoading = true;
        })
        .addCase(fetchGlobalSkills.fulfilled, (state, action) => {
            state.skillLoading = false;
			if (action?.payload?.success) {
				state.globalSkills = action?.payload?.data?.result
			}else{
				state.globalSkills = []
			}

        })
        .addCase(fetchGlobalSkills.rejected, (state) => {
            state.skillLoading = false;
        })
    },
});

export default skillSlice.reducer;
export const { } =
skillSlice.actions;
