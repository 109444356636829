import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import {
  setRefetch,
  setRefetchKey,
  setStage,
} from "../../../features/attestation/attestationSlice";
import { useDispatch } from "react-redux";
import PassedAttestationTable from "./PassedAttestationTable";
import FailedAttestationTable from "./FailedAttestationTable";

function PastAttestationTab({ activePast, setactivePast }) {
  const dispatch = useDispatch();
  //const [activePast, setactivePast] = useState("1")

  function refetching(activeKeyPast) {
    dispatch(setRefetch());
    dispatch(setRefetchKey(activeKeyPast));
    setactivePast(activeKeyPast);
    dispatch(setStage("past"));
  }

  const { TabPane } = Tabs;
  const tabConfigurations = [
    {
      label: (
        <span className="flex items-center">
          <span className="ml-2 ">Passsed Attestation</span>
        </span>
      ),
      key: "1",
      children: <PassedAttestationTable />,
    },

    {
      label: (
        <span className="flex items-center">
          <span className="ml-2">Failed Attestation</span>
        </span>
      ),
      key: "2",
      children: <FailedAttestationTable />,
    },
  ];
  return (
    <>
      <div className="">
        <Tabs
          activeKey={activePast}
          onChange={refetching}
          items={tabConfigurations}
          defaultActiveKey="1"
          className="employees-bar attestaion-tab2" 
        />
      </div>
    </>
  );
}

export default PastAttestationTab;
