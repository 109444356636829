import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  globalLoading: false,
  fullWidth: true,
  studyAreas: [],
  courses: [],
  awards: [],
  instTypes: [],
  profBodies: [],
  profSpecializations: [],
  notifications: [],
};

export const fetchInstitutionTypes = createAsyncThunk(
  "globalSlice/fetchInstitutionTypes",
  async () => {
    const res = await axiosInstance.get(
      `${url}/setup/fetchInstitutionTypes.action`
    );
    return res.data.data.result;
  }
);

export const fetchStudyAreas = createAsyncThunk(
  "globalSlice/fetchStudyAreas",
  async () => {
    const res = await axiosInstance.get(`${url}/setup/fetchStudyAreas.action`);
    return res.data.data.result;
  }
);

export const fetchAwards = createAsyncThunk(
  "globalSlice/fetchAwards",
  async () => {
    const res = await axiosInstance.get(`${url}/setup/fetchAwards.action`);
    return res.data.data.result;
  }
);

export const fetchCourses = createAsyncThunk(
  "globalSlice/fetchCourses",
  async (corsAwdId) => {
    const res = await axiosInstance.get(
      `${url}/setup/fetchCourses.action?corsAwdId=${corsAwdId}`
    );
    return res.data.data.result;
  }
);

export const fetchProfessionalBodies = createAsyncThunk(
  "globalSlice/fetchProfessionalBodies",
  async () => {
    const res = await axiosInstance.get(
      `${url}/setup/fetchProfessionalBodys.action`
    );
    return res.data.data.result;
  }
);

export const fetchProfessionalBodySpecializations = createAsyncThunk(
  "globalSlice/fetchProfessionalBodySpecializations",
  async (bodyId) => {
    const res = await axiosInstance.get(
      `${url}/setup/fetchProfessionalBodySpecializations.action?pbsPrbId=${bodyId}`
    );
    return res.data.data.result;
  }
);

export const saveNotification = createAsyncThunk(
  "globalSlice/saveNotification",
  async (obj) => {
    const res = await axios.post(`${url}/not/saveNotification.action`, obj);
    return res.data;
  }
);

export const fetchNotifications = createAsyncThunk(
  "globalSlice/fetchNotifications",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/not/fetchNotifications.action?ntfnInstId=${usrObj?.usrInstId}&ntfnReceiverId=${usrObj?.usrId}`
    );
    return res.data.data.result;
  }
);

export const deleteNotification = createAsyncThunk(
  "deleteSlice/deleteNotification",
  async (id) => {
    const res = await axios.get(
      `${url}/not/deleteNotification.action?ntfnId=${id}`
    );
    return res.data;
  }
);

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    globalCleanUp: (state) => {
      state.courses = [];
      state.profSpecializations = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(deleteNotification.pending, (state) => {
        state.globalLoading = true;
      })
      .addCase(deleteNotification.fulfilled, (state) => {
        state.globalLoading = false;
      })
      .addCase(deleteNotification.rejected, (state) => {
        state.globalLoading = false;
      })

      .addCase(saveNotification.pending, (state) => {
        state.globalLoading = true;
      })
      .addCase(saveNotification.fulfilled, (state) => {
        state.globalLoading = false;
      })
      .addCase(saveNotification.rejected, (state) => {
        state.globalLoading = false;
      })

      .addCase(fetchNotifications.pending, (state) => {
        state.globalLoading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.globalLoading = false;
        state.notifications = action.payload ?? [];
      })
      .addCase(fetchNotifications.rejected, (state) => {
        state.globalLoading = false;
      })

      .addCase(fetchProfessionalBodySpecializations.pending, (state) => {
        state.globalLoading = true;
      })
      .addCase(
        fetchProfessionalBodySpecializations.fulfilled,
        (state, action) => {
          state.globalLoading = false;
          state.profSpecializations = action?.payload;
        }
      )
      .addCase(fetchProfessionalBodySpecializations.rejected, (state) => {
        state.globalLoading = false;
        state.profSpecializations = [];
      })

      .addCase(fetchProfessionalBodies.pending, (state) => {
        state.globalLoading = true;
      })
      .addCase(fetchProfessionalBodies.fulfilled, (state, action) => {
        state.globalLoading = false;
        state.profBodies = action?.payload;
      })
      .addCase(fetchProfessionalBodies.rejected, (state) => {
        state.globalLoading = false;
        state.profBodies = [];
      })

      .addCase(fetchInstitutionTypes.pending, (state) => {
        state.globalLoading = true;
      })
      .addCase(fetchInstitutionTypes.fulfilled, (state, action) => {
        state.globalLoading = false;
        state.instTypes = action?.payload;
      })
      .addCase(fetchInstitutionTypes.rejected, (state) => {
        state.globalLoading = false;
        state.instTypes = [];
      })

      .addCase(fetchAwards.pending, (state) => {
        state.globalLoading = true;
      })
      .addCase(fetchAwards.fulfilled, (state, action) => {
        state.globalLoading = false;
        state.awards = action?.payload;
      })
      .addCase(fetchAwards.rejected, (state) => {
        state.globalLoading = false;
        state.awards = [];
      })

      .addCase(fetchCourses.pending, (state) => {
        state.globalLoading = true;
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.globalLoading = false;
        state.courses = action?.payload;
      })
      .addCase(fetchCourses.rejected, (state) => {
        state.globalLoading = false;
        state.courses = [];
      })

      .addCase(fetchStudyAreas.pending, (state) => {
        state.globalLoading = true;
      })
      .addCase(fetchStudyAreas.fulfilled, (state, action) => {
        state.globalLoading = false;
        state.studyAreas = action?.payload;
      })
      .addCase(fetchStudyAreas.rejected, (state) => {
        state.globalLoading = false;
        state.studyAreas = [];
      });
  },
});

export default globalSlice.reducer;
export const { handleSidenavFullWidth, globalCleanUp } = globalSlice.actions;
