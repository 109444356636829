import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  message,
} from "antd";
import "../../clients/demand-modal/demand-modal.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast"; 
import { fetchJobs, fetchSingleJob, saveJob } from "../../../features/jobs/jobSlice";

const { TextArea } = Input;
const CancelAdvertModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { jobLoading, jobEditId, singleCreateJob } = useSelector(
    (state) => state.jobs
  );
  const [receivedId, setReceivedId] = useState(
    jobEditId ? jobEditId : singleCreateJob?.jobId
  );

  useEffect(() => {
    setReceivedId(jobEditId ? jobEditId : singleCreateJob?.jobId);
  }, [jobEditId, singleCreateJob?.jobId]);

  function closeModal() {
    toast.dismiss();
  }
  const [data, setdata] = useState({});

  const onFinish = async (data) => {
    const res = await dispatch(
      saveJob({
        jobId: receivedId,
        jobCancelReason:data?.jobCancelReason,
        jobAdvertStatus:"PENDING_APPROVE_CANCEL",
        jobInstId: user?.usrInstId,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
     await dispatch(
        fetchSingleJob({
          jobId: receivedId,
        })
      ); 
      form.resetFields();
      handleOk();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  return (
    <>
      <Modal
        className="mod mod-height" 
        title="Cancel Advert "
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
         

        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
         
            <Form.Item
              label="Reason"
              name="jobCancelReason"
              rules={[
                {
                  required: true,
                  message: "Please add reason",
                },
              ]}
            >
              <TextArea
                rows={4}
                className="input"
              />
            </Form.Item>
           
          <div className="flex justify-between mt-[10rem] mb-[40px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center">
              <Button
                key="back"
                onClick={handleCancel}
                className="w-[138px] h-12 px-7 py-4 rounded-[56px] border-none justify-center items-center gap-2.5 inline-flex
                        text-blueDark text-[1rem] font-medium font-dmSans leading-normal"
              >
                Cancel
              </Button>
              ,
              <button
                key="submit"
                type="submit"
                className="w-[239px] h-12 px-[16px] py-[28px] bg-blueDark rounded-[56px] shadow justify-center items-center gap-2.5 inline-flex
                           text-blue-50 text-[1rem] font-medium font-dmSans leading-normal"
              >
                {jobLoading ? <Spin /> : "Cancel Advert"}
              </button>
              ,
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default CancelAdvertModal;
