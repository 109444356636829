import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  loading: false,
  refetch:false,
  refetchKey:'', 
  distressData:[],
  distressCount:0,
  singleDistress:{},
  singleRunaway:{},

  runawayData:[],
  runawayCount:0,
};
export const saveDistress = createAsyncThunk(
	'distressSlice/saveDistress',
	async (data) => {
		const res = await axios.post(`${url}/emp/saveDistress.action`, data);
		return res.data;
	},
);
 
export const fetchDistress = createAsyncThunk(
	'distressSlice/fetchDistress',
	async (data) => {
		const res = await axios.get(
			`${url}/emp/fetchDistresss.action?instId=${data?.instId}&dstStatus=${data?.dstStatus}`,
		);
		return res.data.data;
	},
);

export const fetchSingleDistress = createAsyncThunk(
	'distressSlice/fetchSingleDistress',
	async (data) => {
		const res = await axios.get(
			`${url}/emp/fetchDistresss.action?dstId=${data?.dstId}`,
		);
		return res.data.data.result;
	},
);

export const saveRunAway = createAsyncThunk(
	'distressSlice/saveRunAway',
	async (data) => {
		const res = await axios.post(`${url}/emp/saveRunAway.action`, data);
		return res.data;
	},
);
 

export const fetchRunAways = createAsyncThunk(
	'distressSlice/fetchRunAways',
	async (data) => {
		const res = await axios.get(
			`${url}/emp/fetchRunAways.action?rnaStatus=${data?.rnaStatus}&rnaInstId=${data?.rnaInstId}`,
		);
		return res.data.data;
	},
);


export const fetchSingleRunAway = createAsyncThunk(
	'distressSlice/fetchSingleRunAway',
	async (data) => {
		const res = await axios.get(
			`${url}/emp/fetchRunAways.action?rnaId=${data?.rnaId}`,
		);
		return res.data.data.result;
	},
);

 


export const runawaySlice = createSlice({
  name: "runaway",
  initialState,
  reducers: {
    setRefetchKey: (state, action) => {
      state.refetchKey = action.payload;
    },
    setRefetch:(state)=>{
      state.refetch = !state.refetch;
    }, 

    
  },
  extraReducers: (builder) => {
    builder 
    .addCase(saveDistress.pending, (state) => {
      state.loading = true;
    })
    .addCase(saveDistress.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(saveDistress.rejected, (state) => {
      state.loading = false;
    })

    .addCase(fetchDistress.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchDistress.fulfilled, (state, action) => {
      state.loading = false;
      state.distressData =  action.payload?.result
      state.distressCount = action.payload?.count;
    })
    .addCase(fetchDistress.rejected, (state) => {
      state.loading = false;
      state.distressData =  []
      state.distressCount  = 0
    })


    .addCase(fetchSingleDistress.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchSingleDistress.fulfilled, (state, action) => {
      state.loading = false;
      state.singleDistress =  action.payload?.[0]
    })
    .addCase(fetchSingleDistress.rejected, (state) => {
      state.loading = false;
      state.singleDistress =  [] 
    })

    
    .addCase(saveRunAway.pending, (state) => {
      state.loading = true;
    })
    .addCase(saveRunAway.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(saveRunAway.rejected, (state) => {
      state.loading = false;
    })

    .addCase(fetchRunAways.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchRunAways.fulfilled, (state, action) => {
      state.loading = false;
      state.runawayData =  action.payload?.result
      state.runawayCount = action.payload?.count;
    })
    .addCase(fetchRunAways.rejected, (state) => {
      state.loading = false;
      state.runawayData =  []
      state.runawayCount  = 0
    })


    .addCase(fetchSingleRunAway.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchSingleRunAway.fulfilled, (state, action) => {
      state.loading = false;
      state.singleRunaway =  action.payload?.[0]
    })
    .addCase(fetchSingleRunAway.rejected, (state) => {
      state.loading = false;
      state.singleRunaway =  [] 
    })
   
  },
});

export default runawaySlice.reducer;
export const {
  setRefetchKey,
  setRefetch
   
  
} = runawaySlice.actions;
