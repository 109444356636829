import React, { useRef, useState } from "react";
import { Breadcrumb, DatePicker, Form, Input, Select, Spin } from "antd";
import "../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast"; 
import {
  cleanupSingleActivity,
  fetchSingleJobActivity,
  saveJobActivity,
  setActivityDetails,
  setInterviewBlockTimes, 
} from "../../features/activities/activitySlice";
import { fetchJobs } from "../../features/jobs/jobSlice";
import { useEffect } from "react"; 
import InsideHeader from "../../components/InsideHeader";
import { disabledPastDate } from "../../utils";
import moment from "moment";
import { fetchPanels } from "../../features/panel/panelSlice";
import { fetchAdminUsers } from "../../features/admin/adminSlice";

 

const ActivityStep2 = () => {
  async function cleanActivityId() {
    await dispatch(cleanupSingleActivity());
  }

  const handlePrevious = async () => {
    await navigate("/activity-add");
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  const {
    singleActivity,
    activityLoading,
    activityEditId,
    singleActivityData,
    editMode
  } = useSelector((state) => state.activity);
  const { jobData } = useSelector((state) => state.jobs);

  const { usersData } = useSelector((state) => state.admin);
  const { panelDetails } = useSelector((state) => state.panel);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setdata] = useState({});

  const [receivedId, setreceivedId] = useState(
    Object.keys(singleActivity)?.length > 0
      ? singleActivity?.jaaId
      : activityEditId?.jaaId
  );

  const [formData, setFormData] = useState(receivedId ? singleActivityData : "");

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  function onMonthChange(value) {
    console.log("value",value)
    setFormData((prevData) => ({
      ...prevData,
      jaaStartDate: value,
    }));
  }
 
 
  const onFinish = async (data) => {
    if (!formData?.jaaStartDate) {
      toast.error("Select date");
      return;
    }
    if (!formData?.jaaPaId) {
      toast.error("Select interview panel");
      return;
    }
    if (!formData?.jaaAssigneeId) {
      toast.error("Select assignee");
      return;
    }
    const res = await dispatch(
      saveJobActivity({
        jaaId: receivedId ?? null,
        jaaStartDate: formData?.jaaStartDate?.$d,
        jaaLocation: formData?.jaaLocation,
        jaaMeetingUrl: formData?.jaaMeetingUrl,
        jaaPaId: formData?.jaaPaId,
        jaaAssigneeId: formData?.jaaAssigneeId,

        jaaInstId: user?.usrInstId,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      if(editMode){
        await dispatch(setInterviewBlockTimes("Manual"));
        await navigate("/jap-table");
      }else{
        navigate("/activity-step3");
      }  
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  async function fetchJobsDetails() {
    const res = await dispatch(
      fetchJobs({
        jobInstId: user?.usrInstId,
        jobCntId: "",
      })
    );
  }

  async function fetchUsersDetails() {
    const res = await dispatch(
      fetchAdminUsers({
        usrInstId: user?.usrInstId,
      })
    );
  }

  async function fetchPanelDetailsData() {
    await dispatch(
      fetchPanels({
        paInstId: user?.usrInstId,
      })
    );
  }

  async function fetchSingleActivity() {
    const res = await await dispatch(
      fetchSingleJobActivity({
        jaaId: receivedId,
      })
    );
  }

  useEffect(() => {
    fetchJobsDetails();
  }, []);

  useEffect(() => {
    fetchPanelDetailsData();
    fetchUsersDetails();
  }, []);

  useEffect(() => {
    setFormData(receivedId ? singleActivityData : "");
  }, [singleActivityData,receivedId]);

  useEffect(() => {
    setreceivedId(
      Object.keys(singleActivity)?.length > 0
        ? singleActivity?.jaaId
        : activityEditId?.jaaId
    );
  }, [singleActivity, activityEditId]);

  useEffect(() => {
    fetchSingleActivity();
  }, []);

 
  return (
    <>
      <InsideHeader
        title="Activities"
        subtitle="Manage your activities such as meetings and interviews"
        back={false}
      />

      <div className="mt-[40px] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer "
                  onClick={() => navigate("/activity-list")}
                >
                  Activities
                </span>
              ),
            },
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer "
                  onClick={handlePrevious}
                >
                  Add activities
                </span>
              ),
            },
            {
              title: (
                <span className="text-blueDark font-dmSans">
                  Interview details
                </span>
              ),
            },
          ]}
        />
      </div>

      <div className="flex justify-center items-center">
        <div className="border border-solid border-[#E0E0E0] mt-[40px] h-auto w-[1020px] px-[100px] py-[60px]">
          <div className="text-black2 text-[24px] font-bold dash-title leading-[32.4px]">
            Activity Details
          </div>
          <div className="w-[308px] mt-[28px] mb-[40px] text-black2 text-[20px] font-medium  leading-[24px]">
            Please fill in the following details.
          </div>

          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            className=""
            style={{
              maxWidth: "100%",
            }}
            form={form}
          >
            <Form.Item
              label="Date of interview"
              extra={"Choose interview date"}
              className="mr-6 w-full"
            >
              <DatePicker
                name="jaaStartDate"
                style={{
                  width: "100%",
                  height: "3.25rem",
                }}
                placeholder={
                  formData?.jaaStartDate
                    ? moment(formData?.jaaStartDate)?.format("DD-MM-YYYY")
                    : "Select Date"
                }
                className="mr-3"
                format={"DD-MM-YYYY"}
                onChange={onMonthChange}
                disabledDate={disabledPastDate}
              />
            </Form.Item>
            <Form.Item
              extra={"Enter location"}
              label="Location"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please add location",
                },
              ]}
            >
              <Input
                required
                name="jaaLocation"
                value={formData?.jaaLocation}
                onChange={onChange}
                className="input"
              />
            </Form.Item>

            <Form.Item
              label="Online meeting link"
              extra={"Enter meeting link"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: false,
                  type: "url",
                  message: "Please add a valid URL",
                },
              ]}
            >
              <Input
                // required
                value={formData?.jaaMeetingUrl}
                onChange={onChange}
                name="jaaMeetingUrl"
                placeholder="Enter URL"
                className="input"
              />
            </Form.Item>

            <Form.Item
              label="Choose interview Panel"
              extra={"Choose option"}
              rules={[
                {
                  required: true,
                  message: "Please select panel",
                },
              ]}
            >
              <Select
                required
                name="jaaPaId"
                value={formData?.jaaPaId}
                className="activity-select-tag activity-select-icon h-[44px] w-full"
                allowClear
                style={{
                  width: "100%",
                  height: "3.25rem",
                }}
                placeholder="Please select panel"
                onChange={(value) => {
                  handleSelectChange(value, "jaaPaId");
                }}
                options={
                  panelDetails?.length > 0 &&
                  panelDetails
                    ?.filter((item) => item.children !== null)
                    .map((item) => ({
                      value: item?.paId,
                      label: item?.paName,
                    }))
                }
                onDropdownVisibleChange={() => {
                  fetchPanelDetailsData();
                  if (
                    panelDetails?.length === 0 ||
                    panelDetails?.every((item) => item?.children === null)
                  ) {
                    dispatch(setActivityDetails(formData));
                    navigate("/panel-list");
                  }
                }}
              />
            </Form.Item>

            <Form.Item label="Choose assignees" extra={"Choose option"}>
              <Select
                required
                name="jaaAssigneeId"
                value={formData?.jaaAssigneeId}
                className="activity-select-tag activity-select-icon"
                allowClear
                style={{
                  width: "100%",
                  height: "3.25rem",
                }}
                placeholder="Please select"
                onChange={(value) => {
                  handleSelectChange(value, "jaaAssigneeId");
                }}
                options={
                  usersData?.length > 0 &&
                  usersData?.map((item) => ({
                    value: item?.usrId,
                    label: item?.usrFirstName + " " + item?.usrLastName,
                  }))
                }
                onDropdownVisibleChange={() => {
                  fetchUsersDetails();
                  if (usersData?.length === 0) {
                    dispatch(setActivityDetails(formData));
                    navigate("/users-list");
                  }
                }}
              />
            </Form.Item>

            <div className="justify-center items-center flex flex-col gap-y-2">
              <button
                key="submit"
                type="submit"
                className="w-[300px] h-[50px] px-2 py-3 bg-blueDark rounded-[28px] justify-center items-center text-white text-[18px]
                font-dmSans"
                disabled={activityLoading}
              >
                {activityLoading ? <Spin /> : "Next"}
              </button>
              <button
                onClick={handlePrevious}
                className="w-[300px] h-[50px] px-2 py-3 justify-center items-center rounded-[28px] border border-solid border-blueDark flex 
                !text-blueDark text-[18px] font-dmSans bg-white"
              >
                Previous
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default ActivityStep2;
