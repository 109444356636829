import { Button, Dropdown, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import AddBranchModal from "./modals/AddBranchModal";
import {
  clearAgency,
  deleteBranch,
  fetchAgencyBranches,
  setBranchObj,
} from "../../../../features/agency/agencySlice";
import morevert from "../../../../assets/morevert.svg";
import toast from "react-hot-toast";
import DeleteModal from "../../../../components/DeleteModal";
import { checkDeletePermission, checkEditPermission } from "../../../../utils";

export default function BranchesTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { branches, agnLoading, agencyObj } = useSelector(
    (state) => state.agency
  );
  const { menuSidebarData } = useSelector((state) => state.admin);

  const [dataList, setdataList] = useState(branches);
  const [editObj, seteditObj] = useState({});
  const [open, setopen] = useState(false);
  const [prodd, setProdd] = useState("");

  async function handleFetchListData() {
    await dispatch(fetchAgencyBranches());
    await dispatch(clearAgency());
  }

  // async function handleEdit(item) {
  // 	await dispatch(setBranchObj(item))
  // 	await navigate(`/branches/edit/${item?.instName}`)
  // }

  function handleSetEditObj(obj) {
    seteditObj(obj);
  }

  async function handleOpen() {
    await setProdd("");
    await setopen(true);
  }
  function handleEdit() {
    setopen(true);
  }

  function handleCancel() {
    setopen(false);
  }
  const columns = [
    {
      title: "Branch Name",
      dataIndex: "ibrnName",
    },
    {
      title: "County",
      dataIndex: "ibrnCounty",
    },
    {
      title: "Region",
      dataIndex: "ibrnRegion",
    },
    {
      title: "Physical Address",
      dataIndex: "ibrnPhysicalAddress",
    },
    {
      title: "Email",
      dataIndex: "ibrnEmail",
    },
    {
      title: "Phone Number",
      dataIndex: "ibrnCellPhone",
    },
    {
      title: "Date added",
      dataIndex: "ibrnCreatedDate",
      sorter: {
        compare: (a, b) => a.ibrnCreatedTime - b.ibrnCreatedTime,
        multiple: 1,
      },
      render: (item) => <span>{moment(item).format("LLL")}</span>,
    },

    {
      title: "Actions",
      render: (item) => (
        <>
          <Dropdown
            overlayStyle={{
              width: "250px",
            }}
            trigger={"click"}
            menu={{ items: settingItems }}
            placement="bottom"
          >
            <button onClick={() => setProdd(item)}>
              <img src={morevert} alt="morevert" />
            </button>
          </Dropdown>
        </>
      ),
    },
  ];

  const [searchText, setSearchText] = useState("");
  const filteredData = searchText
    ? dataList?.filter((item) =>
        item?.instName?.toLowerCase().includes(searchText.toLowerCase())
      )
    : dataList;

  const content = "Are you sure you want to delete ?";
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };

  const handleDelete = async () => {
    const res = await dispatch(
      deleteBranch({
        ibrnId: prodd?.ibrnId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleFetchListData();
      await setIsModalOpenDelete(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const settingItems = [
    {
      key: "0",
      label: (
        <div
          className={`font-dmSans text-black font-[400] text-[19px] ${checkEditPermission(menuSidebarData, "ADMIN") ? "cursor-pointer" : 'cursor-not-allowed'}`}
          onClick={checkEditPermission(menuSidebarData, "ADMIN") ? handleEdit : null}
        >
          Edit
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <div
          className={`font-dmSans text-black font-[400] text-[19px]  ${checkDeletePermission(menuSidebarData, "ADMIN") ? "cursor-pointer" : 'cursor-not-allowed'}`}
          onClick={checkDeletePermission(menuSidebarData, "ADMIN") ? showModalDelete : null}
        >
          Delete
        </div>
      ),
    },
  ];

  useEffect(() => {}, [dataList]);

  useEffect(() => {
    setdataList(branches);
  }, [branches]);

  useEffect(() => {
    handleFetchListData();
  }, []);

  return (
    <>
      <div className="flex flex-col w-full">
        <h3 className="font-[700] text-[24px] text-black1 dash-title mt-[2.5rem]">
          List of Branches
        </h3>

        <div className="flex justify-between items-center w-full mt-[1.5rem]">
          <div className="flex items-center w-full ">
            <Input
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search branch by name or keyword.."
              className="text-[16px] font-[400] flex-row-reverse"
              prefix={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                    fill="#333333"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                    fill="#333333"
                  />
                </svg>
              }
            />
          </div>
          {checkEditPermission(menuSidebarData, "ADMIN") && (
            <button onClick={handleOpen} className="w-[250px] save-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                  fill="#EDF8FF"
                />
              </svg>
              Add Branch
            </button>
          )}
        </div>

        <div className="dash-inner-page mt-10 max-w-full w-full overflow-x-auto">
          <section>
            <Table
              loading={agnLoading}
              className="no-header-bg-table"
              size={"middle"}
              bordered={false}
              columns={columns}
              dataSource={filteredData}
              scroll={{
                x: 800,
              }}
              pagination={{
                defaultPageSize: 5,
                hideOnSinglePage: true,
                pageSizeOptions: [10, 20, 50, 100],
              }}
            />
          </section>
        </div>
      </div>

      <AddBranchModal
        open={open}
        handleCancel={handleCancel}
        handleFetchListData={handleFetchListData}
        prodd={prodd}
      />

      <DeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        handleDelete={handleDelete}
        content={content}
        title="Delete branch"
        loading={agnLoading}
      />
    </>
  );
}
