import { Button, Form, Input, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchClients,
  saveClient,
  setClientEditId,
  setEditBasic,
  setViewBasic,
} from "../../features/client/clientSlice";
import { showToastError, showToastSuccess } from "../../utils";
import toast from "react-hot-toast";

function BasicInfoEdit() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const {
    clients,
    clientData,
    clientLoading,
    viewBasic,
    editBasic,
    clientEditId,
  } = useSelector((state) => state.client);

  const singleClient = clientData?.find((item) => item?.cntId == clientEditId);

  const [clientInfo, setClientInfo] = useState(singleClient);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setdata] = useState({});

  function closeModal() {
    toast.dismiss();
  }

  const handleChange = (e) => {
    setClientInfo((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCancel = () => {
    dispatch(setViewBasic(true));
    dispatch(setEditBasic(false)); 
  };
  const onFinish = async (data) => {
    const res = await dispatch(
      saveClient({
        cntId: clientInfo?.cntId,
        cntInstId: user?.usrInstId,
        cntName: clientInfo?.cntName, 
        cntWebsite: clientInfo?.cntWebsite ,
        cntIndustry: clientInfo?.cntIndustry ,
        cntDescription: clientInfo?.cntDescription 
      })
    );
    if (res?.payload?.success) {
      toast.success("Basic info updated successfull");
      dispatch(
        fetchClients({
          cntInstId: user?.usrInstId,
        })
      );
      await dispatch(setViewBasic(true));
      await dispatch(setEditBasic(false));
      await dispatch(setClientEditId(''));
    } else {
      toast.error("Check your details");
    }
  };

  useEffect(() => {
    setClientInfo(singleClient);
  }, [singleClient]);

  return (
    <div className="mt-[38px]">
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-2">
          <div className="flex flex-col mb-5">
            <label htmlFor="">Client Name</label>
            <Input
              name="cntName"
              onChange={handleChange}
              value={clientInfo?.cntName}
              className="input"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
          <div className="flex flex-col mb-5">
            <label htmlFor="">Website</label>
            <Input
              name="cntWebsite"
              onChange={handleChange}
              value={clientInfo?.cntWebsite}
              className="input"
            />
          </div>

          <div className="flex flex-col mb-5">
            <label htmlFor="">Industry</label>
            <Input
              name="cntIndustry"
              onChange={handleChange}
              value={clientInfo?.cntIndustry}
              className="input"
            />
          </div>
        </div>
        <div className="flex flex-col mb-5">
          <label htmlFor="">Client Description</label>
          <TextArea
            rows={4}
            name="cntDescription"
            onChange={handleChange}
            value={clientInfo?.cntDescription}
            className="input"
          />
        </div>

        <div className="flex items-center justify-between mt-[35px]">
          <div className="flex items-start"></div>
          <div className="flex gap-5 items-center">
            <button
              className="text-blueDark text-[1rem] font-medium font-dmSans leading-normal
              w-[168px] h-12 px-[18px] py-3 rounded-[46px] shadow justify-center items-center gap-2 inline-flex"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              className="!text-blueDark text-[1rem] font-medium font-dmSans leading-normal spin-modal
                 w-[168px] h-[46px] px-[18px] py-3 rounded-[46px] border border-blueDark justify-center items-center gap-2 inline-flex"
              type="submit"
              disabled={clientLoading ? true : false}
            >
              {clientLoading ? <Spin /> : "Save"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default BasicInfoEdit;
