import { Spin } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { formatImgPath } from "../../../utils";

function CoverLetter() {
  const { singleJap } = useSelector((state) => state.candidate);
  const [loading, setloading] = useState(true);
  return (
    <>
      <div> 
        <iframe
          onLoad={() => setloading(false)}
          title="Cover Letter"
          width={"100%"}
          height={"700px"}
          src={formatImgPath(singleJap?.japCvlFile)}
        ></iframe>

        {loading ? (
          <>
            <button
              className="bg-blueDark text-white w-[300px] p-3 flex justify-center mt-10"
              disabled
              type="button"
            >
              <Spin />
              <span className="ml-2">Loading cover letter... Please wait</span>
            </button>
          </>
        ) : null}
      </div>
    </>
  );
}

export default CoverLetter;

 