import React, { useRef, useState } from "react";
import { Button, Card, Form, Input, Modal, Spin } from "antd";
import "./client.css"; 
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import uplodSvg from "../../assets/uploadPic.png";
import { saveClient } from "../../features/client/clientSlice";
import toast from "react-hot-toast";
import { saveFile } from "../../features/application/applicationSlice";
import { formatImgPath } from "../../utils";
 
const ClientNameModal = ({
  isModalOpenName,
  setIsModalOpenName,
  showModalName,
  setIsModalOpen
}) => {
  const handleOk = () => {
    setIsModalOpenName(false);
  };
  const handleCancel = () => {
    setIsModalOpenName(false);
    setIsModalOpen(true);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const {user} =  useSelector((state)=>state.auth) 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { clientLoading } = useSelector((state) => state.client);  

  const { agencyObj } = useSelector((state) => state.agency);

  const [loadingAttach, setLoadingAttach] = useState(false);
  const [profile, setProfile] = useState('');
  
  const handleImageSelect = async (event) => {
    setLoadingAttach(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    const res = await dispatch(saveFile(formData));
    if (res?.payload?.success) {
      setLoadingAttach(false);
      setProfile(res?.payload?.jsonData ?? "");
    } else {
      setLoadingAttach(false);
      toast.error("Please try upload your image again");
    }
  };

  const [data, setdata] = useState({});

  const onFinish = async (data) => {
    // if (!profile) {
    //   toast.error("Add logo");
    //   return
    // } 

      const res = await dispatch(
        saveClient({
          cntName: data?.cntName,
          cntInstId: user?.usrInstId,
          cntIcon:profile,
          cntCreatedBy:user?.usrId
        })
      );
      if (res?.payload?.success) {
        toast.success("basic info saved successfull");
        form.resetFields();
        navigate("/client-add");
        // handleOk();
      } else {
        toast.error("Check your details");
      } 
  };
  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={`Create ${agencyObj?.licenseName == "LOCAL LICENSE" ? "local" : "foreign"} employers`} 
        open={isModalOpenName}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <div className="w-[700px] text-black1 text-[22px] font-medium font-dmSans leading-[29px] mb-[35px]">
          Creating a Foreign/ Local Employer will allow you to fill in their details and create
          job vacancies on their behalf.
        </div>

        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="flex items-center w-full gap-x-2">
          <div className="items-center flex gap-5 mb-[23px] w-[20%]">
            {!profile ? (
              <label className="flex flex-row items-center justify-start w-[100%]">
                <input
                  name="secIcon"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleImageSelect(e)}
                />
                <img
                  loading="lazy"
                  className="z-10 w-[100px] h-[100px] rounded-full"
                  alt="tab1"
                  src={uplodSvg}
                  style={{ cursor: "pointer" }}
                />
              </label>
            ) : loadingAttach ? (
              <Spin className="spin-modal" />
            ) : (
              <label className="flex flex-row items-center justify-start gap-2  w-[100%]">
                <input
                  name="secIcon"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageSelect}
                />
                <div className="rounded-full  overflow-hidden border-4 border-blueDark">
                  <img
                    src={formatImgPath(profile)}
                    alt="director"
                    className="w-28 h-28 cursor-pointer object-cover"
                  />
                </div>
              </label>
            )}
          </div>
          <Form.Item
                className="w-full"
                name="cntName"
                label={`${agencyObj?.licenseName == 'LOCAL LICENSE' ?'Local Employer' : 'Foreign Employer'} name`}
                rules={[
                  {
                    required: true,
                    message: `Please add ${agencyObj?.licenseName == 'LOCAL LICENSE' ?'Local Employer' : 'Foreign Employer'} name`,
                  },
                ]}
              >
                <Input className="input" />
              </Form.Item> 
          </div>
          
          <div className="flex justify-between">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center">
              <button
                type="button"
                key="back"
                onClick={handleCancel}
                className="min-w-[138px] cancel-btn"
              >
                Cancel 
              </button>
              
              <button
                key="submit"
                type="submit"
                className="min-w-[239px] save-btn"
                // disabled={loadingAttach || clientLoading ? "true" : "false"}
              >
                {clientLoading ? <Spin /> : "Continue"}
              </button>
              ,
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default ClientNameModal;
