import { Button, Card, Form, Spin, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAttachments,
  saveAttachment,
} from "../../../../features/jobs/jobSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { previewPaper } from "../../../../utils";

const url = process.env.REACT_APP_API_BASE_URL;

function Attachment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setdata] = useState({});
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { refetchKey } = useSelector((state) => state.cv);
  const { eShot, singleCreateJob, viewModeTable, jobEditId, refetch } =
    useSelector((state) => state.jobs);

  const [eshotDoc, setEshotDoc] = useState(eShot);

  useEffect(() => {
    setEshotDoc(eShot);
  }, [eShot]);

  useEffect(() => {}, [eshotDoc]);

  const [fileUrl, setfileUrl] = useState("");
  const [fileName, setfileName] = useState("");
  const [fileLoading, setFileLoading] = useState(false);

  const { applicantEditId, applicants, singleCreateApplicant } = useSelector(
    (state) => state.applicant
  );

  const [receivedId, setReceivedId] = useState(
    applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
  );

  useEffect(() => {
    setReceivedId(
      applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
    );
  }, [applicantEditId, singleCreateApplicant?.usrId]);

  const props = {
    name: "file",
    multiple: false,
    accept: "image/*",
    action: `${url}/nea/saveFile.action`,
    onChange(info) {
      setFileLoading(true);
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        setFileLoading(false);
        message.success(`${info.file.name} file attached successfully.`);
        if (info?.file?.response?.success) {
          setfileUrl(info?.file?.response?.jsonData);
          setfileName(info?.file?.name);
        } else {
          setfileUrl("");
          setfileName("");
        }
        console.log(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setFileLoading(false);
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (fileUrl) {
      onFinish();
    }
  }, [fileUrl]);
  const onFinish = async (values) => {
    const res = await dispatch(
      saveAttachment({
        attInstId: receivedId,
        attType: "ESHOT",
        attFile: fileUrl,
        attId: eshotDoc?.attId ? eshotDoc?.attId : null,
      })
    );
    if (res?.payload?.attId) {
      toast.success("E-shot uploaded successfully");
      fetchEshot();
      setfileUrl("");
      setEditMode(false);
    } else {
      toast.error("Error occured, please try again");
    }
  };

  async function fetchEshot() {
    const res = await dispatch(
      fetchAttachments({
        attInstId: receivedId,
        attType: "ESHOT",
      })
    );
  }

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (refetchKey == 7) {
      fetchEshot();
    }
  }, [refetch, refetchKey]);

  return (
    <>
      <Card className="p-[20px] mr-2 rounded-[0px]">
        {eShot &&
        typeof eShot === "object" &&
        Object.keys(eShot)?.length > 0 &&
        !editMode ? (
          <>
            <div className="w-[700px] h-[79px] bg-[#F2FAFF] text-center items-center justify-between px-[30px]  flex">
              <div className=" text-black text-[22px] font-bold font-dmSans leading-[33px] flex justify-start">
                Upload Job E-Shot
              </div>
              {!jobEditId ? (
                <div className="flex justify-end">
                  <svg
                    onClick={() => setEditMode(true)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 4.00023H4C3.46957 4.00023 2.96086 4.21094 2.58579 4.58601C2.21071 4.96109 2 5.46979 2 6.00023V20.0002C2 20.5307 2.21071 21.0394 2.58579 21.4144C2.96086 21.7895 3.46957 22.0002 4 22.0002H18C18.5304 22.0002 19.0391 21.7895 19.4142 21.4144C19.7893 21.0394 20 20.5307 20 20.0002V13.0002M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                      stroke="#101828"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              ) : null}
            </div>
            <iframe
              src={previewPaper(eshotDoc?.attFile)}
              style={{
                width: "100%",
                minHeight: "100vh",
                backgroundColor: "#FAFDFF",
              }}
              title="File Preview"
            />
          </>
        ) : (
          <>
            <div className="w-[700px] h-[79px] bg-[#F2FAFF] text-center items-center justify-between px-[30px]  flex">
              <div className=" text-black text-[22px] font-bold font-dmSans leading-[33px] flex justify-start">
                Upload Job E-Shot
              </div>
              {editMode && (
                <div
                  className="flex justify-end font-bold text-[22px] cursor-pointer"
                  onClick={() => setEditMode(false)}
                >
                  X
                </div>
              )}
            </div>
            <div className="grid grid-cols-2">
              <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                className=" "
                style={{
                  maxWidth: "100%",
                }}
                form={form}
              >
                <div className="grid grid-cols-1">
                  <Form.Item
                    name="attFile"
                    rules={[
                      {
                        required: true,
                        message: "Please upload a file",
                      },
                    ]}
                  >
                    <Dragger className="attachment" {...props}>
                      <div className="flex flex-col justify-center">
                        <div className="flex justify-center mb-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="29"
                            height="29"
                            viewBox="0 0 29 29"
                            fill="none"
                          >
                            <path
                              d="M25.375 18.125V22.9583C25.375 23.5993 25.1204 24.214 24.6672 24.6672C24.214 25.1204 23.5993 25.375 22.9583 25.375H6.04167C5.40073 25.375 4.78604 25.1204 4.33283 24.6672C3.87961 24.214 3.625 23.5993 3.625 22.9583V18.125"
                              stroke="#147CBC"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M20.5423 9.66667L14.5007 3.625L8.45898 9.66667"
                              stroke="#147CBC"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.5 3.625V18.125"
                              stroke="#147CBC"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <p className="ant-upload-text !ml-2">
                          Click to upload
                          <span className="ml-2 text-[#667085]">
                            or drag and drop
                          </span>
                          <br></br>
                          <span className="!mt-[10rem] text-[#667085]">
                            PNG or JPG (max. 800x400px)
                          </span>
                        </p>
                      </div>
                    </Dragger>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </>
        )}
      </Card>
    </>
  );
}

export default Attachment;
