import { Steps } from 'antd';
import { useEffect, useState } from 'react';
import StepOne from './license_steps/step_one/StepOne';
import { useDispatch, useSelector } from 'react-redux';
import { handleLicenseStep } from '../../features/license/licenseSlice';
import StepTwo from './license_steps/step_two/StepTwo';
import StepThree from './license_steps/step_three/StepThree';
import License from './License';

export default function MainLicensePage() {
	const dispatch = useDispatch();

	const { licenseStep } = useSelector((state) => state.license);
	const { agencyObj } = useSelector((state) => state.agency);

	const [current, setcurrent] = useState(licenseStep);

	useEffect(() => {
		if (
			agencyObj?.instStatus === 'PAYMENT_PENDING_CONFIRMATION' ||
			agencyObj?.instStatus === 'REJECT_RECEIPT'
		) {
			dispatch(handleLicenseStep(2));
		}
	}, [agencyObj]);

	const steps = [
		{
			title: 'STEP 1',
			subTitle: <div className='font-[500]'>payment Details</div>,
			description: (
				<>
					{current === 0 ? (
						<div className='bg-[#DBEBF7] text-blueDark rounded-xl text-center px-2 w-fit truncate'>
							In progress
						</div>
					) : current > 0 ? (
						<div className='bg-blueDark text-white rounded-xl text-center px-2 w-20'>
							Complete
						</div>
					) : (
						<div className='bg-blueDark text-white rounded-xl text-center px-2 w-20'>
							Complete
						</div>
					)}
				</>
			),
			content: <StepOne />,
			icon: (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'>
					<path
						d='M18.7085 7.20986C18.6155 7.11613 18.5049 7.04174 18.3831 6.99097C18.2612 6.9402 18.1305 6.91406 17.9985 6.91406C17.8665 6.91406 17.7358 6.9402 17.6139 6.99097C17.492 7.04174 17.3814 7.11613 17.2885 7.20986L9.83849 14.6699L6.70849 11.5299C6.61196 11.4366 6.49802 11.3633 6.37317 11.3141C6.24831 11.2649 6.11499 11.2408 5.98081 11.2431C5.84663 11.2454 5.71422 11.2741 5.59114 11.3276C5.46806 11.3811 5.35672 11.4583 5.26348 11.5549C5.17025 11.6514 5.09693 11.7653 5.04773 11.8902C4.99852 12.015 4.97439 12.1484 4.97672 12.2825C4.97904 12.4167 5.00776 12.5491 5.06126 12.6722C5.11475 12.7953 5.19196 12.9066 5.28848 12.9999L9.12849 16.8399C9.22145 16.9336 9.33205 17.008 9.45391 17.0588C9.57577 17.1095 9.70647 17.1357 9.83849 17.1357C9.9705 17.1357 10.1012 17.1095 10.2231 17.0588C10.3449 17.008 10.4555 16.9336 10.5485 16.8399L18.7085 8.67986C18.81 8.58622 18.891 8.47257 18.9464 8.34607C19.0018 8.21957 19.0304 8.08296 19.0304 7.94486C19.0304 7.80676 19.0018 7.67015 18.9464 7.54365C18.891 7.41715 18.81 7.3035 18.7085 7.20986Z'
						fill='#147CBC'
					/>
				</svg>
			),
		},
		{
			title: 'STEP 2',
			subTitle: <div className='font-[500] w-full truncate'>Make Payment</div>,
			description: (
				<>
					{current === 1 ? (
						<div className='bg-[#DBEBF7] text-blueDark rounded-xl text-center px-2 w-fit truncate'>
							In progress
						</div>
					) : current > 1 ? (
						<div className='bg-blueDark text-white rounded-xl text-center px-2 w-20'>
							Complete
						</div>
					) : (
						<div className='bg-[#7E93AE40] rounded-xl text-center w-16'>
							Pending
						</div>
					)}
				</>
			),
			content: <StepTwo />,
			icon: (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'>
					<path
						d='M13 9H11V7C11 6.73478 10.8946 6.48043 10.7071 6.29289C10.5196 6.10536 10.2652 6 10 6C9.73478 6 9.48043 6.10536 9.29289 6.29289C9.10536 6.48043 9 6.73478 9 7V9H7C6.73478 9 6.48043 9.10536 6.29289 9.29289C6.10536 9.48043 6 9.73478 6 10C6 10.2652 6.10536 10.5196 6.29289 10.7071C6.48043 10.8946 6.73478 11 7 11H9V13C9 13.2652 9.10536 13.5196 9.29289 13.7071C9.48043 13.8946 9.73478 14 10 14C10.2652 14 10.5196 13.8946 10.7071 13.7071C10.8946 13.5196 11 13.2652 11 13V11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9ZM18 15V5C18 4.20435 17.6839 3.44129 17.1213 2.87868C16.5587 2.31607 15.7956 2 15 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V15C2 15.7956 2.31607 16.5587 2.87868 17.1213C3.44129 17.6839 4.20435 18 5 18H15C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15ZM4 15V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H15C15.2652 4 15.5196 4.10536 15.7071 4.29289C15.8946 4.48043 16 4.73478 16 5V15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16H5C4.73478 16 4.48043 15.8946 4.29289 15.7071C4.10536 15.5196 4 15.2652 4 15ZM21 6C20.7348 6 20.4804 6.10536 20.2929 6.29289C20.1054 6.48043 20 6.73478 20 7V17C20 17.7956 19.6839 18.5587 19.1213 19.1213C18.5587 19.6839 17.7956 20 17 20H7C6.73478 20 6.48043 20.1054 6.29289 20.2929C6.10536 20.4804 6 20.7348 6 21C6 21.2652 6.10536 21.5196 6.29289 21.7071C6.48043 21.8946 6.73478 22 7 22H17C18.3261 22 19.5979 21.4732 20.5355 20.5355C21.4732 19.5979 22 18.3261 22 17V7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6Z'
						fill='#808080'
					/>
				</svg>
			),
		},
		{
			title: 'STEP 3',
			subTitle: <div className='font-[500] w-full truncate'>Approval</div>,
			description: (
				<>
					{current === 2 ? (
						<div className='bg-[#DBEBF7] text-blueDark rounded-xl text-center px-2 w-fit truncate'>
							In progress
						</div>
					) : current > 2 ? (
						<div className='bg-blueDark text-white rounded-xl text-center px-2 w-20'>
							Complete
						</div>
					) : (
						<div className='bg-[#7E93AE40] rounded-xl text-center w-16'>
							Pending
						</div>
					)}
				</>
			),
			content: <StepThree />,
			icon: (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'>
					<path
						d='M13 9H11V7C11 6.73478 10.8946 6.48043 10.7071 6.29289C10.5196 6.10536 10.2652 6 10 6C9.73478 6 9.48043 6.10536 9.29289 6.29289C9.10536 6.48043 9 6.73478 9 7V9H7C6.73478 9 6.48043 9.10536 6.29289 9.29289C6.10536 9.48043 6 9.73478 6 10C6 10.2652 6.10536 10.5196 6.29289 10.7071C6.48043 10.8946 6.73478 11 7 11H9V13C9 13.2652 9.10536 13.5196 9.29289 13.7071C9.48043 13.8946 9.73478 14 10 14C10.2652 14 10.5196 13.8946 10.7071 13.7071C10.8946 13.5196 11 13.2652 11 13V11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10C14 9.73478 13.8946 9.48043 13.7071 9.29289C13.5196 9.10536 13.2652 9 13 9ZM18 15V5C18 4.20435 17.6839 3.44129 17.1213 2.87868C16.5587 2.31607 15.7956 2 15 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V15C2 15.7956 2.31607 16.5587 2.87868 17.1213C3.44129 17.6839 4.20435 18 5 18H15C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15ZM4 15V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H15C15.2652 4 15.5196 4.10536 15.7071 4.29289C15.8946 4.48043 16 4.73478 16 5V15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16H5C4.73478 16 4.48043 15.8946 4.29289 15.7071C4.10536 15.5196 4 15.2652 4 15ZM21 6C20.7348 6 20.4804 6.10536 20.2929 6.29289C20.1054 6.48043 20 6.73478 20 7V17C20 17.7956 19.6839 18.5587 19.1213 19.1213C18.5587 19.6839 17.7956 20 17 20H7C6.73478 20 6.48043 20.1054 6.29289 20.2929C6.10536 20.4804 6 20.7348 6 21C6 21.2652 6.10536 21.5196 6.29289 21.7071C6.48043 21.8946 6.73478 22 7 22H17C18.3261 22 19.5979 21.4732 20.5355 20.5355C21.4732 19.5979 22 18.3261 22 17V7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6Z'
						fill='#808080'
					/>
				</svg>
			),
		},
	];

	const items = steps.map((item) => ({
		key: item.title,
		description: item.description,
		icon: item.icon,
		title: item.title,
		subTitle: item.subTitle,
	}));

	async function handlePaymentStepOne() {
		await dispatch(handleLicenseStep(0));
	}

	useEffect(() => {}, [current]);

	useEffect(() => {
		setcurrent(licenseStep);
	}, [licenseStep]);

	return (
		<>
			<div className='w-[100%] h-auto bg-white p-10'>
				<div className='flex w-full h-[47px] relative'>
					<button
						onClick={handlePaymentStepOne}
						className='triangleStyle3 w-[50%] flex justify-center items-center'>
						<svg
							className='mr-5'
							xmlns='http://www.w3.org/2000/svg'
							width='34'
							height='34'
							viewBox='0 0 34 34'
							fill='none'>
							<path
								d='M26.5043 10.2159C26.3726 10.0831 26.216 9.97775 26.0433 9.90583C25.8707 9.8339 25.6855 9.79688 25.4985 9.79688C25.3115 9.79688 25.1263 9.8339 24.9537 9.90583C24.7811 9.97775 24.6244 10.0831 24.4927 10.2159L13.9385 20.7843L9.50434 16.3359C9.3676 16.2038 9.20618 16.1 9.0293 16.0303C8.85243 15.9606 8.66355 15.9264 8.47346 15.9297C8.28337 15.933 8.09579 15.9737 7.92143 16.0494C7.74707 16.1252 7.58934 16.2346 7.45725 16.3713C7.32517 16.5081 7.22131 16.6695 7.1516 16.8464C7.08189 17.0233 7.04771 17.2121 7.051 17.4022C7.05429 17.5923 7.09498 17.7799 7.17077 17.9542C7.24655 18.1286 7.35593 18.2863 7.49267 18.4184L12.9327 23.8584C13.0644 23.9912 13.2211 24.0966 13.3937 24.1685C13.5663 24.2404 13.7515 24.2775 13.9385 24.2775C14.1255 24.2775 14.3107 24.2404 14.4833 24.1685C14.656 24.0966 14.8126 23.9912 14.9443 23.8584L26.5043 12.2984C26.6481 12.1658 26.7629 12.0048 26.8414 11.8255C26.9199 11.6463 26.9604 11.4528 26.9604 11.2572C26.9604 11.0615 26.9199 10.868 26.8414 10.6888C26.7629 10.5096 26.6481 10.3486 26.5043 10.2159Z'
								fill='#147CBC'
							/>
						</svg>
						Application Stage
					</button>
					<div className='triangleStyle4 w-[45%] flex justify-center items-center'>
						Purchase License
					</div>
					<div className='triangleStyle5 w-[5%] flex justify-center items-center'></div>
				</div>
			</div>

			{agencyObj?.instStatus === 'LICENSE_PAID' ||
			agencyObj?.instStatus === 'NINETY_EXPIRATION' ||
			agencyObj?.instStatus === 'EXPIRED' ||
			agencyObj?.instStatus === 'THIRTY_EXPIRATION' ? (
				<>
					<div className='mt-3 bg-white w-full p-10'>
						<License />
					</div>
				</>
			) : (
				<>
					<div className='mt-10 px-10'>
						<Steps
							className=''
							current={current}
							size='small'
							labelPlacement='vertical'
							items={items}
						/>
					</div>
					<div className='mt-3 bg-white w-full p-10'>
						<div className='w-full mt-5'>{steps[current].content}</div>
					</div>
				</>
			)}
		</>
	);
}
