import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanUpEditId,
  setEditContact,
  setViewContact,
} from "../../features/client/clientSlice";
import ApplicantAddModal from "./applicantsModal/ApplicantAddModal";
import addApplicant from "../../assets/addApplicant.svg";
import {
  cleanUpapplicantEditId,
  cleanUpapplicantSingleCreateApplicant,
  cleanupApplicant,
  fetchApplicantsById,
  fetchApplicantsDetails,
  setApplicantEditId,
  setEditApplicantDetails,
  setEditDisability,
  setEditEducation,
  setEditJobSeekerInformation,
  setEditSkills,
  setTabsActiveKey,
  setViewApplicantDetails,
  setViewDisability,
  setViewEducation,
  setViewJobSeekerInformation,
  setViewSkills,
} from "../../features/applicant/applicantSlice";
import { Skeleton } from "antd";
import ApplicantTabs from "./applicant-tabs/ApplicantTabs";
import ApplicantDrawer from "./applicant-tabs/ApplicantDrawer";
import { setRefetch, setRefetchKey } from "../../features/cv/cvSlice";
import { cleanUpJobEditId } from "../../features/jobs/jobSlice";

function ApplicantTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = async () => {
    await dispatch(cleanupApplicant());
    await dispatch(cleanUpapplicantEditId());
    await dispatch(setViewContact(false));
    await dispatch(setEditContact(false));
    setIsModalOpen(true);
  };

  const { applicants } = useSelector((state) => state.applicant);

  const { user } = useSelector((state) => state.auth);

  const [searchText, setSearchText] = useState("");

  const [open, setOpen] = useState(false);
  const [prodd, setProdd] = useState("");

  const [applicantsList, setApplicantsList] = useState(applicants);

  console.log("applicantsList", applicantsList);
  useEffect(() => {
    setApplicantsList(applicants);
  }, [applicants]);

  const [applicantFiltered, setApplicantFiltered] = useState(applicantsList);

  useEffect(() => {
    const newItem = applicantsList?.length >0 && applicantsList?.filter((item)=>item?.usrId !=user?.usrId)
    setApplicantFiltered(newItem);
  }, [applicantsList]);

  useEffect(() => {}, [applicants]);

  const showApplicant = async (id) => {
    await dispatch(setViewApplicantDetails(true));
    await dispatch(setEditApplicantDetails(false));
    await dispatch(setViewJobSeekerInformation(true));
    await dispatch(setEditJobSeekerInformation(false));
    await dispatch(setViewContact(true));
    await dispatch(setEditContact(false));
    await dispatch(setViewSkills(true));
    await dispatch(setEditSkills(false));
    await dispatch(setViewEducation(true));
    await dispatch(setEditEducation(false));
    await dispatch(setViewDisability(true));
    await dispatch(setEditDisability(false));
    await dispatch(cleanUpapplicantSingleCreateApplicant());
    await dispatch(setRefetch());
    await dispatch(setApplicantEditId(id));
    await dispatch(setRefetchKey("1"));
    await dispatch(setTabsActiveKey("1"));
    await dispatch(cleanUpJobEditId());
    setOpen(true);
  };

  async function fetchApplicants() {
    const res = await dispatch(
      fetchApplicantsDetails({
        usrInstId: user?.usrInstId,
      })
    );
  }

  useEffect(() => {
    fetchApplicants();
  }, []);
  const columns = [
    {
      title: "Full name",
      render: (item) => (
        <>
          <div className="grid grid-cols-2">
            <div className="flex items-center">
              <span className="table-name mx-3">{item?.usrFullNames}</span>
            </div>

            <div className="flex items-center">
              <button
                className="p-0 mx-4 flex items-end"
                type="button"
                onClick={() =>
                  dispatch(
                    fetchApplicantsById({
                      usrId: item?.usrId,
                    })
                  )
                }
              >
                <MaterialIcon
                  color="#000"
                  icon="visibility"
                  onClick={() => showApplicant(item?.usrId)}
                />
              </button>
              <button className="p-0 m-0 flex items-end" type="button">
                <MaterialIcon color="#000" icon="more_vert" />
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "NEA Reg No.",
      dataIndex: "usrCode",
    },
    {
      title: "Status",
      dataIndex: "usrStatus",
    },
    {
      title: "Current Position",
      render: (item) => {
        return <div>N/A</div>;
      },
    },
    {
      title: "Job Owner",
      render: (item) => {
        return <div>{user?.usrFullNames}</div>;
      },
    },
  ]; 
 
 
  // applicantsList?.filter((item) => {
  //   if (searchText) {
  //     return item?.usrFullNames
  //       ?.toLowerCase()
  //       .includes(searchText.toLowerCase());
  //   } else {
  //     return item;
  //   }
  // });

    const filteredData = applicantFiltered && applicantFiltered?.length > 0 && Array.isArray(applicantFiltered) && searchText
  ? applicantFiltered?.filter((item) =>
      item?.usrFullNames?.toLowerCase().includes(searchText.toLowerCase())
    )
  : applicantFiltered;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));
  return (
    <>
      <div className="min-h-[895px] bg-white border border-gray-200  mt-[1%]">
        <div className=" p-[50px]">
          <div className="text-black text-[22px] font-bold font-dmSans leading-[33px] mb-[31px]">
            List of Candidates
          </div>
          <div className="flex items-center justify-between ">
            <div className="flex items-start">
              <form class="flex items-center px-10">
                <div class="relative w-full">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 mr-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="voice-search"
                    className="bg-gray-50 !border-none text-black1 text-[19px] font-normal font-dmSans leading-7 rounded-lg  block w-full pl-10 p-2.5  h-[52px] px-[18px]
                        dark:placeholder-gray-400 dark:text-white "
                    placeholder="Looking for a specific candidate?"
                    required
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="text-white text-[1rem] font-medium font-dmSans leading-normal
    w-[294px] h-[52px] px-[18px] py-[19px] bg-blueDark rounded shadow border border-[#F6F6F6] justify-center items-center gap-2 inline-flex"
                >
                  Search candidate
                </button>
              </form>
            </div>
            <div className="items-end">
              <Button
                className="w-[209px] h-14 px-7 py-4 bg-blueDark rounded-md shadow justify-center items-center gap-2 inline-flex
        !text-blue-50 text-[1rem] font-medium font-dmSans leading-7"
                onClick={showModal}
              >
                <img src={addApplicant} alt="addapplicant icon" />
                Add candidate
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-[6px] ml-[45px] mr-[10px]">
          <Table
            rowSelection={true}
            size={"middle"}
            bordered={true}
            pagination={{
              position: ["bottomCenter"],
            }}
            rowKey={(record) => record?.usrId}
            columns={tableColumns}
            dataSource={filteredData}
            scroll={"scroll"}
            className="custom-table"
          />
        </div>
      </div>

      <ApplicantAddModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        showModal={showModal}
      />

      <ApplicantDrawer open={open} setOpen={setOpen} />
    </>
  );
}

export default ApplicantTable;
