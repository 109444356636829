import React from "react";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/Back.svg";

function Header() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="bg-white mr-3 mb-[10px]">
      <div className="items-center px-[54px] flex text-center h-[115px] justify-between">
        <span className="text-black1 text-2xl font-bold font-dmSans leading-[54px] justify-start">
          Activities
        </span>

        <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={handleBack}
            >
              <div
                style={{
                  height: "35px",
                  width: "35px",
                  background: "#EDF8FF",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={backArrow}
                  alt="back-arrow"
                  style={{ height: "40px", width: "40px" }}
                />
              </div>

              <span className="text-black2 text-[22px] font-bold font-dmSans leading-relaxed">
                Go back
              </span>
            </div>
      </div>
    </div>
  );
}

export default Header;
