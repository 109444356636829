import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Breadcrumb,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  theme,
} from "antd";
import toast from "react-hot-toast";
import { saveEmployee } from "../../../features/myEmployees/myEmployeesSlice";
import { checkEditPermission, formatImgPath } from "../../../utils";
import moment from "moment";
import dayjs from "dayjs";
import {
  fetchAwards,
  fetchCourses,
  fetchGender,
  fetchGrades,
  fetchInstitutionTypes,
  fetchJobTypes,
  fetchMaritalStatus,
  fetchNationalitys,
  fetchStudyAreas,
} from "../../../features/dropDowns/dropDownSlice";

function EditEmployee() {
  const { token } = theme.useToken();
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { employeeLoading, employeeEditObj, employeeReturnsPage } = useSelector(
    (state) => state.employee
  );
  const {
    dropDownLoading,
    instTypes,
    studyAreas,
    awards,
    courses,
    grades,
    jobTypes,
    maritalStatus,
    genders,
    nationalityOptions,
  } = useSelector((state) => state.dropDown);
  const { menuSidebarData } = useSelector((state) => state.admin);
  const today = new Date();
  let dd = String(today.getDate()).padStart(2, 0);
  let mm = String(today.getMonth() + 1).padStart(2, 0);
  let yyyy = today.getFullYear() - 18;

  let minDate = `${yyyy}-${mm}-${dd}`;

  function handleGoBack() {
    navigate(-1);
  }

  function handleCancel() {
    if (employeeReturnsPage) {
      navigate("/employment-returns");
    } else {
      navigate("/employees");
    }
  }

  async function handleFetchCourses(name) {
    let ardObj = awards?.find(
      (item) =>
        String(item?.awdName).toUpperCase() === String(name).toUpperCase()
    );
    await dispatch(fetchCourses(ardObj?.awdId));
  }

   

  const panelStyle = {
    marginBottom: 24,
    background: "#fff",
    borderRadius: token.borderRadiusLG,
    border: "1px solid #147CBC",
  };

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#147CBC" />
            <circle cx="12.7904" cy="12.7943" r="2.32558" fill="#147CBC" />
          </svg>

          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Employee Details
          </div>
        </div>
      ),
      children: (
        <div className="p-10">
          <span className="accordion-sub-title">Basic Info</span>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{
              maxWidth: "100%",
              marginTop: "30px",
            }}
            initialValues={{
              ...employeeEditObj,
              emplContractCommencement: dayjs(
                employeeEditObj?.emplContractCommencement
              ),
              emplDob: dayjs(employeeEditObj?.emplDob),
            }}
            form={form}
          >
            <div className="grid grid-cols-2 gap-x-10 w-full mb-5">
              <Form.Item name="emplFirstName" label="First Name">
                <Input className="input" />
              </Form.Item>
              <Form.Item name="emplMiddleName" label="First Name">
                <Input className="input" />
              </Form.Item>
              <Form.Item name="emplLastName" label="Last Name">
                <Input className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                name="emplDesc"
                label="Occupation Name"
              >
                <Input className="input" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                name="emplTitle"
                label="Job Title"
              >
                <Input className="input" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="emplNationalId"
                label="National ID"
              >
                <Input className="input" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="emplKraPin"
                label="KRA PIN"
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                name="emplEmploymentNo"
                label="Employee No."
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                label="Terms of Service"
                name="emplServiceTerms"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                  }}
                  className=""
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    jobTypes?.length > 0 &&
                    jobTypes?.map((item) => ({
                      value: item.jtpName,
                      label: item.jtpName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                name="emplGender"
                label="Gender"
                rules={[
                  {
                    required: false,
                    message: "Gender is required",
                  },
                ]}
              >
                <Select
                  placeholder="Select gender"
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    genders?.length &&
                    genders?.map((item) => {
                      return {
                        label: item?.gndName,
                        value: item?.gndName,
                      };
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                name="emplMaritalStatus"
                label="Marital Status"
                rules={[
                  {
                    required: false,
                    message: "Please select your marital status",
                  },
                ]}
              >
                <Select
                  placeholder="Select your marital status"
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    maritalStatus?.length &&
                    maritalStatus?.map((item) => {
                      return {
                        label: item?.marName,
                        value: item?.marName,
                      };
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                name="emplNationality"
                label="Nationality"
                rules={[
                  {
                    required: false,
                    message: "Nationality is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  maxCount={1}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  allowClear
                  options={
                    nationalityOptions?.length > 0 &&
                    nationalityOptions?.map((item) => ({
                      value: item?.natName,
                      label: item?.natName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                name="emplDob"
                label="Date of Birth"
              >
                <DatePicker maxDate={dayjs(minDate)} className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                name="emplContractCommencement"
                label="Hired Date"
              >
                <DatePicker maxDate={dayjs(new Date())} className="input" />
              </Form.Item>

              {/* <Form.Item
								rules={[
									{
										required: false,
										message: 'Field is required',
									},
								]}
								name='emplStatus'
								label='Employment Status'
							>
								<Select
									style={{
										width: '100%',
										height: '44px',
									}}
									options={employmentStatus}
									onChange={(value) => {
										handleChange(value);
									}}
									onDropdownVisibleChange={() => {
									}}
								/>
							</Form.Item> */}
            </div>

            <span className="accordion-sub-title">Highest Academic Info</span>

            <div className="grid grid-cols-2 gap-5 mb-5 w-full mt-5">
              <Form.Item
                label="Institution Type"
                name="emplInsttype"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  className="mt-2"
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    instTypes?.length > 0 &&
                    instTypes?.map((item) => ({
                      value: item.instpName,
                      label: item.instpName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                label="Area of Study"
                name="emplAreaOfStudy"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  className="mt-2"
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    studyAreas?.length > 0 &&
                    studyAreas?.map((item) => ({
                      value: item.stdyName,
                      label: item.stdyName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                label="Award"
                name="emplAward"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  className=""
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  onChange={(val) => {
                    handleFetchCourses(val);
                  }}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    awards?.length > 0 &&
                    awards?.map((item) => ({
                      value: item.awdName,
                      label: item.awdName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                name="emplCourse"
                label="Course"
              >
                <Input className="input" />
                </Form.Item>
             
              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                label="Grade"
                name="emplGrade"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  className=""
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    grades?.length > 0 &&
                    grades?.map((item) => ({
                      value: item.grdeName,
                      label: item.grdeName,
                    }))
                  }
                />
              </Form.Item>

              {/* <Form.Item
								rules={[
									{
										required: true,
										message: 'Field is required',
									},
								]}
								name='grdStartDate'
								label='Start Date'>
								<DatePicker className='input' />
							</Form.Item>

							<Form.Item
								rules={[
									{
										required: true,
										message: 'Field is required',
									},
								]}
								name='grdGradDate'
								label='Graduation Date'>
								<DatePicker className='input' />
							</Form.Item> */}
            </div>

            <span className="accordion-sub-title">
              Professional Training Info
            </span>

            <div className="grid grid-cols-2 gap-5 mb-5 w-full mt-5">
              <Form.Item
                label="Professional Qualification"
                name="emplProfessionalQualification"
                className="flex flex-col w-[100%]"
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                label="Highest Training by Employer"
                name="emplTrainingObtained"
                className="flex flex-col w-[100%]"
              >
                <Input className="input" />
              </Form.Item>
            </div>

            <span className="accordion-sub-title mt-10">Contact Info</span>

            <div className="grid grid-cols-2 gap-x-10 w-full mt-5">
              <Form.Item name="emplEmail" label="Email">
                <Input className="input" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="emplMobileNo"
                label="Phone number"
              >
                <InputNumber
                  maxLength={10}
                  minLength={9}
                  className="input-phone"
                />
              </Form.Item>
            </div>

            <div className="flex justify-between mt-[56px]">
              <div className="justify-start"></div>
              <div className="justify-end flex items-center gap-x-2">
                <button
                  onClick={handleCancel}
                  disabled={employeeLoading}
                  type="button"
                  className="w-[200px] cancel-btn flex items-center justify-center border border-blueDark"
                >
                  Cancel
                </button>
                {checkEditPermission(menuSidebarData, "EMPLOYMENT_RETURN") && (
                   <button
                   key="submit"
                   type="submit"
                   className="w-[200px] save-btn"
                   disabled={employeeLoading}
                 >
                   {employeeLoading ? <Spin /> : "Save"}
                 </button>
                )} 
              </div>
            </div>
          </Form>
        </div>
      ),
      showArrow: true,
      style: panelStyle,
    },
  ];

  const onFinish = async (values) => {
    values.emplContractCommencement = values.emplContractCommencement.$d;
    values.emplDob = values.emplDob.$d;

    const res = await dispatch(
      saveEmployee({
        ...employeeEditObj,
        ...values,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      form.resetFields();
      if (employeeReturnsPage) {
        navigate("/employment-returns");
      } else {
        navigate("/employees");
      }
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };
  const onFinishFailed = (errorInfo) => { 
    toast.error("Please fill in all required fields correctly.");
  };

  async function handleDropdownOptions() {
    await dispatch(fetchJobTypes());
    await dispatch(fetchMaritalStatus());
    await dispatch(fetchGender());
    await dispatch(fetchNationalitys());
    await dispatch(fetchInstitutionTypes());
    await dispatch(fetchAwards());
    await dispatch(fetchStudyAreas());
    await dispatch(fetchGrades());
  }

  useEffect(() => {
    handleDropdownOptions();
  }, []);

  useEffect(() => {}, [
    instTypes,
    studyAreas,
    awards,
    courses,
    grades,
    jobTypes,
    maritalStatus,
    genders,
    nationalityOptions,
  ]);

  useEffect(() => {}, [employeeEditObj]);

  return (
    <>
      <div className="bg-lightBlue   flex items-center justify-between h-auto px-10 py-[60px]">
        <div className="items-center flex text-center gap-x-5">
          <img
            className="h-[91px] w-[91px] rounded-full object-contain border-2 border-black2"
            src={
              employeeEditObj?.emplIcon
                ? formatImgPath(employeeEditObj?.emplIcon)
                : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
            }
            alt="employee-profile"
          />
          <span className="text-black1 text-2xl font-bold font-dmSans leading-[54px]">
            {employeeEditObj?.emplFirstName} {employeeEditObj?.emplMiddleName}{" "}
            {employeeEditObj?.emplLastName}
          </span>
        </div>
      </div>

      <div className="mt-5 px-10">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer "
                  onClick={() => navigate("/employment-returns")}
                >
                  Employees
                </span>
              ),
            },

            {
              title: (
                <span className="text-blueDark font-dmSans">Edit employee</span>
              ),
            },
          ]}
        />
      </div>

      <div className="bg-white mr-3 mb-[10px] flex items-center justify-between h-auto px-[39px]">
        <div className="flex flex-col w-[100%]">
          <div className="w-full flex justify-between items-center my-[51px]">
            <h3 className="dash-title font-[700] text-[24px] text-black1">
              Update employee details
            </h3>
          </div>
          <Collapse
            bordered={false}
            expandIconPosition="end"
            defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
            style={{
              background: "#fff",
            }}
            items={getItems(panelStyle)}
          />
        </div>
      </div>
    </>
  );
}

export default EditEmployee;
