import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
	fetchAgencyDetails,
	saveAgencyInfo,
} from '../../../../features/application/applicationSlice';
import FileUploader from '../../../../components/FileUploader';
import dayjs from 'dayjs';

const url = process.env.REACT_APP_API_BASE_URL;

const CompanyRegistration = ({ item }) => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { agencyObj } = useSelector((state) => state.agency);

	const [formData, setFormData] = useState();

	const handleSuccess = (response) => {
		setFormData((prev) => ({
			...prev,
			[response.fileName]: response.jsonData,
		}));
	};

	const handleError = (response) => {
		console.error('File upload failed:', response);
	};

	const onChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	async function handleAgencyData() {
		const obj = {
			agcInstId: user?.usrInstId,
			agcAdsId: item?.adsId,
		};
		const res = await dispatch(fetchAgencyDetails(obj));
		if (res?.payload?.success) {
			setFormData(res?.payload?.data?.result[0]);
		}
	}

	const submitData = async () => {
		let obj = {
			...formData,
			agcInstId: user?.usrInstId,
			agcType: item?.adsType,
			agcAdsId: item?.adsId,
		};
		await dispatch(saveAgencyInfo(obj));
		await handleAgencyData();
	};

	useEffect(() => {}, [user, formData]);

	useEffect(() => {
		if (formData?.agcFile?.length) {
			submitData();
		}
	}, [formData?.agcFile]);

	useEffect(() => {
		handleAgencyData();
	}, []);

	return (
		<div className='grid grid-cols-4 items-end w-full gap-10'>
			{item?.adsTitle && (
				<div className='flex flex-col w-full'>
					<div className='font-[500]'>{item?.adsDesc}</div>
					<Input
						readOnly={item?.adsDesc === 'Company Reg No.'}
						onBlur={submitData}
						className='rounded-[8px] mt-2  h-[44px] w-full border border-black'
						onChange={(e) => onChange(e)}
						name='agcNo'
						value={
							item?.adsDesc === 'Company Reg No.'
								? agencyObj?.instRegCertNo
								: formData?.agcNo
						}
					/>
				</div>
			)}

			{item?.adsUpload && (
				<div className='flex flex-col w-full'>
					<div className='font-[500]'>Upload</div>
					<FileUploader
						fileName={formData?.agcFile?.split('_').pop()}
						name='agcFile'
						url={url}
						onSuccess={handleSuccess}
						onError={handleError}
					/>
				</div>
			)}

			{(item?.adsIssueDate || item?.adsLeaseStartDate) && (
				<div className='flex flex-col w-full'>
					<div className='font-[500]'>
						{item?.adsLeaseStartDate ? 'Lease Start Date' : 'Date of Issue'}
					</div>
					<Input
						onBlur={submitData}
						onChange={(e) => onChange(e)}
						name='agcIssueDate'
						className='rounded-[8px] mt-2 h-[44px] w-full border border-black'
						type='date'
						max={dayjs(new Date()).format('YYYY-MM-DD')}
						value={
							formData?.agcIssueDate
								? moment(formData?.agcIssueDate).format('YYYY-MM-DD')
								: null
						}
					/>
				</div>
			)}

			{(item?.adsExpiryDate || item?.adsLeaseEndDate) && (
				<div className='flex flex-col w-full'>
					<div className='font-[500]'>
						{item?.adsLeaseEndDate ? 'Lease end Date' : 'Date of Expiry'}
					</div>
					<Input
						onBlur={submitData}
						onChange={(e) => onChange(e)}
						name='agcExpireDate'
						className='rounded-[8px] mt-2 h-[44px] w-full border border-black'
						type='date'
						min={dayjs(formData?.agcIssueDate).format('YYYY-MM-DD')}
						value={
							formData?.agcExpireDate
								? dayjs(formData?.agcExpireDate).format('YYYY-MM-DD')
								: null
						}
					/>
				</div>
			)}
		</div>
	);
};

export default CompanyRegistration;
