import { Button, Form, Input, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchClients, saveClient } from "../../features/client/clientSlice";

function BasicInfoView() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const { clients, clientData, viewBasic, editBasic, clientEditId } =
    useSelector((state) => state.client);
  const [clientDataList, setClientDataList] = useState(clientData);
  const [singleClient, setSingleClient] = useState("");
  const [receivedId, setReceivedId] = useState(
    clientEditId ? clientEditId : clients?.cntId
  );

  const [data, setdata] = useState({});
  const onFinish = async (data) => {};

  useEffect(() => {
    setReceivedId(clientEditId ? clientEditId : clients?.cntId);
  }, [clientEditId, clients?.cntId]);

  useEffect(() => {
    setClientDataList(clientData);
  }, [clientData]);

  useEffect(() => {
    const singleItem = clientDataList?.find(
      (item) => item?.cntId == receivedId
    );
    setSingleClient(singleItem);
  }, [receivedId, clientDataList]);

  useEffect(() => { 
  }, [singleClient]);

  useEffect(() => {
    dispatch(
      fetchClients({
        cntInstId: user?.usrInstId,
      })
    );
  }, []);

  return (
    <div className="mt-[38px]">
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-3 mb-[18px]">
          <div className="w-full">
            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose">
              Name
            </div>
          </div>
          <div className="col-span-2  w-full">
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose">
              {singleClient?.cntName}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 mb-[18px]">
          <div className="w-full">
            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose">
              Website
            </div>
          </div>
          <div className="col-span-2  w-full">
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose">
              {singleClient?.cntWebsite}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 mb-[18px]">
          <div className="w-full">
            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose">
            Economic activity
            </div>
          </div>
          <div className="col-span-2  w-full">
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose">
              {singleClient?.cntIndustry}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 mb-[18px]">
          <div className="w-full">
            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose">
              Description
            </div>
          </div>
          <div className="col-span-2  w-full">
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose">
              {singleClient?.cntDescription}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default BasicInfoView;
