import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dragger from "antd/es/upload/Dragger";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Button, Card, Form, Spin, message } from "antd";
import upload from "../../../../assets/image 145.png";
import { setApplicantJobViewTable } from "../../../../features/cv/cvSlice";
import ApplicantJobsTable from "./ApplicantJobsTable";
import { fetchJobApplications } from "../../../../features/applicantJob/applicantJobSlice";
import { fetchJobRecommendations } from "../../../../features/recommedations-referals/recommedationsSlice";

const url = process.env.REACT_APP_API_BASE_URL;
function Jobs() {
  const [data, setdata] = useState({});
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const { refetchKey } = useSelector((state) => state.cv);

  const { refetch } = useSelector((state) => state.applicantJob);
  const { referallData, referalLoading } = useSelector(
    (state) => state.recommedation
  );
  const { jobEditId } = useSelector((state) => state.jobs);

  const [applicantJobApplicationList, setApplicantJobApplication] =
    useState(referallData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fileUrl, setfileUrl] = useState("");
  const [fileName, setfileName] = useState("");
  const [fileLoading, setFileLoading] = useState(false);

  const props = {
    name: "file",
    multiple: false,
    accept: "image/*",
    action: `${url}/nea/saveFile.action`,
    onChange(info) {
      setFileLoading(true);
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        setFileLoading(false);
        message.success(`${info.file.name} file attached successfully.`);
        if (info?.file?.response?.success) {
          setfileUrl(info?.file?.response?.jsonData);
          setfileName(info?.file?.name);
        } else {
          setfileUrl("");
          setfileName("");
        }
        console.log(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setFileLoading(false);
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleAddJob = async () => {
    await dispatch(setApplicantJobViewTable(true));
    await navigate("/job-table");
  };
  const onFinish = async (values) => {};

  useEffect(() => {
    setApplicantJobApplication(referallData);
  }, [referallData]);

  const { applicantEditId, applicants, singleCreateApplicant } = useSelector(
    (state) => state.applicant
  );
  const [applicantsList, setapplicantsList] = useState(applicants);
  const [singleClient, setSingleClient] = useState("");
  const [receivedId, setReceivedId] = useState(
    applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
  );

  useEffect(() => {
    setapplicantsList(applicants);
  }, [applicants]);

  useEffect(() => {
    setReceivedId(
      applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
    );
  }, [applicantEditId, singleCreateApplicant?.usrId]);

  useEffect(() => {
    const singleItem = applicantsList?.find(
      (item) => item?.usrId == receivedId
    );
    setSingleClient(singleItem);
  }, [receivedId, applicantsList]);

  useEffect(() => {}, [singleClient]);

  useEffect(() => {
    if (refetchKey == 3 && !jobEditId) {
      dispatch(
        fetchJobRecommendations({
          jbrUsrId: applicantEditId
            ? applicantEditId
            : singleCreateApplicant?.usrId,
          jbrJobId: jobEditId ? jobEditId : "",
        })
      );
    }
  }, [
    jobEditId,
    receivedId,
    refetch,
    singleClient,
    applicantEditId,
    singleCreateApplicant,
    refetchKey,
  ]);
console.log("applicantJobApplicationList",applicantJobApplicationList)
console.log("jobEditId",jobEditId)
  return (
    <>
      {applicantJobApplicationList &&
      applicantJobApplicationList?.length > 0 ? (
        <ApplicantJobsTable />
      ) : (
        <div className="mr-2 h-[700px]  bg-white flex flex-col">
          <div className="text-black text-[22px] font-bold font-dmSans leading-[33px] px-[63px] py-[60px]">
            Job Details
          </div>

          <div className="flex flex-col text-center items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="121"
              height="107"
              viewBox="0 0 121 107"
              fill="none"
            >
              <path
                d="M77.0938 20.6618V7.33235C77.0938 5.51471 75.5791 4 73.7614 4H46.9511C45.1335 4 43.6188 5.51471 43.6188 7.33235V20.6618H77.0938Z"
                stroke="#00B6EA"
                stroke-width="8"
                stroke-miterlimit="10"
              />
              <path
                d="M118.303 101.697C118.303 104.574 116.031 106.847 113.153 106.847H7.72972C4.85178 106.847 2.57973 104.574 2.57973 101.697V26.4156C2.57973 23.5377 4.85178 21.2656 7.72972 21.2656H113.153C116.031 21.2656 118.303 23.5377 118.303 26.4156V101.697Z"
                fill="#5859A7"
              />
              <path
                d="M16.2121 100.636C13.3341 100.636 11.0621 98.3642 11.0621 95.4862V79.8848C11.0621 77.0068 13.3341 74.7347 16.2121 74.7347H118.303V26.4156C118.303 23.5377 116.031 21.2656 113.153 21.2656H7.72972C4.85178 21.2656 2.57973 23.5377 2.57973 26.4156V101.848C2.57973 104.726 4.85178 106.998 7.72972 106.998H113.153C116.031 106.998 118.303 104.726 118.303 101.848V100.636H16.2121Z"
                fill="#147CBC"
              />
              <path
                d="M120.722 65.1954C120.722 68.2248 118.299 70.6483 115.269 70.6483H5.45295C2.42354 70.6483 0 68.2248 0 65.1954V21.8748C0 18.8454 2.42354 16.4219 5.45295 16.4219H115.269C118.299 16.4219 120.722 18.8454 120.722 21.8748V65.1954Z"
                fill="#5859A7"
              />
              <path
                d="M119.056 60.6513C115.269 65.3469 110.422 65.3469 104.666 65.3469C82.4 65.3469 83.6118 65.1954 64.5265 65.3469C53.1662 65.3469 46.1986 65.3469 35.4442 60.8028C24.6897 56.2586 20.7515 46.1101 20.903 34.9013C20.903 28.691 22.1147 22.6322 24.6897 17.0278C24.8412 16.8763 24.8412 16.5733 24.9926 16.4219H5.45295C2.42354 16.4219 0 18.8454 0 21.8748V65.1954C0 68.2248 2.42354 70.6483 5.45295 70.6483H115.269C118.299 70.6483 120.722 68.2248 120.722 65.1954V58.2278C120.268 58.9851 119.662 59.8939 119.056 60.6513Z"
                fill="#147CBC"
              />
              <path
                d="M68.0103 68.6797H53.0147V81.2518H68.0103V68.6797Z"
                fill="#00B6EA"
              />
            </svg>

            <div className="mt-[40px] text-center text-[#9D9797] text-[22px] font-normal font-dmSans leading-[33px]">
              This candidate has not been added to a job yet.
              <br />
              Jobs the candidate has been added to will be displayed here.
            </div>

            {!jobEditId && (
              <button
                className="text-blue-50 text-[19px] font-medium font-dmSans leading-normal mt-[60px] 
w-[211px] h-14 px-7 py-4 bg-blueDark rounded-[56px] shadow justify-center items-center gap-2.5 inline-flex
relative"
                onClick={handleAddJob}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
                    fill="#EDF8FF"
                  />
                </svg>
                Add to Job
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Jobs;
