import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'; 
import checked from '../../../assets/checked.png';
import { formatImgPath } from '../../../utils';


export default function UserDesciption() {
	const {singleDistress} = useSelector((state)=>state.runaway)

	return (
		<>
			<div className='py-[1.2rem] px-[2.38rem]  bg-white pb-14 rounded-[0.5rem] flex justify-between items-start'>
				<div className='flex w-full items-center gap-[1.57rem]'>
					<div className='flex'>
						<img
							loading='lazy'
							src={
								singleDistress?.dstIcon
									? formatImgPath(singleDistress?.dstIcon)
									: 'https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png'
							}
							alt='profile'
							className='w-[9.6305rem] h-[9.704rem] rounded-full object-cover'
						/>
					</div>

					<div className='flex flex-col'>
						<div className='flex items-center'>
							<span className='prof-card-name w-full truncate'>
								{singleDistress?.dstFirstName} {singleDistress?.dstLastName}
							</span>

							<img
								loading='lazy'
								className='w-11 object-contain ml-1'
								src={checked}
								alt='Verified'
							/>
						</div>
						<span className='prof-reg'>CODE: {singleDistress?.dstCode}</span>
						<span className='prof-job  mt-5'>{singleDistress?.dstPhone}</span>
						<span className='prof-loc mt-1'>
							{singleDistress?.dstCountry ? singleDistress?.dstCountry + ', ' : ''}Kenya
						</span>
					</div>
				</div>

				 
			</div>

		
		</>
	);
}
