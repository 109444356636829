import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BranchesTable from "./branch_files/BranchesTable";
import InsideHeader from "../../../components/InsideHeader";
import { Breadcrumb } from "antd";

export default function Branches() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  

  function handleGoBack() {
    navigate(-1);
  }
 

  return (
    <>
      <InsideHeader
        title="List of Branches"
        subtitle="Manage all branches here"
        back={false}
      />

      <div className="mt-[2.5rem] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer "
                  onClick={() => navigate("/admin-landing")}
                >
                  Administrator
                </span>
              ),
            },
            {
              title: (
                <span className="text-blueDark font-dmSans">Branches list</span>
              ),
            },
          ]}
        />
      </div>
      <div className="mt-[1.35rem] bg-white px-10 min-h-[100%]">
        <BranchesTable />
      </div>
    </>
  );
}
