import React from 'react';
import MaterialIcon from 'material-icons-react';
import { previewPaper } from '../utils';
import { Button } from 'antd';

function FileButton({ titlePreview, show }) {
	const title = titlePreview?.split('_').pop();
	return (
		<>
			{title ? (
				<div
					className='cursor-pointer'
					onClick={() => previewPaper(titlePreview)}
				>
					<div className='flex'>
						{show ? <MaterialIcon icon='visibility' color='#147CBC' /> : null}

						<div className='text-bgDark underline ml-1'>{title}</div>
					</div>
				</div>
			) : (
				<span className='underline'>No file</span>
			)}
		</>
	);
}

export default FileButton;
