import React, { useRef, useState } from "react";
import { Breadcrumb, Form, Input, Select, Spin } from "antd";
import "../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import {
  cleanupSingleActivity,
  fetchJobActivitys,
  fetchSingleJobActivity,
  saveJobActivity,
} from "../../features/activities/activitySlice";
import { fetchJobs } from "../../features/jobs/jobSlice";
import { useEffect } from "react";
import { activityData } from "../../Gender";
import InsideHeader from "../../components/InsideHeader";

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};

var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

const ActivityAdd = () => {
  async function cleanActivityId() {
    await dispatch(cleanupSingleActivity());
  }

  const handleCancel = async () => {
    await navigate("/activity-list");
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  const {
    singleActivity,
    activityLoading,
    activityEditId,
    singleActivityData,
  } = useSelector((state) => state.activity);
  const { jobData } = useSelector((state) => state.jobs);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setdata] = useState({});

  const [receivedId, setreceivedId] = useState(
    Object.keys(singleActivity)?.length > 0
      ? singleActivity?.jaaId
      : activityEditId?.jaaId
  );

  const [formData, setFormData] = useState(receivedId ? singleActivityData : "");

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  function onChangeDesc(value) {
    setFormData((prevData) => ({
      ...prevData,
      jaaDesc: value,
    }));
  }

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  

  const onFinish = async (data) => {
    const res = await dispatch(
      saveJobActivity({
        jaaId: receivedId ?? null,
        jaaTitle: formData?.jaaTitle,
        jaaType: formData?.jaaType,
        jaaJobId: formData?.jaaJobId,
        jaaDesc: formData?.jaaDesc,
        jaaInstId: user?.usrInstId,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      navigate("/activity-step2");
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  async function fetchJobsDetails() {
    const res = await dispatch(
      fetchJobs({
        jobInstId: user?.usrInstId,
        jobCntId: "",
      })
    );
  }
 
  async function fetchSingleActivity() {
    const res = await await dispatch(
      fetchSingleJobActivity({
        jaaId: receivedId,
      })
    );
  }

  useEffect(() => {
    fetchJobsDetails();
  }, []);

  useEffect(() => {
    setFormData(receivedId ? singleActivityData : "");
  }, [singleActivityData,receivedId]);

  useEffect(() => {
    setreceivedId(
      Object.keys(singleActivity)?.length > 0
        ? singleActivity?.jaaId
        : activityEditId?.jaaId
    );
  }, [singleActivity,activityEditId]);

  useEffect(() => {
      fetchSingleActivity();
  }, []);

 

  return (
    <>
      <InsideHeader
        title="Activities"
        subtitle="Manage your activities such as meetings and interviews"
        back={false}
      />
      <div className="mt-[40px] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer "
                  onClick={handleCancel}
                >
                  Activities
                </span>
              ),
            },
            {
              title: (
                <span className="text-blueDark font-dmSans">Add activity</span>
              ),
            },
          ]}
        />
      </div>

      <div className="flex justify-center items-center">
        <div className="border border-solid border-[#E0E0E0] mt-[40px] h-auto w-[1020px] px-[100px] py-[60px]">
          <div className="text-black2 text-[24px] font-bold dash-title leading-[32.4px]">
            Activity Details
          </div>
          <div className="w-[308px] mt-[28px] mb-[40px] text-black2 text-[20px] font-medium  leading-[24px]">
            Please fill in the following details.
          </div>

          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            className=""
            style={{
              maxWidth: "100%",
            }}
            form={form}
          >
            <Form.Item
              label="Choose activity"
              extra={"Choose option"}
              rules={[
                {
                  required: true,
                  message: "Please select activity",
                },
              ]}
            >
              <Select
                required
                name="jaaType"
                value={formData?.jaaType}
                className="activity-select-tag activity-select-icon"
                allowClear
                style={{
                  width: "100%",
                  height: "3.25rem",
                }}
                onChange={(value) => {
                  handleSelectChange(value, "jaaType");
                }}
                options={
                  activityData?.length > 0 &&
                  activityData?.map((item) => ({
                    value: item?.value,
                    label: item?.label,
                  }))
                }
                onDropdownVisibleChange={() => {
                  // fetchUsersDetails();
                }}
              />
            </Form.Item>

            <Form.Item
              label="Select Job"
              rules={[
                {
                  required: true,
                  message: "Please select job",
                },
              ]}
              extra={"Choose option"}
            >
              <Select
                required
                name="jaaJobId"
                value={formData?.jaaJobId}
                placeholder="Select a job"
                style={{
                  width: "100%",
                  height: "3.25rem",
                }}
                options={
                  jobData?.length > 0 &&
                  jobData?.map((item) => ({
                    value: item?.jobId,
                    label: item?.jobName,
                  }))
                }
                onChange={(value) => {
                  handleSelectChange(value, "jaaJobId");
                }}
                onDropdownVisibleChange={() => {
                  fetchJobsDetails();
                }}
              />
            </Form.Item>

            <Form.Item
              extra={"Enter activity name, e.g. Sales Interview."}
              label="Name of activity"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please add activity name",
                },
              ]}
            >
              <Input
                required
                name="jaaTitle"
                value={formData?.jaaTitle}
                onChange={onChange}
                className="input"
              />
            </Form.Item>

            <Form.Item
              extra={
                "Briefly describe the activity e.g. What to bring in the interview"
              }
              className="w-full"
              label="Description"
              rules={[
                {
                  required: false,
                  message: "Please add name",
                },
              ]}
            >
              <ReactQuill
                required
                name="jaaDesc"
                value={formData?.jaaDesc}
                onChange={(value) => {
                  onChangeDesc(value);
                }}
                theme="snow"
                modules={modules}
                formats={formats}
                placeholder="Add description"
                style={{ height: "300px", marginBottom: "5%" }}
              />
            </Form.Item>

            <div className="justify-center items-center flex flex-col gap-y-2">
              <button
                key="submit"
                type="submit"
                className="w-[300px] h-[50px] px-2 py-3 bg-blueDark rounded-[28px] justify-center items-center text-white text-[18px]
                font-dmSans"
                disabled={activityLoading}
              >
                {activityLoading ? <Spin /> : "Next"}
              </button>
              <button
                onClick={handleCancel}
                className="w-[300px] h-[50px] px-2 py-3 justify-center items-center rounded-[28px] border border-solid border-blueDark flex 
                !text-blueDark text-[18px] font-dmSans bg-white"
              >
                Cancel
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default ActivityAdd;
