import { Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calculateYears, dateForHumans } from "../../../utils";
 

function ContractDetailsView() {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const {singlePlacement } =  useSelector((state) => state.employee);

   
  return (
    <>
      <div className="mt-[38px]">
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="grid grid-cols-2">
            <div className="grid grid-cols-2">
              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Date of departure from Kenya
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {dateForHumans(singlePlacement?.emplDepartureDate) ?? "N/A"}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Country of Work
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singlePlacement?.employerCountry ?? "N/A"}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Contract Commencement
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {dateForHumans(singlePlacement?.japValidityStartDate) ?? "N/A"}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Contract Expiry Date
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singlePlacement?.japValidityEndDate ?? "N/A"}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default ContractDetailsView;
