import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, Divider, Form, Input, Select, Space, Spin } from "antd";

import qs from "qs";
import {
  deleteSkill,
  fetchGlobalSkills,
  fetchSkillCategory,
  fetchSkills,
  saveSkill,
} from "../../../../features/skills/skillSlice";
import {
  fetchJobs,
  setEditSkills,
  setViewSkills,
} from "../../../../features/jobs/jobSlice";

const url = process.env.REACT_APP_API_BASE_URL;

let timeout;
let currentValue;
const fetchFunc = (value, callback) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = () => {
    fetch(`${url}/nea/fetchGlobalSkills.action?gsklName=${value}`)
      .then((response) => response.json())
      .then((d) => {
        if (currentValue === value) {
          const data = d?.data?.result?.map((item) => ({
            value: item?.gsklId,
            text: item?.gsklName,
          }));
          callback(data);
        }
      });
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};

export default function SkillStep() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const { mySkills, skillLoading, globalSkills } = useSelector(
    (state) => state.skill
  );
  const { singleCreateJob, jobEditId, jobData, viewSkills, editSkills } =
    useSelector((state) => state.jobs);
  const { refetch, refetchKey } = useSelector((state) => state.jobs);

  const [data, setdata] = useState({});
  const [showSearch, setshowSearch] = useState(true);

  const [skillData, setskillData] = useState([]);
  const [value, setValue] = useState();
  const [skillsList, setskillsList] = useState(mySkills);
  const [skillCategoryList, setskillCategoryList] = useState([]);

  const usrObj = {
    usrId: singleCreateJob?.jobId ? singleCreateJob?.jobId : jobEditId,
    usrType: "JOB",
  };

  const handleSearch = (newValue) => {
    fetchFunc(newValue, setskillData);
  };
  const handleSkillChange = async (newValue) => {
    await setValue(newValue);
    let newArr = await [...newValue];
    let val = await newArr?.pop();
    const skillObj = {
      sklUsrId: singleCreateJob?.jobId ? singleCreateJob?.jobId : jobEditId,
      sklGsklId: val,
      sklType: "JOB",
    };

    await dispatch(saveSkill(skillObj));
    const res = await dispatch(fetchSkills(usrObj));
    if (res?.payload?.success) {
    }
  };

  const handleChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const [globalSkillsList, setglobalSkillsList] = useState(globalSkills);
  // async function handleSelectChange(value) {
  //   const resp = await dispatch(fetchGlobalSkills(value));

  //   let skills = [];

  //   if (resp?.payload?.success) {
  //     await resp?.payload?.data?.result?.forEach((item) => {
  //       skills.push({
  //         label: item?.gsklName,
  //         value: item?.gsklId,
  //       });
  //     });

  //     await setglobalSkillsList(skills);
  //   }
  // }

  async function handleSelectChange(values, options) {
    await setInitialSelectedAccounts(values);
    const selectedSkills = options.map((option) => ({
      label: option?.children,
      value: option?.value,
    }));
    await setglobalSkillsList(selectedSkills);
    handleSkillChange(values);
  }

  const onFinish = async (data) => {};

  async function handleFetchSkills() {
    const resp = await dispatch(fetchSkills(usrObj));

    if (resp?.payload?.success) {
      await setskillsList(resp?.payload?.data?.result);
    }
  }

  async function handleDeleteSkill(id) {
    await dispatch(deleteSkill(id));
    await handleFetchSkills();
  }

  async function handleFetchSKillCategory() {
    const res = await dispatch(fetchSkillCategory());
    if (res?.payload?.success) {
      let skills = [];

      await res?.payload?.data?.result?.forEach((item) => {
        skills.push({
          label: item?.sklcatName,
          value: item?.sklcatId,
        });
      });

      await setskillCategoryList(skills);
    }
  }
  async function handleFetchGlobalSkills() {
    const resp = await dispatch(fetchGlobalSkills());
  }

  useEffect(() => {}, [skillsList, globalSkillsList]);

  useEffect(() => {
    setskillsList(mySkills);
  }, [mySkills]);

  useEffect(() => {
    setglobalSkillsList(globalSkills);
  }, [globalSkills]);

  useEffect(() => {
    if (refetchKey == 1) {
      handleFetchSkills();
    }
  }, [refetch, refetchKey]);

  useEffect(() => {
    handleFetchSkills();
    handleFetchGlobalSkills();
  }, []);

  const [jobDataList, setJobDataList] = useState(jobData);
  const [receivedId, setReceivedId] = useState(
    jobEditId ? jobEditId : singleCreateJob?.jobId
  );
  const [singleJob, setSingleJob] = useState("");

  const handleViewSkill = () => {
    dispatch(setViewSkills(true));
    dispatch(setEditSkills(false));
  };

  useEffect(() => {
    setReceivedId(jobEditId ? jobEditId : singleCreateJob?.jobId);
  }, [jobEditId, singleCreateJob?.jobId]);

  useEffect(() => {
    const singleItem = jobDataList?.find((item) => item?.jobId == receivedId);
    setSingleJob(singleItem);
  }, [receivedId, jobDataList]);

  useEffect(() => {
    setJobDataList(jobData);
  }, [jobData]);

  useEffect(() => {}, [singleJob]);

  useEffect(() => {
    dispatch(
      fetchJobs({
        jobInstId: user?.usrInstId,
        jobCntId: "",
      })
    );
  }, []);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value) => {};

  const [initialSelectedAccounts, setInitialSelectedAccounts] = useState([]);

  useEffect(() => {
    if (skillsList?.length > 0) {
      const skillIds = skillsList?.map((skill) => skill?.skillName);
      setInitialSelectedAccounts(skillIds);
      form.setFieldsValue({ gsklId: skillIds });
    } else {
      setInitialSelectedAccounts([]);
      form.resetFields(["gsklId"]);
    }
  }, [form]);

  return (
    <>
      <div className="mt-10">
        <h3 className="edit-step-head">Skills</h3>
        <p className="edit-step-desc mt-2">
          Tell us a little more about your skills
        </p>

        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          style={{
            maxWidth: "100%",
            marginTop: 20,
          }}
          form={form}
        >
          {!viewSkills && (
            <>
              <div className=" py-1 bg-transparent flex items-center justify-between max-w-full min-w-[30%] mb-10">
                <div className="flex items-center w-full">
                  {/* {showSearch ? (
                    <MaterialIcon color="#333" size={25} icon="search" />
                  ) : null} */}

                  <Form.Item
                    extra={"You can choose more than one skill"}
                    label=""
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                    className="w-full"
                  >
                    <Select
                      // value={initialSelectedAccounts}
                      name="gsklId"
                      onChange={(values, options) =>
                        handleSelectChange(values, options)
                      }
                      mode="multiple"
                      allowClear
                      style={{
                        minHeight: "52px",
                      }}
                      placeholder={"Select skills"}
                      size="large"
                      className=""
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.children?.toLowerCase() ?? "").includes(
                          input.toLowerCase()
                        )
                      }
                      onDropdownVisibleChange={(open) => {
                        if (open) {
                          handleFetchGlobalSkills();
                        }
                      }}
                      dropdownRender={(menu) => (
                        <>
                          <Space
                            style={{
                              padding: "8px 8px 4px",
                            }}
                          >
                            <span className="select-info mt-1">
                              Choose one or more of the following
                            </span>
                          </Space>
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          {menu}
                        </>
                      )}
                      menuItemSelectedIcon={({ isSelected }) => (
                        <>
                          {isSelected ? (
                            <Checkbox checked={true}></Checkbox>
                          ) : (
                            <Checkbox checked={false}></Checkbox>
                          )}
                        </>
                      )}
                    >
                      {globalSkills?.map((option) => (
                        <Select.Option
                          key={option?.gsklId}
                          value={option?.gsklId}
                        >
                          {option?.gsklName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {/* <Select
                    id="search-select"
                    mode="multiple"
                    style={{
                      width: "100%",
                      height: "44px",
                      maxHeight: "100% !important",
                      border: "none !important",
                      outline: "none",
                    }}
                    value={value}
                    className="!placeholder-black333 max-h-auto h-[44px] w-full focus:border-none focus:outline-none focus:ring-0 active:ring-0 search-select"
                    placeholder={"Search Skills"}
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    filterOption={false}
                    onSearch={handleSearch}
                    onChange={handleSkillChange}
                    notFoundContent={null}
                    options={(skillData || [])?.map((d) => ({
                      value: d.value,
                      label: d.text,
                    }))}
                    onFocus={() => setshowSearch(!showSearch)}
                    onBlur={() => {
                      setshowSearch(true);
                      setskillData([]);
                      setValue();
                    }}
                  /> */}
                </div>
              </div>
            </>
          )}

          <div>
            <p className="mb-5 mt-7 font-medium text-[15px]">Chosen skills:</p>
            <div className="flex items-center flex-wrap mt-2">
              {skillsList?.map((item) => {
                return (
                  <>
                    <div className="bg-lightPurple rounded-[63px] text-center px-4 py-1 mr-2 flex items-center justify-between mb-3">
                      <span className="prof-card-badge">{item?.skillName}</span>
                      {!viewSkills && (
                        <button
                          onClick={() => handleDeleteSkill(item?.sklId)}
                          type="button"
                          className="ml-3"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                          >
                            <path
                              d="M14.25 4.75L4.75 14.25"
                              stroke="#262168"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.75 4.75L14.25 14.25"
                              stroke="#262168"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          {!viewSkills && (
            <div className="flex items-center justify-between mt-[35px]">
              <div className="flex items-start"></div>
              <div className="flex gap-5 items-center">
                {editSkills ? (
                  <button
                    className="w-[168px] cancel-btn"
                    onClick={handleViewSkill}
                  >
                    Cancel
                  </button>
                ) : null}

                <button
                  className=" w-[168px] save-btn"
                  type="submit"
                  onClick={handleViewSkill}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </Form>
      </div>
    </>
  );
}
