import React, { useEffect, useState } from 'react';
import jsonp from 'fetch-jsonp';
import qs from 'qs';
import { Select } from 'antd';
import { setMemberEmail, setMemberName, setMemberPhone, setMemberRole, setMemberText } from '../../features/panel/panelSlice';
import { useDispatch, useSelector } from 'react-redux';
let timeout;
let currentValue;
 
const url = process.env.REACT_APP_API_BASE_URL;
const fetchFunc = (value, callback) => { 
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = () => {
    fetch(`${url}/emp/fetchPanelMembers.action?pmeName=${value}`)
      .then((response) => response.json())
      .then((d) => {
        if (currentValue === value) {
          const data = d?.data?.result?.map((item) => ({
            value: item?.pmeId,
            text: item?.pmeName,
            email: item?.pmeEmail,
            phone: item?.pmePhone,
            role: item?.pmeRole
          }));
          callback(data); 
        };
       
      });
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  }  
};

const SearchInput = (props) => {  
  const { memberName } = useSelector((state) => state.panel);

  const [data, setData] = useState([]);
  // const [value, setValue] = useState(props?.formData ? props?.formData : memberName);  
  const [value, setValue] = useState(props?.formData || memberName || '');

  console.log("memberName",memberName)
  console.log("value",value)

  const dispatch = useDispatch();
 
  const handleSearch = (newValue) => {
    fetchFunc(newValue, (result) => {
      setData(result); 
      if (result.length === 0) {  
        setValue(newValue); 
        dispatch(setMemberName(newValue));
      }
    });
  };
 
  const handleChange = (newValue) => {   
    setValue(newValue);
    dispatch(setMemberName(newValue)); 
    if (data) {
      dispatch(setMemberText(data[0].text));
      dispatch(setMemberEmail(data[0].email));
      dispatch(setMemberPhone(data[0].phone));
      dispatch(setMemberRole(data[0].role));  
  
    }
  };

  useEffect(()=>{
    setValue(props?.formData || memberName || '')
  },[props?.formData || memberName || ''])
 
  return (
    <Select
      showSearch
      value={value} 
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange} 
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
  );
};
const SearchField = ({formData}) => (
  <SearchInput
    placeholder="input search text"
    style={{
      width: '100%',
      height:'44px',
      borderRadius:"8px"
    }}
    required
    name="pmeName"  
    formData={formData}
  />
);
export default SearchField;