import { Button, Input, Upload, message, Form, Select, Checkbox } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import toast from 'react-hot-toast';
import AttachImage from '../../../../assets/uploadPic.png';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchDirectorAndManager,
	saveDirectorAndManager,
	saveFile,
} from '../../../../features/application/applicationSlice';
import {
	formatImgPath,
	showToastError,
	showToastSuccess,
} from '../../../../utils';
import { toFormData } from 'axios';
import Dragger from 'antd/es/upload/Dragger';
import FileUploader from '../../../../components/FileUploader';
import {
	fetchAwards,
	fetchCourses,
	fetchInstitutionTypes,
	fetchStudyAreas,
	globalCleanUp,
	fetchProfessionalBodies,
	fetchProfessionalBodySpecializations,
} from '../../../../features/global/globalSlice';
import moment from 'moment';
import dayjs from 'dayjs';

const AddDirector = ({ handleCancelAdd, editItem, handleLoading }) => {
	const { editId } = useSelector((state) => state.application);
	const { user } = useSelector((state) => state.auth);
	const {
		studyAreas,
		globalLoading,
		courses,
		awards,
		instTypes,
		profBodies,
		profSpecializations,
	} = useSelector((state) => state.global);

	const formRef = useRef(null);

	const url = process.env.REACT_APP_API_BASE_URL;

	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [form] = Form.useForm();
	const values = Form.useWatch([], form);

	const [fileName, setFileName] = useState('');
	const [profile, setProfile] = useState(null);
	const [formData, setFormData] = useState({
		secInstId: user?.usrInstId,
		secType: 'DIRECTOR',
	});
	const [isMember, setisMember] = useState(
		editItem?.secBodyName ? true : false
	);

	const editableForm = () => {
		if (Object.keys(editItem).length > 0) {
			setProfile(formatImgPath(editItem.secIcon ?? ''));
			setFormData({
				...editItem,
				secId: editItem?.secId,
				file: editItem?.secIdFile?.split('_').pop(),
				file2: editItem?.secGoodConduct?.split('_').pop(),
				file3: editItem?.secEduCert?.split('_').pop(),
				file4: editItem?.secProfCertFile?.split('_').pop(),
				file5: editItem?.secBodyFile?.split('_').pop(),
			});
		}
	};

	function onBodyChange(val) {
		setisMember(val);
	}

	const onSubmit = async () => {
		setIsLoading(true);
		await handleLoading(true);
		const res = await dispatch(saveDirectorAndManager(formData));
		await dispatch(fetchDirectorAndManager(user?.usrInstId));
		if (res?.payload?.success) {
			message.info('Director details saved');
			handleCancelAdd();
			setIsLoading(false);
			await handleLoading(false);
		} else {
			message.error(res?.payload?.messages?.message);
			setIsLoading(false);
			await handleLoading(false);
		}
	};

	const handleSuccess = (response, name) => {
		// Handle success logic
		setFormData((prev) => ({
			...prev,
			[response.fileName]: response.jsonData,
		}));
	};

	const handleError = (response) => {
		// Handle error logic
		console.error('File upload failed:', response);
	};

	function handleInstypeChange(val) {
		setFormData((prev) => ({
			...prev,
			secInstType: val,
		}));
	}

	function handleStudyAreaChange(val) {
		setFormData((prev) => ({
			...prev,
			secAreaOfStudy: val,
		}));
	}

	function handleAwardChange(val) {
		let awdObj = awards.find((item) => item?.awdId === val);
		setFormData((prev) => ({
			...prev,
			secAward: awdObj?.awdName,
		}));
	}

	function handleCourseChange(val) {
		setFormData((prev) => ({
			...prev,
			secCourse: val,
		}));
	}

	function handleBodySpecializationChange(val) {
		setFormData((prev) => ({
			...prev,
			secBodySpecialization: val,
		}));
	}

	const onChange = async (e) => {
		if (
			e.target.name === 'secGoodConduct' ||
			e.target.name === 'secEduCert' ||
			e.target.name === 'secIdFile' ||
			e.target.name === 'secProfCertFile' ||
			e.target.name === 'secBodyFile' ||
			e.target.name === 'secIcon'
		) {
			const formData = new FormData();
			formData.append('file', e.target.files[0]);
			const res = await dispatch(saveFile(formData));

			setFormData((prev) => ({
				...prev,
				[e.target.name]: res.payload.jsonData,
			}));
			if (e.target.name === 'secIcon') {
				setProfile(formatImgPath(res.payload.jsonData));
			}
		} else {
			setFormData((prev) => ({
				...prev,
				[e.target.name]: e.target.value,
			}));
		}
	};

	async function handleDropDownData() {
		await dispatch(fetchStudyAreas());
		await dispatch(fetchAwards());
		await dispatch(fetchInstitutionTypes());
		await dispatch(fetchProfessionalBodies());
	}

	async function handleFetchCourses(item) {
		await dispatch(fetchCourses(item));
	}

	async function handleBodyChange(item) {
		let bodyObj = profBodies?.find(
			(obj) => String(obj?.prbName) === String(item)
		);
		setFormData((prev) => ({
			...prev,
			secBodyName: item,
		}));

		await dispatch(fetchProfessionalBodySpecializations(bodyObj?.prbId));
	}

	async function handlCleanUp() {
		await dispatch(globalCleanUp());
	}

	useEffect(() => {
		handleDropDownData();
		handlCleanUp();
	}, []);

	useEffect(() => {
		editableForm();
	}, [editId]);

	useEffect(() => {}, [
		studyAreas,
		courses,
		awards,
		profBodies,
		profSpecializations,
		formData,
	]);

	return (
		<Form
			onFinish={onSubmit}
			disabled={isLoading}
			form={form}
			ref={formRef}
			className='flex flex-col w-[100%]'>
			<div className='flex flex-row justify-between my-2'>
				<div>Please fill in the details below</div>
			</div>
			{profile === null ? (
				<>
					<label className='flex flex-row items-center justify-start w-[30%]'>
						<input
							name='secIcon'
							type='file'
							accept='image/*'
							style={{ display: 'none' }}
							onChange={(e) => onChange(e)}
						/>
						<img
							loading='lazy'
							className='z-10 w-[100px] h-[100px] rounded-full'
							alt='tab1'
							src={AttachImage}
							style={{ cursor: 'pointer' }}
						/>
            <div className='text-blueDark cursor-pointer w-full ml-3'>
							Upload passport photo
						</div>
					</label>
				</>
			) : (
				<label className='flex flex-row items-center justify-start gap-2  w-[30%]'>
					<input
						name='secIcon'
						type='file'
						accept='image/*'
						style={{ display: 'none' }}
						onChange={onChange}
					/>
					<div className='rounded-full  overflow-hidden border-4 border-blueDark'>
						<img
							src={profile}
							alt='director'
							className='w-28 h-28 cursor-pointer object-cover'
						/>
					</div>
				</label>
			)}
			<div>
				<div className='grid grid-cols-3 gap-x-10 my-7'>
					<Form.Item className='flex flex-col w-[100%]'>
						<div>First name</div>
						<Input
							required
							value={formData.secFirstName}
							onChange={(e) => onChange(e)}
							name='secFirstName'
							className='rounded-[8px] mt-1 h-[44px]  w-full border border-black'
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div>Middle name</div>
						<Input
							required
							value={formData.secMiddleName}
							onChange={(e) => onChange(e)}
							name='secMiddleName'
							className='rounded-[8px] mt-1 h-[44px]  w-full border border-black'
						/>
					</Form.Item>
					<Form.Item className='flex flex-col w-[100%]'>
						<div>Last name</div>
						<Input
							required
							value={formData.secLastName}
							onChange={(e) => onChange(e)}
							name='secLastName'
							className='rounded-[8px] mt-1 h-[44px]  w-full border border-black'
						/>
					</Form.Item>
					<Form.Item className='flex flex-col w-[100%]'>
						<div>Email</div>
						<Input
							required
							value={formData.secEmail}
							onChange={(e) => onChange(e)}
							name='secEmail'
							className='input'
						/>
					</Form.Item>
					<Form.Item className='flex flex-col w-[100%]'>
						<div>Phone No.</div>
						<Input
							required
							value={formData.secMobileNumber}
							onChange={(e) => onChange(e)}
							name='secMobileNumber'
							className='input'
						/>
					</Form.Item>

					<Form.Item className='flex flex-col'>
						<div>ID No.</div>
						<Input
							required
							value={formData.secNationalId}
							onChange={(e) => onChange(e)}
							name='secNationalId'
							className='rounded-[8px] mt-2 h-[44px] border border-black'
						/>
					</Form.Item>
					<Form.Item className='flex flex-col'>
						<div className='mb-2'>Upload ID</div>
						<FileUploader
							fileName={formData?.file}
							setFileName={setFileName}
							name='secIdFile'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</Form.Item>

					<Form.Item className='flex flex-col'>
						<div className='mb-2'>Good Conduct Certificate</div>
						<FileUploader
							fileName={formData?.file2}
							setFileName={setFileName}
							name='secGoodConduct'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</Form.Item>
				</div>

				<h3 className='appl-head mb-3'>Director Academic Details</h3>

				<div className='grid grid-cols-3 gap-x-10 mb-7 '>
					<Form.Item className='flex flex-col w-[100%]'>
						<div>Institution Type</div>
						<Select
							showSearch
							loading={globalLoading}
							style={{
								width: '100%',
								height: '44px',
							}}
							value={formData?.secInstType}
							className='mt-2'
							placeholder='Search to Select'
							optionFilterProp='children'
							filterOption={(input, option) =>
								(option?.label?.toLocaleLowerCase() ?? '').includes(
									input?.toLocaleLowerCase()
								)
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
							}
							onChange={handleInstypeChange}
							options={
								instTypes?.length > 0 &&
								instTypes?.map((item) => ({
									value: item.instpName,
									label: item.instpName,
								}))
							}
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div>Institution Name</div>
						<Input
							required
							value={formData.secInstName}
							onChange={(e) => onChange(e)}
							name='secInstName'
							className='input'
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div>Area of Study</div>
						<Select
							showSearch
							loading={globalLoading}
							value={formData?.secAreaOfStudy}
							style={{
								width: '100%',
								height: '44px',
							}}
							className='mt-2'
							placeholder='Search to Select'
							optionFilterProp='children'
							filterOption={(input, option) =>
								(option?.label?.toLocaleLowerCase() ?? '').includes(
									input?.toLocaleLowerCase()
								)
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
							}
							onChange={handleStudyAreaChange}
							options={
								studyAreas?.length > 0 &&
								studyAreas?.map((item) => ({
									value: item.stdyName,
									label: item.stdyName,
								}))
							}
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div>Award</div>
						<Select
							showSearch
							value={formData?.secAward}
							loading={globalLoading}
							style={{
								width: '100%',
								height: '44px',
							}}
							className='mt-2'
							placeholder='Search to Select'
							optionFilterProp='children'
							filterOption={(input, option) =>
								(option?.label?.toLocaleLowerCase() ?? '').includes(
									input?.toLocaleLowerCase()
								)
							}
							onChange={(val) => {
								handleFetchCourses(val);
								handleAwardChange(val);
							}}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
							}
							options={
								awards?.length > 0 &&
								awards?.map((item) => ({
									value: item.awdId,
									label: item.awdName,
								}))
							}
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div>Course</div>
						<Input
							required
							value={formData.secCourse}
							onChange={(e) => onChange(e)}
							name='secCourse'
							className='input'
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div>Education Cert No</div>
						<Input
							value={formData.secEduCertNo}
							onChange={(e) => onChange(e)}
							name='secEduCertNo'
							className='input'
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div>Start Date</div>
						<input
							required
							value={
								formData?.secStartDate
									? moment(formData?.secStartDate).format('YYYY-MM-DD')
									: null
							}
							onChange={(e) => onChange(e)}
							name='secStartDate'
							type='date'
							max={dayjs(new Date()).format("YYYY-MM-DD")}
							className='input'
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div>Graduation Date</div>
						<Input
							required
							value={
								formData?.secGradDate
									? moment(formData?.secGradDate).format('YYYY-MM-DD')
									: null
							}
							onChange={(e) => onChange(e)}
							name='secGradDate'
							type='date'
							min={dayjs(formData?.secStartDate ?? new Date()).format("YYYY-MM-DD")}
							className='input'
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div className='mb-2'>Upload Education Certificate</div>
						<FileUploader
							fileName={formData?.file3}
							setFileName={setFileName}
							name='secEduCert'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</Form.Item>
				</div>

				<h3 className='appl-head mb-3'>Director Professional Qualification</h3>

				<div className='grid grid-cols-3 gap-x-10 mb-7 '>
					<Form.Item className='flex flex-col w-[100%]'>
						<div>Institution Name</div>
						<Input
							required
							value={formData.secProfInstName}
							onChange={(e) => onChange(e)}
							name='secProfInstName'
							className='input'
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div>Course</div>
						<Input
							required
							value={formData.secProfCourse}
							onChange={(e) => onChange(e)}
							name='secProfCourse'
							className='input'
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div>Start Date</div>
						<Input
							required
							value={
								formData?.secProfStartDate
									? moment(formData?.secProfStartDate).format('YYYY-MM-DD')
									: null
							}
							onChange={(e) => onChange(e)}
							name='secProfStartDate'
							type='date'
							max={dayjs(new Date()).format("YYYY-MM-DD")}
							className='input'
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div>End Date</div>
						<Input
							required
							value={
								formData?.secProfGradDate
									? moment(formData?.secProfGradDate).format('YYYY-MM-DD')
									: null
							}
							onChange={(e) => onChange(e)}
							name='secProfGradDate'
							type='date'
							min={dayjs(formData?.secProfStartDate ?? new Date()).format("YYYY-MM-DD")}
							className='input'
						/>
					</Form.Item>

					<Form.Item className='flex flex-col w-[100%]'>
						<div className='mb-2'>Upload Certificate</div>
						<FileUploader
							fileName={formData?.file4}
							setFileName={setFileName}
							name='secProfCertFile'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</Form.Item>
				</div>
			</div>

			<Checkbox
				className='mb-3'
				checked={isMember}
				onChange={(e) => onBodyChange(e.target.checked)}>
				<h3 className='appl-head mb-3'>
					Is the director a member of a professional body
				</h3>
			</Checkbox>

			{isMember ? (
				<>
					<div className='grid grid-cols-2 gap-x-10 mb-7 '>
						<Form.Item className='flex flex-col w-[100%]'>
							<div>Professional Body</div>
							<Select
								showSearch
								loading={globalLoading}
								style={{
									width: '100%',
									height: '44px',
								}}
								value={formData?.secBodyName}
								className='mt-2'
								placeholder='Search to Select'
								optionFilterProp='children'
								filterOption={(input, option) =>
									(option?.label?.toLocaleLowerCase() ?? '').includes(
										input?.toLocaleLowerCase()
									)
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								onChange={handleBodyChange}
								options={
									profBodies?.length > 0 &&
									profBodies?.map((item) => ({
										value: item.prbName,
										label: item.prbName,
									}))
								}
							/>
						</Form.Item>
{/* 
						<Form.Item className='flex flex-col w-[100%]'>
							<div>Specialization</div>
							<Select
								showSearch
								disabled={profSpecializations?.length === 0 || globalLoading}
								loading={globalLoading}
								style={{
									width: '100%',
									height: '44px',
								}}
								value={formData?.secBodySpecialization}
								className='mt-2'
								placeholder='Search to Select'
								optionFilterProp='children'
								filterOption={(input, option) =>
									(option?.label?.toLocaleLowerCase() ?? '').includes(
										input?.toLocaleLowerCase()
									)
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								onChange={handleBodySpecializationChange}
								options={
									profSpecializations?.length > 0 &&
									profSpecializations?.map((item) => ({
										value: item.pbsName,
										label: item.pbsName,
									}))
								}
							/>
						</Form.Item> */}

						<Form.Item className='flex flex-col'>
							<div>Specialization</div>
							<Input
								required
								value={formData.secBodyCourse}
								onChange={(e) => onChange(e)}
								name='secBodyCourse'
								className='rounded-[8px] mt-2 h-[44px] border border-black'
							/>
						</Form.Item>

						<Form.Item className='flex flex-col w-[100%]'>
							<div className='mb-2'>Upload Certificate</div>
							<FileUploader
								fileName={formData?.file5}
								setFileName={setFileName}
								name='secBodyFile'
								url={url}
								onSuccess={handleSuccess}
								onError={handleError}
							/>
						</Form.Item>
					</div>
				</>
			) : null}

			<div className='flex flex-col gap-4 my-10 w-[100%] items-center'>
				<button
					type='submit'
					// onClick={onSubmit}
					className=' bg-blueDark rounded-[35px] text-white w-[200px] p-3 ml-4'>
					Save
				</button>
				<Button
					onClick={() => handleCancelAdd()}
					className='flex justify-center items-center border-2 border-blueDark rounded-[35px] text-blueDark font-medium w-[200px] p-5 ml-4'>
					Cancel
				</Button>
			</div>
		</Form>
	);
};

export default AddDirector;
