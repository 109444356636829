import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleLicenseStep } from '../../../../features/license/licenseSlice';

export default function StepThree() {
	const dispatch = useDispatch();

	const { agencyObj } = useSelector((state) => state.agency);

	useEffect(() => {}, [agencyObj]);

	return (
		<>
			{agencyObj?.instStatus === 'REJECT_RECEIPT' ? (
				<>
					<div className='my-10 flex justify-between items-center w-full'>
						<span className='font-bold text-[36px] text-[#141414]'>
							Payment Not Verified
						</span>
					</div>
					<div
						style={{
							boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
						}}
						className='border-l-8 border-[#C8001B] w-full h-auto rounded-[14px] p-10 mb-24'>
						<p className='text-[#333] text-[16px] mt-3'>
							<div
								className='mt-5'
								dangerouslySetInnerHTML={{
									__html: agencyObj?.instRemarks?.replace(/<li>/g, '<li> • '),
								}}
							/>
						</p>

						<div className=' flex justify-center w-full mt-7'>
							<button
								onClick={() => dispatch(handleLicenseStep(0))}
								className='text-blueDark font-bold border-none p-3'
								type='button'>
								Back to payment
							</button>
						</div>
					</div>
				</>
			) : (
				<>
					<div className='my-10 flex justify-between items-center w-full'>
						<span className='font-bold text-[36px] text-[#141414]'>
							Payment approval
						</span>
					</div>
					<div
						style={{
							boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
						}}
						className='border-l-8 border-[#FFD06D] w-full h-auto rounded-[14px] p-10 mb-24'>
						<p className='text-[#333] text-[16px] mt-3'>
							Payment receipt under review. You will receive the license once
							payment is confirmed
						</p>

						{/* <div className=' flex justify-center w-full mt-7'>
					<button
						onClick={handleLastStep}
						className='text-blueDark font-bold border-none p-3'
						type='button'
					>
						Okay
					</button>
				</div> */}
					</div>
				</>
			)}
		</>
	);
}
