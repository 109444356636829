import { Form, Input, Radio, Select, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShortlistedCandidates } from '../../../features/applicantJob/applicantJobSlice';
import { fetchJobs } from '../../../features/jobs/jobSlice';

export default function SelectReciepient({ handleCurrentView }) {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { jobData, jobLoading } = useSelector((state) => state.jobs);

	const [value, setValue] = useState(1);
	const [candidates, setcandidates] = useState([]);

	const onChange = (e) => {
		setValue(e.target.value);
		// if (e.target.value === 1) {
		// 	handleDontSendToNea()
		// }else{
		// 	handleSendToNea()
		// }
	};

	async function handleFetchJobs() {
		await dispatch(
			fetchJobs({
				jobInstId: user?.usrInstId,
				jobCntId: '',
			}),
		);
	}

	async function handleJobChange(val) {
		const res = await dispatch(fetchShortlistedCandidates(val));
		if (res?.payload?.success) {
			setcandidates(res?.payload?.data?.result);
		} else {
			setcandidates([]);
		}
	}

	function handleNext() {
		handleCurrentView(1);
	}

	useEffect(() => {}, [jobData]);

	useEffect(() => {
		handleFetchJobs();
	}, []);

	return (
		<>
			<div className='flex justify-start flex-col mt-10 py-10 min-h-full h-auto'>
				<span className='text-[18px] mb-3'>
					You can only initiate a direct message to your nearest NEA office or
					shortlisted candidates
				</span>

				<h3 className='text-[1rem] font-bold mb-3'>Select Recipient(s): </h3>

				<Radio.Group
					className='flex justify-start items-center w-full'
					onChange={onChange}
					value={value}
				>
					<Radio value={1}>National Employment Authority Office (NEA)</Radio>
					<Radio className='ml-10' value={2}>
						<span>Shortlisted Candidates</span>
					</Radio>
				</Radio.Group>

				{value === 2 ? (
					<>
						<div className='mt-10 w-full'>
							<div className='grid grid-cols-2 gap-5'>
								<Form.Item label='Jobs'>
									<Select
										loading={jobLoading}
										showSearch
										style={{
											width: '100%',
											height: '44px',
										}}
										placeholder='Select a job to get shortlisted candidates'
										optionFilterProp='children'
										filterOption={(input, option) =>
											(option?.label?.toLocaleLowerCase() ?? '').includes(
												input?.toLocaleLowerCase(),
											)
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										options={
											jobData?.length > 0 &&
											jobData?.map((item) => ({
												label: item?.jobPosition,
												value: item?.jobId,
											}))
										}
										onChange={handleJobChange}
									/>
								</Form.Item>

								<Form.Item name='candidates' label='Candidates'>
									<Select
										disabled={candidates?.length === 0}
										mode='multiple'
										maxTagCount={2}
										loading={jobLoading}
										showSearch
										style={{
											width: '100%',
											height: '44px',
										}}
										placeholder='Select a job to get shortlisted candidates'
										optionFilterProp='children'
										filterOption={(input, option) =>
											(option?.label?.toLocaleLowerCase() ?? '').includes(
												input?.toLocaleLowerCase(),
											)
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										options={
											candidates?.length > 0 &&
											candidates?.map((item) => ({
												label: item?.japFullName,
												value: item?.japUsrId,
											}))
										}
									/>
								</Form.Item>
							</div>

							<div className='flex justify-end mt-20'>
								<button
									disabled={candidates?.length === 0}
									onClick={handleNext}
									className='bg-blueDark rounded-[35px] text-white w-[300px] p-3'
									type='button'
								>
									Next
								</button>
							</div>
						</div>
					</>
				) : null}

				{value === 1 ? (
					<>
						<div className='mt-10 w-full'>
							<Form.Item label='Nea Office'>
								<Input
									value={user?.usrNearNeaOffice}
									readOnly
									className='input'
								/>
							</Form.Item>

							<div className='flex justify-end mt-20'>
								<button
									onClick={handleNext}
									className='bg-blueDark rounded-[35px] text-white w-[300px] p-3'
									type='button'
								>
									Next
								</button>
							</div>
						</div>
					</>
				) : null}
			</div>
		</>
	);
}
