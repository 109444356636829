import { Button, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { formatImgPath } from "../../../utils";
import AddDemand from "../../clients/demand-modal/AddDemand";
import {
  fetchDemandLetters,
  saveDemandLetter,
  setDemandLetterEditId,
  setDemandLetterEditMode,
} from "../../../features/demand/demandLetterSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { ExclamationCircleFilled } from "@ant-design/icons";

function UploadedFile({
  title,
  onClose,
  id,
  usrId,
  receivedId,
  hideButton,
  // singleJob,
  proddClient,
}) {
  const dispatch = useDispatch();
  const { demandLoading } = useSelector((state) => state.letter);
  const { user } = useSelector((state) => state.auth);
  const { refetch,singleJob } = useSelector((state) => state.jobs);
  const { refetchKey } = useSelector((state) => state.cv);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
    dispatch(setDemandLetterEditId(id));
    dispatch(setDemandLetterEditMode(true));
  };

  const { confirm } = Modal;
  const showConfirm = () => {
    confirm({
      title: "Do you want to submit for approval?",
      icon: <ExclamationCircleFilled />,
      content:
        "Once submitted, you will not be able to edit the demand letter again",
      className:
        "submit-modal-btn-primary approval-modal-title approval-modal-sub-title approval-btns",
      async onOk() {
        const res = await dispatch(
          saveDemandLetter({
            dmlStatus: "PENDING APPROVAL",
            dmlUsrId: usrId,
            dmlId: id,
          })
        );
        if (res?.payload?.dmlId) {
          toast.success("Demand letter sent for approval");
          await dispatch(
            fetchDemandLetters({
              dmlUsrId: user?.usrInstId,
              dmlCntId: receivedId,
            })
          );
          onClose();
        } else {
          toast.error("Not sent for approval, please try again");
        }
      },
      onCancel() {
        console.log("Cancel");
      },
      width: "600px",
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      onClose();
    }
  }, [isModalOpen]);

  // const { demandLetters } = useSelector((state) => state.letter);
  // const { refetchKey } = useSelector((state) => state.cv);
  // const [singleJobDemandLetter, setSingleJobDemandLetter] = useState(demandLetters?.find(
  //   (item) => item?.dmlId == singleJob?.jobDmlId)); 
  const [loading, setloading] = useState(true);

 

  // useEffect(() => {
  //   // const singleItem = demandLetters?.find(
  //   //   (item) => item?.dmlId == singleJob?.jobDmlId
  //   // );
  //   setSingleJobDemandLetter(demandLetters?.find(
  //     (item) => item?.dmlId == singleJob?.jobDmlId)); 
  // }, [singleJob?.jobDmlId,singleJob]);

  // useEffect(() => {
  //   if (refetchKey == 5) {
  //     dispatch(
  //       fetchDemandLetters({
  //         dmlUsrId: user?.usrInstId,
  //         dmlCntId: receivedId
  //           ? receivedId
  //           : singleJob?.jobCntId
  //           ? singleJob?.jobCntId
  //           : proddClient,
  //       })
  //     );
  //   }
  // }, [refetch, refetchKey]);

  const [iframeSrc, setIframeSrc] = useState("");
 
 
  useEffect(() => {
    if (refetchKey == 5) {  
      setIframeSrc(title ? formatImgPath(title) : formatImgPath(singleJob?.jobDemandLetter));
    }
  }, [refetch,refetchKey]);
  
  
  return (
    <>
      <div className="mt-[20px] mb-[50px] flex justify-between">
        <div className="flex flex-start"></div>
        {!hideButton && (
          <div className="flex flex-end justify-between gap-5  mr-[26px] ">
            <Button
              className="text-blueDark text-lg font-medium font-dmSans leading-7
            w-[283px] h-14 px-7 py-4 rounded-[56px] shadow border border-blueDark justify-center items-center gap-.5 inline-flex"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22 7.24002C22.0008 7.10841 21.9756 6.97795 21.9258 6.85611C21.876 6.73427 21.8027 6.62346 21.71 6.53002L17.47 2.29002C17.3766 2.19734 17.2658 2.12401 17.1439 2.07425C17.0221 2.02448 16.8916 1.99926 16.76 2.00002C16.6284 1.99926 16.4979 2.02448 16.3761 2.07425C16.2543 2.12401 16.1435 2.19734 16.05 2.29002L13.22 5.12002L2.29002 16.05C2.19734 16.1435 2.12401 16.2543 2.07425 16.3761C2.02448 16.4979 1.99926 16.6284 2.00002 16.76V21C2.00002 21.2652 2.10537 21.5196 2.29291 21.7071C2.48045 21.8947 2.7348 22 3.00002 22H7.24002C7.37994 22.0076 7.51991 21.9857 7.65084 21.9358C7.78176 21.8858 7.90073 21.8089 8.00002 21.71L18.87 10.78L21.71 8.00002C21.8013 7.9031 21.8757 7.79155 21.93 7.67002C21.9397 7.59031 21.9397 7.50973 21.93 7.43002C21.9347 7.38347 21.9347 7.33657 21.93 7.29002L22 7.24002ZM6.83002 20H4.00002V17.17L13.93 7.24002L16.76 10.07L6.83002 20ZM18.17 8.66002L15.34 5.83002L16.76 4.42002L19.58 7.24002L18.17 8.66002Z"
                    fill="#147CBC"
                  />
                </svg>
              }
              onClick={showModal}
            >
              Change demand letter
            </Button>

            <Button
              className="!text-blue-50 text-lg font-medium font-dmSans leading-7 text-center
            w-[283px] h-14 px-7 py-4 bg-blueDark rounded-[56px] shadow justify-center items-center gap-2.5 inline-flex"
              onClick={() => showConfirm()}
            >
              Submit for approval
            </Button>
          </div>
        )}
      </div>

      <div className="mx-[30px] h-auto bg-[#E6E6E6]">
        
        <div>
          <iframe
            onLoad={() => setloading(false)}
            title="Demand Letter Preview"
            style={{ width: "100%", height: "100vh" }}
            src={iframeSrc}
          ></iframe>

          {loading ? (
            <>
              <button
                className="bg-blueDark text-white w-[300px] p-3 flex justify-center mt-10"
                disabled
                type="button"
              >
                <Spin />
                <span className="ml-2">
                  Loading demand letter... Please wait
                </span>
              </button>
            </>
          ) : null}
        </div>
      </div>

      <AddDemand
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        showModal={showModal}
      />
    </>
  );
}

export default UploadedFile;
