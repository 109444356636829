import React from "react";
import container from "../assets/Container.png";
import containerArchive from "../assets/ContainerArchive.png";

function NoDataTableJob({ content, archive }) {
  return (
    <div
      className="mt-[31px] bg-white w-full h-[471.33px] rounded-[15px] border border-solid
   border-[#F5F7FF] py-[60px] flex flex-col justify-center items-center cursor-pointer"
    >
      <div>
        <img
          src={archive ? containerArchive : container}
          alt="group-pic"
          className="object-contain h-[291.33px] w-[292px]"
        />
        <div className="empty-desc flex items-center justify-center mt-[2.06rem]">
          {content}
        </div>
      </div>
    </div>
  );
}

export default NoDataTableJob;
