import axios from "axios"; 
import { useDispatch } from "react-redux";
import { logout } from "./features/auth/authSlice";

const url = process.env.REACT_APP_API_BASE_URL;

export const handleLogout = () => {
    window.location.href = 'https://kazi254.ke';
};

const axiosInstance = axios.create({
    baseURL: url,
    headers: {
        'Content-Type': 'application/json',
    },
});



axiosInstance.interceptors.response.use(response => {
    // console.log(response.headers);	
	
    return response;
}, (error) => {
		const statusCode = error.response ? error.response.status : null;

		if (statusCode === 401) {
			handleLogout();
		}

		if (statusCode >= 500) {
			// show server error
		}

		if (statusCode === 400) {
			handleLogout();
		}

		return Promise.reject(error);
	});

export default axiosInstance;