import { Button, Card, Form, Spin, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAttachments,
  saveAttachment,
} from "../../../../features/jobs/jobSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { formatImgPath, previewPaper } from "../../../../utils";
import uplodSvg from "../../../../assets/uploadPic.png";
import { saveFile } from "../../../../features/application/applicationSlice";

const url = process.env.REACT_APP_API_BASE_URL;

function Attachment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setdata] = useState({});
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { refetchKey } = useSelector((state) => state.cv);
  const { eShot, singleCreateJob, viewModeTable, jobEditId, refetch } =
    useSelector((state) => state.jobs);

  const [eshotDoc, setEshotDoc] = useState(eShot);
 
  const [loadingAttach, setLoadingAttach] = useState(false);
  const [profile, setProfile] = useState(eshotDoc?.attFile ? eshotDoc?.attFile : "");

  const onFinish = async (values) => {
    const res = await dispatch(
      saveAttachment({
        attInstId: singleCreateJob?.jobId ?? jobEditId,
        attType: "ESHOT",
        attFile: profile,
        attId: eshotDoc?.attId ? eshotDoc?.attId : null,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      fetchEshot();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  async function fetchEshot() {
    const res = await dispatch(
      fetchAttachments({
        attInstId: jobEditId ? jobEditId : singleCreateJob?.jobId,
        attType: "ESHOT",
      })
    );
  }

  
  const handleImageSelect = async (event) => {
    setLoadingAttach(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    const res = await dispatch(saveFile(formData));
    if (res?.payload?.success) {
      setLoadingAttach(false);
      setProfile(res?.payload?.jsonData);
      
      const res1 = await dispatch(
        saveAttachment({
          attInstId: singleCreateJob?.jobId ?? jobEditId,
          attType: "ESHOT",
          attFile: res?.payload?.jsonData, // Changed res1 to res
          attId: eshotDoc?.attId ? eshotDoc?.attId : null,
        })
      );
      if (res1?.payload?.success) {
        toast.success(res?.payload?.messages?.message);
        fetchEshot();
      } else {
        toast.error(res?.payload?.messages?.message);
      }
    } else {
      setLoadingAttach(false);
      toast.error("Please try upload your image again");
    }
};


  useEffect(() => {
    if (refetchKey == 4) {
      fetchEshot();
    }
  }, [refetch, refetchKey]);

  useEffect(() => {
    setEshotDoc(eShot);
  }, [eShot]);

  useEffect(() => {}, [eshotDoc]);

  useEffect(() => {
    setProfile(eshotDoc?.attFile ? eshotDoc?.attFile : "");
  }, [eshotDoc?.attFile]);

  return (
    <>
      <Card className="p-[20px] mr-2 rounded-[0px]">
        {!profile ? (
          <label className="flex flex-row items-center justify-start w-[20%]">
            <input
              name="secIcon"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageSelect(e)}
            
            />
            <img
              loading="lazy"
              className="z-10 w-[100px] h-[100px] rounded-full"
              alt="tab1"
              src={uplodSvg}
              style={{ cursor: "pointer" }}
            />
          </label>
        ) : loadingAttach ? (
          <Spin className="spin-modal" />
        ) : (
          <label className="flex flex-row items-center justify-start gap-2  w-[100%]">
            <input
              name="secIcon"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageSelect}
               
            />
            <div className="overflow-hidden border-4 border-blueDark">
              <img
                loading="lazy"
                src={formatImgPath(profile)}
                alt="director"
                className="cursor-pointer object-cover"
                style={{
                  width: "100%",
                  backgroundColor: "#FAFDFF",
                }}
              /> 
            </div>
          </label>
        )}
      </Card>
    </>
  );
}

export default Attachment;
