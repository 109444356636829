import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  agnLoading: false,
  agency: {},
  agencyObj: {},
  verifiedAgencys: [],
  branches: [],
  branchObj: {},
};

export const saveAgency = createAsyncThunk(
  "agencySlice/saveAgency",
  async (data) => {
    const res = await axios.post(`${url}/nea/saveInstitution.action`, data);
    return res.data;
  }
);

export const fetchAgencyData = createAsyncThunk(
  "agencySlice/fetchAgencyData",
  async (instId) => {
    const res = await axios.get(
      `${url}/nea/fetchInstitutions.action?instId=${instId}`
    );
    return res.data.data.result;
  }
);

export const fetchVerifiedAgencys = createAsyncThunk(
  "agencySlice/fetchVerifiedAgencys",
  async () => {
    const res = await axios.get(
      `${url}/nea/fetchInstitutions.action?instStatus=LICENSE_PAID`
    );
    return res.data.data.result;
  }
);
export const saveBranchAgency = createAsyncThunk(
  "agencySlice/saveBranchAgency",
  async (data) => {
    const res = await axiosInstance.post(
      `${url}/nea/saveInstitutionBranch.action`,
      data
    );
    return res.data;
  }
);

export const fetchAgencyBranches = createAsyncThunk(
  "agencySlice/fetchAgencyBranches",
  async (ibrnInstId) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchInstitutionBranchs.action`
    );
    return res.data.data.result;
  }
);

export const notifyNewAdmin = createAsyncThunk(
  "agencySlice/notifyNewAdmin",
  async (data) => {
    const res = await axiosInstance.post(
      `${url}/nea/notifyNewAdmin.action`,
      data
    );
    return res.data;
  }
);

export const deleteBranch = createAsyncThunk(
  "agencySlice/deleteBranch",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/deleteInstitutionBranch.action?ibrnId=${data?.ibrnId}`
    );
    return res.data;
  }
);

export const agencySlice = createSlice({
  name: "agency",
  initialState,
  reducers: {
    setBranchObj: (state, action) => {
      state.branchObj = action.payload;
    },
    clearAgency: (state) => {
      state.branchObj = {};
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveAgency.pending, (state) => {
        state.agnLoading = true;
      })
      .addCase(saveAgency.fulfilled, (state, action) => {
        state.agnLoading = false;
        state.agency = action?.payload;
      })
      .addCase(saveAgency.rejected, (state) => {
        state.agnLoading = false;
      })

      .addCase(fetchAgencyData.pending, (state) => {
        state.agnLoading = true;
      })
      .addCase(fetchAgencyData.fulfilled, (state, action) => {
        state.agnLoading = false;
        state.agencyObj = action?.payload[0];
      })
      .addCase(fetchAgencyData.rejected, (state) => {
        state.agnLoading = false;
        // state.agencyObj = {};
      })

      .addCase(fetchVerifiedAgencys.pending, (state) => {
        state.agnLoading = true;
      })
      .addCase(fetchVerifiedAgencys.fulfilled, (state, action) => {
        state.agnLoading = false;
        state.verifiedAgencys = action?.payload;
      })
      .addCase(fetchVerifiedAgencys.rejected, (state) => {
        state.agnLoading = false;
        state.verifiedAgencys = [];
      })

      .addCase(saveBranchAgency.pending, (state) => {
        state.agnLoading = true;
      })
      .addCase(saveBranchAgency.fulfilled, (state, action) => {
        state.agnLoading = false;
      })
      .addCase(saveBranchAgency.rejected, (state) => {
        state.agnLoading = false;
      })

      .addCase(fetchAgencyBranches.pending, (state) => {
        state.agnLoading = true;
      })
      .addCase(fetchAgencyBranches.fulfilled, (state, action) => {
        state.agnLoading = false;
        state.branches = action?.payload;
      })
      .addCase(fetchAgencyBranches.rejected, (state) => {
        state.agnLoading = false;
        state.branches = [];
      })

      .addCase(deleteBranch.pending, (state) => {
        state.agnLoading = true;
      })
      .addCase(deleteBranch.fulfilled, (state, action) => {
        state.agnLoading = false;
      })
      .addCase(deleteBranch.rejected, (state) => {
        state.agnLoading = false;
      });
  },
});

export default agencySlice.reducer;
export const { setBranchObj, clearAgency } = agencySlice.actions;
