import { Button, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActivityTable from "./ActivityTable";
import { clearActivityObj, saveJobActivity, setRefetch, setRefetchKey } from "../../features/activities/activitySlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Header from "./Header";
import InsideHeader from "../../components/InsideHeader";
import UpcomingActivityTable from "./UpcomingActivityTable";
import PastActivityTable from "./PastActivityTable";

function ActivityList() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { singleActivity, activityLoading, activityData } = useSelector(
    (state) => state.activity
  );
  const navigate = useNavigate();

  const handleAddActivity = async () => {
    navigate("/activity-add");
  };

  const [active, setactive] = useState("1");

  async function refetching(activeKey) {  
    dispatch(setRefetch());
    dispatch(setRefetchKey(activeKey));
    await setactive(activeKey);
  }

  const { TabPane } = Tabs;

  const tabConfigurations = [
    {
      label: (
        <span className="flex items-center">
          <span className=" ">All activities</span>
        </span>
      ),
      key: "1",
      children: <ActivityTable/>,
    },

    {
      label: (
        <span className="flex items-center">
          <span className="">Upcoming</span>
        </span>
      ),
      key: "2",
      children:<UpcomingActivityTable/>,
    },

    {
      label: (
        <span className="flex items-center">
          <span className="">Past</span>
        </span>
      ),
      key: "3",
      children: <PastActivityTable/>,
    },
  ];

  useEffect(()=>{
      dispatch(clearActivityObj())
  },[])

  return (
    <>
      <InsideHeader
        title="Activities"
        subtitle="Manage your activities such as meetings and interviews"
        back={false}
      />

      <div className="h-[111px] px-10">
        <Tabs
          items={tabConfigurations}
          defaultActiveKey="1"
          activeKey={active}
          onChange={refetching}
          className="activity-tab activity-tab-wrap activity-bar"
        />
      </div>


    
    </>
  );
}

export default ActivityList;
