import { useDispatch, useSelector } from 'react-redux';
import license from '../../assets/license.png';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { handleLicenseStep } from '../../features/license/licenseSlice';
import { fetchAgencyData, saveAgency } from '../../features/agency/agencySlice';
import { useNavigate } from 'react-router-dom';

const url = process.env.REACT_APP_API_BASE_URL;

export default function License() {
	const dispatch = useDispatch();
	const navigate = useNavigate()

	const { agencyObj } = useSelector((state) => state.agency);
	const { user } = useSelector((state) => state.auth);

	const [loading, setloading] = useState(true);

	async function handleRenewLicense() {
		const agnObj = {
			...agencyObj,
			instStatus: 'PENDING',
		};
		await dispatch(saveAgency(agnObj));
		await dispatch(fetchAgencyData(user?.usrInstId));
		await navigate("/application")
		await dispatch(handleLicenseStep(0));
	}

	useEffect(() => {}, [agencyObj]);

	return (
		<>
			{agencyObj?.instStatus === 'EXPIRED' ? (
				<>
					<div className='mb-10 flex justify-between items-center w-full'>
						<span className='font-bold text-[26px] text-[#141414]'>
							License expired
						</span>
					</div>

					<div
						style={{
							boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
						}}
						className='border-l-8 border-[#C8001B] w-full h-auto p-10 mb-24'>
						<p className='text-[#141414] font-bold text-[18px]'>
							Your license has expired. Renew your license to regain access to
							all our features.
						</p>
					</div>

					<div className='flex justify-center w-full pb-32'>
						<button
							onClick={handleRenewLicense}
							className='w-[248px] p-2 bg-blueDark text-white text-[18px] font-semibold rounded-[56px]'>
							Renew License
						</button>
					</div>
				</>
			) : (
				<>
					<div className='mb-10 flex justify-between items-center w-full'>
						<span className='font-bold text-[26px] text-[#141414]'>
							Your License Summary
						</span>
					</div>
					{agencyObj?.instStatus === 'NINETY_EXPIRATION' ? (
						<div
							style={{
								boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
							}}
							className='border-l-8 border-[#F7A325] w-full h-auto p-10 mb-24'>
							<p className='text-[#141414] font-bold text-[18px]'>
								License will expire in 90 days or less
							</p>
							<p className='text-gray-500 font-medium text-[15px]'>
								License is valid until{' '}
								{moment(agencyObj?.instExpiryDate).format('Do MMM YYYY')}
							</p>
						</div>
					) : agencyObj?.instStatus === 'THIRTY_EXPIRATION' ? (
						<div
							style={{
								boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
							}}
							className='border-l-8 border-[#F7A325] w-full h-auto p-10 mb-24'>
							<p className='text-[#141414] font-bold text-[18px]'>
								License will expire in 30 days or less
							</p>
							<p className='text-gray-500 font-medium text-[15px]'>
								License is valid until{' '}
								{moment(agencyObj?.instExpiryDate).format('Do MMM YYYY')}
							</p>
						</div>
					) : (
						<div
							style={{
								boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
							}}
							className='border-l-8 border-[#02A548] w-full h-auto p-10 mb-24'>
							<p className='text-[#141414] font-bold text-[18px]'>
								License is valid until{' '}
								{moment(agencyObj?.instExpiryDate).format('Do MMM YYYY')}
							</p>
						</div>
					)}

					<div className='flex flex-col justify-start h-full'>
						<span className='invoice-text mb-5'>Agency License</span>

						<iframe
							onLoad={() => setloading(false)}
							title='Generated invoice'
							width={'100%'}
							height={'700px'}
							src={`${url}/nea/directReport.action?&INST_ID=${agencyObj?.instId}&usrId=${user?.usrId}&reportName=certificate&PDF_FORMAT=inline&P_FORMAT=PDF`}></iframe>

						{loading ? (
							<>
								<button
									className='bg-blueDark text-white w-[300px] p-3 flex justify-center mt-10'
									disabled
									type='button'>
									<Spin />
									<span className='ml-2'>Loading license... Please wait</span>
								</button>
							</>
						) : null}
					</div>
				</>
			)}
		</>
	);
}
