import MaterialIcon from 'material-icons-react';
import { Divider, Input } from 'antd';
import ScrollTo from '../../../ScrollTo';
import CompanyRegistration from './agency_details_files/CompanyRegistration';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchAgencyDetailSetups } from '../../../features/dropDowns/dropDownSlice';
import { fetchAgencyData, saveAgency } from '../../../features/agency/agencySlice';

const AgencyDetails = () => {
	const dispatch = useDispatch();

	const { agencyDetailsSetup } = useSelector((state) => state.dropDown);
	const { agencyObj } = useSelector((state) => state.agency);
	const { user } = useSelector((state) => state.auth);

	const [formData, setFormData] = useState({
		instPhysicalAddress: agencyObj?.instPhysicalAddress ?? '',
		instPostalAddress: agencyObj?.instPostalAddress ?? '',
	});

	const onChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	async function handleAgencyData() {
		await dispatch(fetchAgencyDetailSetups());
	}

	const submitData = async () => {		
		let instCopy = {
		    ...formData,
			instId: agencyObj?.instId,
		};
		await dispatch(saveAgency(instCopy));
		await dispatch(fetchAgencyData(user?.usrInstId));
	};

	useEffect(() => {
		handleAgencyData();
	}, []);

	useEffect(() => {}, [agencyDetailsSetup, formData]);	


	return (
		<div className='flex flex-col w-full mt-10'>
			<ScrollTo />

			{agencyDetailsSetup?.map((item) => {
				return (
					<>
						<div>
							<div className='flex flex-row w-full mb-10'>
								<MaterialIcon color='#147CBC' icon='drag_indicator' />
								<div className='font-bold'>{item?.adsType}</div>
							</div>
							<CompanyRegistration item={item} />

							<Divider className='mt-10' />
						</div>
					</>
				);
			})}

			<div className='w-full mt-5'>
				<div className='flex flex-row w-full mb-10'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<div className='font-bold'>Office location</div>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-4 gap-10'>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Office Location</div>
						<Input
							onBlur={submitData}
							className='rounded-[8px]  h-[44px] w-full border border-black'
							onChange={(e) => onChange(e)}
							name='instPhysicalAddress'
							value={formData.instPhysicalAddress}
						/>
					</div>

					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Postal address</div>
						<Input
							onBlur={submitData}
							className='rounded-[8px]  h-[44px] w-full border border-black'
							onChange={(e) => onChange(e)}
							name='instPostalAddress'
							value={formData.instPostalAddress}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AgencyDetails;
