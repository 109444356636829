import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { handleToken, refreshToken } from './features/auth/authSlice';

const parseJwt = (token) => {
	try {
		return JSON.parse(atob(token.split('.')[1]));
	} catch (e) {
		return null;
	}
};

const AuthVerify = (props) => {
	let location = useLocation();
	const dispatch = useDispatch();

	const { user, token } = useSelector((state) => state.auth);

	async function handleRefreshToken() {
		const usrObj = {
			usrId: user?.usrId,
			usrUsername: user?.usrUsername,
		};
		const res = await dispatch(refreshToken(usrObj));
		let instObj = await res?.payload?.find(
			(item) => item?.myInstId === user?.usrInstId
		);
		await dispatch(handleToken(instObj?.myDesc));
	}

	// useEffect(() => {
	// 	if (token?.length || props.usrToken?.length) {
	// 		const decodedJwt = parseJwt(props.usrToken || token);

	// 		if (decodedJwt?.exp * 1000 < Date.now()) {
	// 			props.logOut();
	// 			handleRefreshToken();
	// 		}
	// 	} else {
	// 		props.logOut();
	// 	}
	// }, [location, token]);
};

export default AuthVerify;
