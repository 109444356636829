import React, { useState } from "react";
import ClientTable from "./ClientTable";
import { useDispatch, useSelector } from "react-redux";
import InsideHeader from "../../components/InsideHeader";

function ClientList() {
  const dispatch = useDispatch();
  const { agencyObj } = useSelector((state) => state.agency);
  return (
    <>
      <InsideHeader
        title={`${agencyObj?.licenseName == 'LOCAL LICENSE'? 'Local' : 'Foreign'} Employer`}
        subtitle={`Manage your ${agencyObj?.licenseName == 'LOCAL LICENSE' ? 'local' : 'foreign' } employer`}
        back={false}
      />
      <div className="">
        <ClientTable />
      </div>
    </>
  );
}

export default ClientList;
