import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Spin,
  message,
  Table,
  InputNumber,
  Breadcrumb,
} from "antd";
import "../clients/demand-modal/demand-modal.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Dragger from "antd/es/upload/Dragger";
import { saveDemandLetter } from "../../features/demand/demandLetterSlice";
import "./editable.css";
import { fetchClients } from "../../features/client/clientSlice";
import ConfirmSubmitDemandModal from "./modal/ConfirmSubmitDemandModal";
import DeleteModal from "../../components/DeleteModal";
import { saveJob } from "../../features/jobs/jobSlice";
import { fetchQualifications } from "../../features/jobFilters/jobFilterSlice";
import {
  fetchGender,
  fetchWorkCategorys,
} from "../../features/dropDowns/dropDownSlice";
import InsideHeader from "../../components/InsideHeader";
import addSvg from "../../assets/add-24px.svg";
import { currencyData } from "../../currency";
import { checkDeletePermission, checkEditPermission } from "../../utils";

const url = process.env.REACT_APP_API_BASE_URL;
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);

  const form = useContext(EditableContext);
  const { qualifications, categorys } = useSelector((state) => state.jobFilter);
  const { workTypeCategory, genders } = useSelector((state) => state.dropDown);

  const dispatch = useDispatch();
  useEffect(() => {
    if (editable) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [editable, dataIndex, record, form]);

  const save = async () => {
    try {
      const values = await form.getFieldsValue();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value) => {};

  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {dataIndex === "jobGender" ? (
          <Select
            style={{
              width: "100%",
              height: "44px",
            }}
            placeholder="Select Gender"
            options={
              genders?.length > 0 &&
              genders?.map((item) => ({
                value: item?.gndName,
                label: item?.gndName,
              }))
            }
            // options={GenderData}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        ) : dataIndex === "jobQualification" ? (
          <Select
            style={{
              width: "100%",
              height: "44px",
            }}
            placeholder="Select Qualification"
            options={
              qualifications?.length > 0 &&
              qualifications?.map((item) => ({
                value: item?.qualName,
                label: item?.qualName,
              }))
            }
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            showSearch
            optionFilterProp="children"
            onSearch={onSearch}
            filterOption={filterOption}
          />
        ) : dataIndex === "jobCategory" ? (
          <Select
            style={{
              width: "100%",
              height: "44px",
            }}
            placeholder="Select job category"
            options={
              workTypeCategory?.length > 0 &&
              workTypeCategory?.map((item) => ({
                value: item?.wcatName,
                label: item?.wcatName,
              }))
            }
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            showSearch
            optionFilterProp="children"
            onSearch={onSearch}
            filterOption={filterOption}
          />
        ) : dataIndex === "jobCurrency" ? (
          <Select
            style={{
              width: "100%",
              height: "44px",
            }}
            placeholder="Select currency"
            options={
              currencyData?.length > 0 &&
              currencyData?.map((item) => ({
                value: item?.Ccy,
                label: item?.Ccy,
              }))
            }
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            showSearch
            optionFilterProp="children"
            onSearch={onSearch}
            filterOption={filterOption}
          />
        ) : dataIndex === "jobName" || dataIndex === "jobWorkingHours" ? (
          <Input
            className="input"
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        ) : (
          <InputNumber
            style={{
              width: "100%",
            }}
            className="input flex items-center"
            onPressEnter={save}
            onBlur={save}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) =>
              value ? parseInt(value.replace(/\$\s?|(,*)/g, ""), 10) : ""
            }
          />
          // <Input type="number" ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const AddDemandLetter = () => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  const [isModalType, setIsModalType] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = async () => {
    setIsModalOpen(true);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { demandLetterEditMode, demandLetterEditId, demandLoading } =
    useSelector((state) => state.letter);
  const { clientLoading, clientData, clientEditId } = useSelector(
    (state) => state.client
  );
  const { workTypeCategory, genders } = useSelector((state) => state.dropDown);
  const { menuSidebarData } = useSelector((state) => state.admin);

  const [clientDataList, setClientDataList] = useState(clientData);
  const [receivedId, setReceivedId] = useState(clientEditId);
  const { agencyObj } = useSelector((state) => state.agency);

  function closeModal() {
    toast.dismiss();
  }
  const [data, setdata] = useState({});
  const [prodd, setProdd] = useState();

  const [fileUrl, setfileUrl] = useState("");
  const [fileName, setfileName] = useState("");

  const props = {
    name: "file",
    multiple: false,
    accept: ".pdf",
    action: `${url}/nea/saveFile.action`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (info?.file?.response?.success) {
          setfileUrl(info?.file?.response?.jsonData);
          setfileName(info?.file?.name);
        } else {
          setfileUrl("");
          setfileName("");
        }
        console.log(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  function handleSelectChange(value) {
    setdata((prevData) => ({
      ...prevData,
      cntName: value,
    }));
  }

  const handleCancel = () => {
    navigate("/letter-list");
  };

  async function fetchClientDetails() {
    const res = await dispatch(
      fetchClients({
        cntInstId: user?.usrInstId,
      })
    );
  }

  useEffect(() => {
    dispatch(fetchWorkCategorys());
    dispatch(fetchGender());
  }, []);

  useEffect(() => {
    fetchClientDetails();
  }, []);

  useEffect(() => {
    setClientDataList(clientData);
  }, [clientData]);

  useEffect(() => {}, [clientDataList]);

  useEffect(() => {
    setReceivedId(clientEditId);
  }, [clientEditId]);

  const { demandJobData, demandJobLoading } = useSelector(
    (state) => state.demandJob
  );

  const [dataSource, setDataSource] = useState([]);
  const validateFields = (data) => {
    const requiredColumns = [
      "jobCategory",
      "jobName",
      "jobVacancyCount",
      "jobGrossPay",
      "jobGender",
      "jobQualification",
      "jobWorkingHours",
      "jobCurrency",
    ];

    for (const item of data) {
      for (const column of requiredColumns) {
        if (!item[column]) {
          toast.error(
            `Please fill in all fields for the job titled "${item.jobName}"`
          );
          return false;
        }
      }
    }
    return true;
  };

  const onFinish = async (values) => {
    values.cntName =
      data?.cntName ??
      clientDataList?.find((item) => item?.cntId == receivedId)?.cntId;
    if (!dataSource || dataSource?.length === 0) {
      toast.error("Add atleast one job associated with the demand letter");
      return;
    }
    if (!values?.cntName) {
      toast.error("Select client");
      return;
    }
    if (!validateFields(dataSource)) {
      return;
    }

    const res1 = await dispatch(
      saveDemandLetter({
        dmlFile: fileUrl,
        dmlUsrId: user.usrInstId,
        dmlCntId: values?.cntName,
        dmlName: values?.dmlName,
        dmlCreatedBy: user?.usrId,
      })
    );
    if (res1?.payload?.success) {
      setProdd(res1?.payload?.jsonData);
      let successCount = 0;

      dataSource.forEach(async (item) => {
        try {
          const res = await dispatch(
            saveJob({
              jobCategory: item.jobCategory,
              jobCategoryId: item?.jobCategoryId,
              jobMainCategoryId: item?.jobMainCategoryId,
              jobName: item.jobName,
              jobVacancyCount: item.jobVacancyCount,
              jobGrossPay: item.jobGrossPay,
              jobGender: item.jobGender,
              jobCurrency: item?.jobCurrency,
              jobDmlId: res1?.payload?.jsonData?.dmlId,
              jobCntId: res1?.payload?.jsonData?.dmlCntId,
              jobInstId: user?.usrInstId,
              jobQualification: item?.jobQualification,
              jobWorkingHours: item?.jobWorkingHours,
              jobInstType: "AGENCY",
            })
          );
          if (res?.payload?.success) {
            successCount++;
            if (successCount === dataSource?.length) {
              toast.success("All demand letter jobs added successfully");
              if (isModalType) {
                showModal();
                form.resetFields();
                values.cntName = "";
              } else if (clientEditId) {
                form.resetFields();
                navigate("/client-tabs");
              } else {
                form.resetFields();
                navigate("/letter-list");
              }
            }
          } else {
            toast.error(res?.payload?.messages?.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      });
    } else {
      toast.error(res1?.payload?.messages?.message);
    }
  };

  const [count, setCount] = useState(2);
  const [keyDelete, setKeyDelete] = useState();

  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };

  const handleDelete = () => {
    const newData = dataSource.filter((item) => item.key !== keyDelete);
    setDataSource(newData);
    setIsModalOpenDelete(false);
  };

  const content = "Are you sure you want to delete ?";

  function handleSelectChange(value) {
    setdata((prevData) => ({
      ...prevData,
      cntName: value,
    }));
  }

  const handleAdd = () => {
    const newData = {
      key: count.toString(),
      jobCategory: "",
      jobName: "",
      jobVacancyCount: "",
      jobGrossPay: "",
      jobGender: "",
      jobQualification: "",
      jobWorkingHours: "",
      jobCurrency: "",
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    const cat =
      workTypeCategory &&
      workTypeCategory?.find((item) => item.wcatName === row.jobCategory);
    newData.splice(index, 1, {
      ...item,
      ...row,
      jobCategoryId: cat?.wcatId,
      jobMainCategoryId: cat?.wcatWktId,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const hasActionColumn = checkDeletePermission(menuSidebarData,"DEMAND_LETTER");
  const columns = [
    {
      title: "Job Category",
      dataIndex: "jobCategory",
      editable: true,
    },
    {
      title: "Job Title",
      dataIndex: "jobName",
      editable: true,
      // width:300
    },
    {
      title: "Working Hours/day",
      dataIndex: "jobWorkingHours",
      editable: true,
    },
    {
      title: "No. of Vacancies",
      dataIndex: "jobVacancyCount",
      editable: true,
    },
    {
      title: "Salary Currency",
      dataIndex: "jobCurrency",
      editable: true,
    },
    {
      title: "Salary Expectation",
      dataIndex: "jobGrossPay",
      editable: true,
    },
    {
      title: "Sex Required",
      dataIndex: "jobGender",
      editable: true,
    },
    {
      title: "Qualification",
      dataIndex: "jobQualification",
      editable: true,
    },

    ...(hasActionColumn
      ? [
          {
            title: "operation",
            dataIndex: "operation",
            className:
              "cursor-pointer flex items-center justify-center h-full border-none",
            render: (_, record) =>
              dataSource.length >= 1 ? (
                <div onClick={() => setKeyDelete(record.key)}>
                  <span onClick={showModalDelete}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                    >
                      <path
                        d="M4.25 8.5026H7.08333M7.08333 8.5026H29.75M7.08333 8.5026V28.3359C7.08333 29.0874 7.38184 29.8081 7.9132 30.3394C8.44455 30.8708 9.16522 31.1693 9.91667 31.1693H24.0833C24.8348 31.1693 25.5554 30.8708 26.0868 30.3394C26.6182 29.8081 26.9167 29.0874 26.9167 28.3359V8.5026H7.08333ZM11.3333 8.5026V5.66927C11.3333 4.91782 11.6318 4.19715 12.1632 3.6658C12.6946 3.13445 13.4152 2.83594 14.1667 2.83594H19.8333C20.5848 2.83594 21.3054 3.13445 21.8368 3.6658C22.3682 4.19715 22.6667 4.91782 22.6667 5.66927V8.5026M14.1667 15.5859V24.0859M19.8333 15.5859V24.0859"
                        stroke="#B42318"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              ) : null,
          },
        ]
      : []),
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  async function fetchQulificationsData() {
    await dispatch(fetchQualifications());
  }

  useEffect(() => {
    fetchQulificationsData();
  }, []);

  return (
    <>
      <InsideHeader
        title="Demand Letter"
        subtitle="Add demand letter"
        back={false}
      />
      <div className="mt-[2.5rem] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer "
                  onClick={() => navigate("/letter-list")}
                >
                  Demand letters
                </span>
              ),
            },
            {
              title: (
                <span className="text-blueDark font-dmSans">
                  Add demand letter jobs
                </span>
              ),
            },
          ]}
        />
      </div>

      <div className="px-10 bg-white mr-3  mt-[1.62rem] min-h-[100vh] h-auto relative">
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <h3 className="font-[700] text-[24px] text-black1 dash-title ">
            Please fill in the details below
          </h3>
          <div className="grid grid-cols-2 mt-[30px] gap-x-5">
            <div className="flex flex-col mb-4">
              <label
                className="font-dmSans !text-[18px] font-[500] text-[#222] leading-relaxed mb-1"
                htmlFor=""
              >
                Choose{" "}
                {agencyObj?.licenseName == "LOCAL LICENSE"
                  ? "local"
                  : "foreign"}{" "}
                employer{" "}
              </label>
              <Select
                style={{
                  width: "100%",
                  height: "44px",
                }}
                options={
                  clientDataList &&
                  clientDataList?.map((item) => ({
                    value: item.cntId,
                    label: item.cntName,
                  }))
                }
                defaultValue={
                  receivedId
                    ? clientDataList &&
                      clientDataList?.find((item) => item?.cntId == receivedId)
                        ?.cntId
                    : undefined
                }
                name="cntName"
                onChange={(value) => {
                  handleSelectChange(value);
                }}
                onDropdownVisibleChange={() => {
                  fetchClientDetails();
                  if (clientDataList?.length === 0) {
                    toast.error(
                      `You should have ${
                        agencyObj?.licenseName === "LOCAL LICENSE"
                          ? "local"
                          : "foreign"
                      } employer ready`
                    );
                    navigate("/client-list");
                  }
                }}
              />
            </div>
            <div></div>

            <Form.Item
              name="dmlName"
              label="Demand Letter Ref No"
              rules={[
                {
                  required: true,
                  message: "Please add Demand Letter Ref No",
                },
              ]}
            >
              <Input className="input" />
            </Form.Item>

            <Form.Item
              name="attFile"
              label={
                demandLetterEditMode
                  ? "Change Demand Letter"
                  : "Upload Demand Letter"
              }
              rules={[
                {
                  required: true,
                  message: "Please upload a file",
                },
              ]}
            >
              <Dragger {...props}>
                <div className="flex justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                    fill="none"
                    className="mt-[-8px]"
                  >
                    <path
                      d="M25.375 18.125V22.9583C25.375 23.5993 25.1204 24.214 24.6672 24.6672C24.214 25.1204 23.5993 25.375 22.9583 25.375H6.04167C5.40073 25.375 4.78604 25.1204 4.33283 24.6672C3.87961 24.214 3.625 23.5993 3.625 22.9583V18.125"
                      stroke="#147CBC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.5423 9.66667L14.5007 3.625L8.45898 9.66667"
                      stroke="#147CBC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.5 3.625V18.125"
                      stroke="#147CBC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p className="ant-upload-text !ml-2">Upload pdf</p>
                </div>
              </Dragger>
            </Form.Item>
          </div>

          <div>
            <div className="flex justify-between mt-[70px] mb-[31px] items-center">
              <h3 className="font-[700] text-[24px] text-black1 dash-title ">
                Add jobs to demand letter
              </h3>

              <button
                type="button"
                className="w-[200px] save-btn"
                onClick={handleAdd}
              >
                <img src={addSvg} alt="addSvg" /> Add job
              </button>
            </div>

            <Table
              locale={{
                emptyText: (
                  <>
                    <div className="w-full min-h-[200px] flex flex-col justify-center items-center">
                      <span className="text-[#808080] text-[21px] font-normal font-dmSans leading-[29.40px]">
                        No jobs added yet. Click add job button{" "}
                      </span>
                    </div>
                  </>
                ),
              }}
              components={components}
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={mergedColumns}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: false,
                hideOnSinglePage: true,
              }}
              scroll={{ x: 'max-content' }}
              // scroll={{
              //   x: 1300,
              // }}
            />
          </div>

          <div className="flex  justify-between mt-[1rem] mb-[40px] py-7  bottom-2 w-full">
            <div className="justify-start">
              <button
                type="button"
                className="w-[200px] cancel-btn border border-blueDark flex items-center justify-center"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
            {checkEditPermission(menuSidebarData,"DEMAND_LETTER") && (
              <div className="flex items-center  gap-x-3 ">
                <div onClick={() => setIsModalType(false)}>
                  <button
                    className="w-[200px] save-btn"
                    type="submit"
                    disabled={!dataSource || dataSource?.length === 0}
                  >
                    {demandJobLoading ? <Spin /> : "Save Draft"}
                  </button>
                </div>

                <div onClick={() => setIsModalType(true)}>
                  <button
                    className={`w-[250px] ${
                      !dataSource || dataSource?.length === 0
                        ? "!bg-[#9CC6E1]"
                        : "!bg-blueDark"
                    } save-btn`}
                    type="submit"
                    disabled={!dataSource || dataSource?.length === 0}
                  >
                    {demandJobLoading && isModalType ? (
                      <Spin />
                    ) : (
                      " Submit for approval"
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </Form>
      </div>

      <ConfirmSubmitDemandModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        prodd={prodd}
        navigateBack="true"
      />

      <DeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        handleDelete={handleDelete}
        content={content}
        title="Delete Demand Letter"
        loading={demandJobLoading}
      />
    </>
  );
};
export default AddDemandLetter;
