import { Table } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanUpJobEditId, 
  fetchJobs,
  fetchJobsByUser,
  setEditJobDescription,
  setEditJobDetails,
  setEditSkills,
  setEditTermsEmployment,
  setJobEditId,
  setRefetch,
  setViewJobDescription,
  setViewJobDetails,
  setViewModeTable,
  setViewSkills,
  setViewTermsEmployment,
} from "../../../../features/jobs/jobSlice";
import { cashConverter, dateForHumans } from "../../../../utils";  
import { Skeleton } from "antd";
import backArrow from "../../../../assets/Back.svg";
import {
  fetchApplicantsDetails,
  setTabsActiveKey,
} from "../../../../features/applicant/applicantSlice";
import { setRefetchKey } from "../../../../features/cv/cvSlice";   
 
function JobTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
 

  const { jobData, jobLoading } = useSelector((state) => state.jobs);
  const {singleAdminUser,userEditId} =  useSelector((state)=>state.admin)
 

  const { applicantJobViewTable } = useSelector((state) => state.cv);

  const { user } = useSelector((state) => state.auth);
  const { clientEditId } = useSelector((state) => state.client);
 

  const [searchText, setSearchText] = useState("");

  const [open, setOpen] = useState(false);
  const [prodd, setProdd] = useState("");
  const clean = async () => {
    await dispatch(cleanUpJobEditId());
  };

  const showDrawer = async (id) => {
    await dispatch(setJobEditId(id));
    await dispatch(setViewJobDetails(true));
    await dispatch(setEditJobDetails(false));
    await dispatch(setViewTermsEmployment(true));
    await dispatch(setEditTermsEmployment(false));
    await dispatch(setViewJobDescription(true));
    await dispatch(setEditJobDescription(false));
    await dispatch(setViewModeTable(true));
    await dispatch(setViewSkills(true));
    await dispatch(setEditSkills(false));
    await dispatch(setRefetch()); 
    await dispatch(setTabsActiveKey("1"));
    await dispatch(setRefetchKey("1"));
    await navigate('/job-view');
  };

  async function fetchJobsDetails() {
    const res = await dispatch(
      fetchJobsByUser({
        jobInstId: user?.usrInstId,
        jobCntId: "",
        jobCreatedBy:userEditId
      })
    );
  }

  async function fetchJobsDetailsClient() {
    const res = await dispatch(
      fetchJobsByUser({
        jobInstId: user?.usrInstId,
        jobCntId: clientEditId,
        jobCreatedBy:userEditId
      })
    );
  }

  useEffect(() => {
    if (clientEditId) {
      fetchJobsDetailsClient();
    } else {
      fetchJobsDetails();
    }
  }, [clientEditId]);
  const columns = [
    {
      title: "Position name",
      render: (item) => (
        <>
          <div className="grid grid-cols-2">
            <div className="flex items-center">
              <span className="table-name mx-3">{item?.jobPosition}</span>
            </div>
            <div className="flex items-center">
              <button className="p-0 mx-4 flex items-end" type="button">
                <MaterialIcon
                  color="#000"
                  icon="visibility"
                  onClick={() => showDrawer(item?.jobId)}
                />
              </button>
              <button className="p-0 m-0 flex items-end" type="button">
                <MaterialIcon color="#000" icon="more_vert" />
              </button>
            </div>
          </div>
        </>
      ),
    },  
    {
      title: "Job Client",
      dataIndex: "jobCntName",
    },
    {
      title: "Job Location",
      dataIndex: "jobCountry",
    },
    {
      title: "Vacancy Count	",
      dataIndex: "jobVacancyCount",
    },
    {
      title: "Salary",
      render: (item) => <div>{cashConverter(item?.jobGrossPay)}</div>,
    },
    {
      title: "Job Owner",
      render: (item) => {
        return <div>{user?.usrFullNames}</div>;
      },
    },
    {
      title: "Date Created",
      render: (item) => <div>{dateForHumans(item?.jobCreatedDate)}</div>,
    },
  ];

   
  const filteredData = searchText
    ? jobData?.filter((item) =>
        item?.jobPosition?.toLowerCase().includes(searchText.toLowerCase())
      )
    : jobData;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const { applicantEditId, applicants, singleCreateApplicant } = useSelector(
    (state) => state.applicant
  );

  const [applicantsList, setapplicantsList] = useState(applicants);
  const [singleClient, setSingleClient] = useState("");
  const [receivedId, setReceivedId] = useState(
    applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
  );

  useEffect(() => {
    setapplicantsList(applicants);
  }, [applicants]);

  useEffect(() => {
    setReceivedId(
      applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
    );
  }, [applicantEditId, singleCreateApplicant?.usrId]);

  useEffect(() => {
    const singleItem =
      applicantsList?.length > 0 &&
      applicantsList?.find((item) => item?.usrId == receivedId);
    setSingleClient(singleItem);
  }, [receivedId, applicantsList]);

  useEffect(() => {}, [singleClient]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [rowId, setRowId] = useState([]);

  const handleAddJobToApplicant = async (selectedRows) => {
    setRowId(selectedRows);
  };
 
  const onSelectChange = (keys, rows) => {
    setSelectedRowKeys(keys);
    handleAddJobToApplicant(rows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleBack = async () => {
    await navigate("/applicant-tabs");
  };

  async function fetchApplicants() {
    const res = await dispatch(
      fetchApplicantsDetails({
        usrInstId: user?.usrInstId,
      })
    );
  }

  useEffect(() => {
    fetchApplicants();
  }, []);

  return (
    <>
      {jobLoading ? (
        <>
          <Skeleton />
        </>
      ) : (
        <>
          {applicantJobViewTable && (
            <div className="bg-white mr-3 mb-[10px]">
              <div className="flex justify-between">
                <div className="items-center ml-[54px] flex text-center h-[115px] ">
                  <span className="text-black1 text-2xl font-bold font-dmSans leading-[54px]">
                    Jobs
                  </span>
                </div>
                <div className="flex-end mr-[26px] items-center flex gap-[5rem]">
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={handleBack}
                  >
                    <div
                      style={{
                        height: "35px",
                        width: "35px",
                        background: "#EDF8FF",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={backArrow}
                        alt="back-arrow"
                        style={{ height: "40px", width: "40px" }}
                      />
                    </div>

                    <span className="text-black2 text-[22px] font-bold font-dmSans leading-relaxed">
                      Go back
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="min-h-[895px] bg-white border border-gray-200  mt-[1%]">
            <div className=" p-[50px]">
            <div className="text-neutral-900 text-2xl font-medium font-dmSans leading-9 mb-10">These are the jobs {singleAdminUser?.usrFirstName} has added</div>

              <div className="flex items-center justify-between ">
                <div className="flex items-start">
                  <form class="flex items-center px-10">
                    <div class="relative w-full">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 mr-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="voice-search"
                        className="bg-gray-50 !border-none text-black1 text-[19px] font-normal font-dmSans leading-7 rounded-lg  block w-full pl-10 p-2.5  h-[52px] px-[18px]
                        dark:placeholder-gray-400 dark:text-white "
                        placeholder="Looking for a specific job?"
                        required
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                    <button
                      type="submit"
                      className="text-white text-[1rem] font-medium font-dmSans leading-normal
                      w-[194px] h-[52px] px-[18px] py-[19px] bg-blueDark rounded shadow border border-[#F6F6F6] justify-center items-center gap-2 inline-flex"
                    >
                      Search job
                    </button>
                  </form>
                </div> 
              </div>
            </div>

            <div className="mt-[6px] ml-[45px] mr-[10px]">
              <Table
                rowSelection={rowSelection}
                size={"middle"}
                bordered={true}
                pagination={{
                  position: ["bottomCenter"],
                }}
                rowKey={(record) => record?.jobId}
                columns={tableColumns}
                dataSource={filteredData}
                scroll={"scroll"}
                className="custom-table"
                loading={jobLoading}
              />
            </div>  
          </div>
        </>
      )} 
    </>
  );
}

export default JobTable;
