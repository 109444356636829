import React, { useEffect } from "react";
import { Button, Card, Collapse, Descriptions, theme } from "antd"; 
import "./client.css";
import BasicInfo from "../../components/client/BasicInfo";
import Location from "../../components/client/Location";
import Contact from "../../components/client/Contact";
import BasicInfoView from "../../components/client/BasicInfoView";
import { useDispatch, useSelector } from "react-redux";
import { 
  setClientEditId, 
  setEditBasic,
  setEditContact,
  setEditLocation, 
} from "../../features/client/clientSlice";
import { useNavigate } from "react-router-dom"; 
import LocationInfoView from "../../components/client/LocationInfoView"; 
import ContactInfoView from "../../components/client/ContactInfoView";
import eclipse from '../../assets/ellipse.svg'
import { checkEditPermission } from "../../utils";

const ClientAdd = () => {
  const { token } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    clients,
    viewBasic,
    editBasic,
    viewLocation,
    editLocation,
    viewContact,
    editContact,
    clientEditId, 
  } = useSelector((state) => state.client);
  const { menuSidebarData } = useSelector((state) => state.admin); 
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "1px solid #147CBC",
  };
  const handleEditBasic = () => {
    dispatch(setEditBasic(true));
    if (Object.keys(clients)?.length === 0) {
    } else {
      dispatch(setClientEditId(clients?.cntId));
    }
  };

  const handleEditLocation = () => {
    dispatch(setEditLocation(true));
    if (Object.keys(clients).length === 0) {
    } else {
      dispatch(setClientEditId(clients?.cntId));
    }
  };

  const handleEditContact = () => {
    dispatch(setEditContact(true));
    if (Object.keys(clients).length === 0) {
    } else {
      dispatch(setClientEditId(clients?.cntId));
    }
  };

  // const handleEditContact = () => {
  //   dispatch(setEditContact(true));
  // };
  // const handleContactAdding =async () => {
  //  await dispatch(setContactAdding(true));
  //  await dispatch(setViewContact(false));
  // };

  const genExtraBasic = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={(event) => {
        event.stopPropagation();
        handleEditBasic();
      }}
    >
      <path
        d="M11 3.99632H4C3.46957 3.99632 2.96086 4.20703 2.58579 4.58211C2.21071 4.95718 2 5.46589 2 5.99632V19.9963C2 20.5268 2.21071 21.0355 2.58579 21.4105C2.96086 21.7856 3.46957 21.9963 4 21.9963H18C18.5304 21.9963 19.0391 21.7856 19.4142 21.4105C19.7893 21.0355 20 20.5268 20 19.9963V12.9963M18.5 2.49632C18.8978 2.0985 19.4374 1.875 20 1.875C20.5626 1.875 21.1022 2.0985 21.5 2.49632C21.8978 2.89415 22.1213 3.43371 22.1213 3.99632C22.1213 4.55893 21.8978 5.0985 21.5 5.49632L12 14.9963L8 15.9963L9 11.9963L18.5 2.49632Z"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const genExtraLocation = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={(event) => {
        event.stopPropagation();
        handleEditLocation();
      }}
    >
      <path
        d="M11 3.99632H4C3.46957 3.99632 2.96086 4.20703 2.58579 4.58211C2.21071 4.95718 2 5.46589 2 5.99632V19.9963C2 20.5268 2.21071 21.0355 2.58579 21.4105C2.96086 21.7856 3.46957 21.9963 4 21.9963H18C18.5304 21.9963 19.0391 21.7856 19.4142 21.4105C19.7893 21.0355 20 20.5268 20 19.9963V12.9963M18.5 2.49632C18.8978 2.0985 19.4374 1.875 20 1.875C20.5626 1.875 21.1022 2.0985 21.5 2.49632C21.8978 2.89415 22.1213 3.43371 22.1213 3.99632C22.1213 4.55893 21.8978 5.0985 21.5 5.49632L12 14.9963L8 15.9963L9 11.9963L18.5 2.49632Z"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const genExtraContact = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={(event) => {
        event.stopPropagation();
        handleEditContact();
      }}
    >
      <path
        d="M11 3.99632H4C3.46957 3.99632 2.96086 4.20703 2.58579 4.58211C2.21071 4.95718 2 5.46589 2 5.99632V19.9963C2 20.5268 2.21071 21.0355 2.58579 21.4105C2.96086 21.7856 3.46957 21.9963 4 21.9963H18C18.5304 21.9963 19.0391 21.7856 19.4142 21.4105C19.7893 21.0355 20 20.5268 20 19.9963V12.9963M18.5 2.49632C18.8978 2.0985 19.4374 1.875 20 1.875C20.5626 1.875 21.1022 2.0985 21.5 2.49632C21.8978 2.89415 22.1213 3.43371 22.1213 3.99632C22.1213 4.55893 21.8978 5.0985 21.5 5.49632L12 14.9963L8 15.9963L9 11.9963L18.5 2.49632Z"
        stroke="#101828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  // const genExtraContact = () => (
  //   <div
  //     className="flex items-center gap-x-2"
  //     onClick={(event) => {
  //       event.stopPropagation();
  //       handleContactAdding();
  //     }}
  //   >
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="25"
  //       height="24"
  //       viewBox="0 0 25 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M12.5 2C10.5222 2 8.58879 2.58649 6.9443 3.6853C5.29981 4.78412 4.01809 6.3459 3.26121 8.17317C2.50433 10.0004 2.3063 12.0111 2.69215 13.9509C3.078 15.8907 4.03041 17.6725 5.42894 19.0711C6.82746 20.4696 8.60929 21.422 10.5491 21.8079C12.4889 22.1937 14.4996 21.9957 16.3268 21.2388C18.1541 20.4819 19.7159 19.2002 20.8147 17.5557C21.9135 15.9112 22.5 13.9778 22.5 12C22.5 10.6868 22.2413 9.38642 21.7388 8.17317C21.2363 6.95991 20.4997 5.85752 19.5711 4.92893C18.6425 4.00035 17.5401 3.26375 16.3268 2.7612C15.1136 2.25866 13.8132 2 12.5 2ZM12.5 20C10.9178 20 9.37104 19.5308 8.05544 18.6518C6.73985 17.7727 5.71447 16.5233 5.10897 15.0615C4.50347 13.5997 4.34504 11.9911 4.65372 10.4393C4.9624 8.88743 5.72433 7.46197 6.84315 6.34315C7.96197 5.22433 9.38743 4.4624 10.9393 4.15372C12.4911 3.84504 14.0997 4.00346 15.5615 4.60896C17.0233 5.21447 18.2727 6.23984 19.1518 7.55544C20.0308 8.87103 20.5 10.4177 20.5 12C20.5 14.1217 19.6572 16.1566 18.1569 17.6569C16.6566 19.1571 14.6217 20 12.5 20ZM16.5 11H13.5V8C13.5 7.73478 13.3946 7.48043 13.2071 7.29289C13.0196 7.10536 12.7652 7 12.5 7C12.2348 7 11.9804 7.10536 11.7929 7.29289C11.6054 7.48043 11.5 7.73478 11.5 8V11H8.5C8.23479 11 7.98043 11.1054 7.7929 11.2929C7.60536 11.4804 7.5 11.7348 7.5 12C7.5 12.2652 7.60536 12.5196 7.7929 12.7071C7.98043 12.8946 8.23479 13 8.5 13H11.5V16C11.5 16.2652 11.6054 16.5196 11.7929 16.7071C11.9804 16.8946 12.2348 17 12.5 17C12.7652 17 13.0196 16.8946 13.2071 16.7071C13.3946 16.5196 13.5 16.2652 13.5 16V13H16.5C16.7652 13 17.0196 12.8946 17.2071 12.7071C17.3946 12.5196 17.5 12.2652 17.5 12C17.5 11.7348 17.3946 11.4804 17.2071 11.2929C17.0196 11.1054 16.7652 11 16.5 11Z"
  //         fill="#147CBC"
  //       />
  //     </svg>
  //     <span className="text-blueDark text-lg font-medium font-dmSans leading-7">
  //       Add contact person
  //     </span>
  //   </div>
  // );

  
  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
           <img src={eclipse} alt="eclipse" className="mt-2"/> 

          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Basic Information
          </div>
        </div>
      ),

      children:
        !viewBasic ? (
          <BasicInfo />
        ) : editBasic ? (
          <BasicInfo />
        ) : clientEditId || viewBasic ? (
          <BasicInfoView />
        ) : null, 
      extra: (viewBasic &&  checkEditPermission(menuSidebarData,"EMPLOYER"))? genExtraBasic() : "",
      showArrow: viewBasic ? false : true,
      style: panelStyle,
    },

    {
      key: "2",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          <img src={eclipse} alt="eclipse" className="mt-2"/> 
          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Location
          </div>
        </div>
      ),

      children:
        !viewLocation  ? (
          <Location />
        ) : editLocation ? (
          <Location />
        ) : clientEditId || viewLocation ? (
          <LocationInfoView />
        ) : null,
      extra: (viewLocation &&  checkEditPermission(menuSidebarData,"EMPLOYER")) ? genExtraLocation() : "",
      showArrow: viewLocation ? false : true,
      style: panelStyle,
    },

    {
      key: "3",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
         <img src={eclipse} alt="eclipse" className="mt-2"/> 
          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Contact
          </div>
        </div>
      ),

      children:
        !viewContact ? (
          <Contact />
        ) : editContact ? (
          <Contact />
        ) : clientEditId || viewContact ? (
          <ContactInfoView />
        ) : null,
      extra: (viewContact &&  checkEditPermission(menuSidebarData,"EMPLOYER")) ? genExtraContact() : "",
      showArrow: viewContact ? false : true,
      style: panelStyle,
    },
  ];

 

  return (
    <Card className="">
      <div className="flex flex-col w-[100%]  ">
        {!clientEditId && (
          <div className="text-blackest text-[1rem] font-medium font-dmSans leading-[30px] mt-[30px] mb-[44px]">
            Please fill in the following details
          </div>
        )}

        <Collapse
          bordered={false}
          expandIconPosition="end"
          activeKey={["1", "2", "3"]} 
          style={{
            background: token.colorBgContainer,
          }}
          items={getItems(panelStyle)}
        />
      </div>

      {!clientEditId && (
        <div className="text-center">
          <button
            className="w-[168px] save-btn"
            onClick={() => navigate("/client-list")}
          >
            Done
          </button>
        </div>
      )}
    </Card>
  );
};

export default ClientAdd;
