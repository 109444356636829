import { Button, Form, Input, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchApplicantsById,
  fetchApplicantsDetails,
} from "../../../../../features/applicant/applicantSlice";
import { dateForHumans } from "../../../../../utils";

function ApplicantDetailsView() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setdata] = useState({});
  const onFinish = async (data) => {};

  const { user } = useSelector((state) => state.auth);
  const { refetch, refetchKey } = useSelector((state) => state.cv);
  const { singleCreateApplicant, applicantEditId, applicantsById } =
    useSelector((state) => state.applicant);

  const [applicantsList, setApplicantsList] = useState(applicantsById);
  const [receivedId, setReceivedId] = useState(
    applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
  );

  useEffect(() => {
    setReceivedId(
      applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
    );
  }, [applicantEditId, singleCreateApplicant?.usrId]);

  useEffect(() => {
    setApplicantsList(applicantsById);
  }, [applicantsById]);

  // useEffect(() => {
  //   if(refetchKey == 1){
  //     dispatch(
  //       fetchApplicantsById({
  //         usrId:receivedId,
  //       })
  //     );
  //   }

  // }, [refetch,refetchKey,applicantEditId,singleCreateApplicant?.usrId]);

  return (
    <div className="mt-[38px]">
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-2">
          <div className="grid grid-cols-2">
            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Full Name
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {applicantsList?.usrFirstName} {applicantsList?.usrLastName}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              NEA Registration No.
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {applicantsList?.usrCode ?? 'N/A'}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Gender
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {applicantsList?.usrGender ?? 'N/A'}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              ID No.
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {applicantsList?.usrNationalId ?? 'N/A'}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Passport No.
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {applicantsList?.usrPassportNo ?? 'N/A'}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Date of Birth
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {dateForHumans(applicantsList?.usrDob) ?? 'N/A'}
            </div>
          </div>

          <div className="grid grid-cols-2">
            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Nationality
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {applicantsList?.usrNationality ?? 'N/A'}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Email
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {applicantsList?.usrEmail ?? 'N/A'}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Phone No.
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {applicantsList?.usrMobileNumber ?? 'N/A'}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Country
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {applicantsList?.usrNationality ?? 'N/A'}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              County
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {applicantsList?.usrCounty ?? 'N/A'}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default ApplicantDetailsView;
