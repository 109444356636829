import React, { useRef } from "react";
import { Form, Input, Modal, Button, Switch,Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast"

const DeleteRevampedModal = ({
  isModalOpen,
  setIsModalOpen, 
  handleDelete,
  content,
  contentSub,
  title,
  loading,
  icon,
  btnTitle
}) => {
  const dispatch =  useDispatch();
 
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false); 
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
 
  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer activity activity-header activity-close"
        title={title}
        open={isModalOpen}
        onCancel={handleCancel}
        width={810}
      >
        <div className="flex justify-center items-center mt-[51px]">
          <div className="w-[2.75rem] h-[2.75rem] rounded-full  border border-[#616161] flex items-center justify-center">
            <img src={icon} alt="icon"/>
          </div>
        </div>

        <div className="mt-[1.5rem] text-center text-[#333] text-[1.25rem] font-[500] font-dmSans leading-loose">
         {content}
        </div>

        <div className="mt-[0px] text-center text-[#333] text-[1rem]  font-[400] font-dmSans leading-loose">
         {contentSub}
        </div>
        <div className="flex flex-col items-center mt-[63px] gap-y-2 mb-[66px]">
          <button
            className={`w-[250px] ${btnTitle === "Delete account" ? '!bg-[#B71724]' : 'inherit'} save-btn`}
            type="submit"
            onClick={handleDelete} 
            disabled={loading}
          >
            {
              loading? <Spin/> : btnTitle
            }
            
          </button>

          <button
           className="w-[250px] cancel-btn border border-blueDark flex items-center justify-center"
            onClick={handleCancel}
          >
            Back
          </button>
        </div>
      </Modal>
    </>
  );
};
export default DeleteRevampedModal;
