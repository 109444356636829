import React, { useEffect, useState } from "react";
import "quill/dist/quill.snow.css"; 
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

const TextEditorView = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { singleCreateJob, jobEditId, jobData, jobLoading, skillsData,singleJob } =
    useSelector((state) => state.jobs);

  

  const addDotToLI = (htmlContent) => { 
    const modifiedContent = htmlContent?.replace(/<li>/g, "<li> . ");
    return modifiedContent;
  };
 
  const processHtmlContent = (htmlContent) => { 
    const correctedContent = htmlContent
      ?.replace(/â\?¢/g, "•")  
      ?.replace(/â\?•/g, "•") 
      ?.replace(/â€™/g, "'")  
      ?.replace(/â€œ/g, '"')  
      ?.replace(/â€/g, '"')
      ?.replace(/Ã¢\?\?/g, ''); 
   
    const modifiedContent = correctedContent?.replace(/<li>/g, "<li> • ");
  
    return modifiedContent;
  };
  
  
  

  
  const requirements = singleJob?.jobRequirements
    ?.replace(/<\/?p>/g, "")
    .split("\n")
    .filter((line) => line.trim() !== "");
  const summary = singleJob?.jobBrief
    ?.split("</p>")
    .filter((line) => line.trim() !== "");
  const responsibilities = singleJob?.jobResponsibilities
    ?.split("</p>")
    .filter((line) => line.trim() !== "");
  const apply = singleJob?.jobApplicationProcess
    ?.replace(/<\/?p>/g, "")
    .split("\n")
    .filter((line) => line.trim() !== "");

  return (
    <div className="px-[30px] ul-disk">
      <div className="text-blackest text-[21px] font-[600] font-dmSans leading-loose">
        Job Summary
      </div>
      <div className="flex flex-col gap-y-[18px]  mb-[2%]">
        {
          <div
            dangerouslySetInnerHTML={{
              __html: processHtmlContent(singleJob?.jobBrief),
            }}
          />
        }
      </div>

      <div className="text-blackest text-[21px] font-[600] font-dmSans leading-loose ">
        Job Description  
      </div>
      <div className="flex flex-col gap-y-[18px] mb-[2%]">
        {
         
          <div
            dangerouslySetInnerHTML={{
              __html: processHtmlContent(singleJob?.jobRequirements),
            }}
          />
        }
      </div>

      <div className="text-blackest text-[21px] font-[600] font-dmSans leading-loose">
        Key Responsibilities
      </div>
      <div className="flex flex-col gap-y-[18px]  mb-[2%]">
        {
         
          <div
            dangerouslySetInnerHTML={{
              __html: processHtmlContent(singleJob?.jobResponsibilities),
            }}
          />
        }
      </div>

    
    </div>
  );
};

export default TextEditorView;
