import { Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { fetchEmployees } from "../../../../features/myEmployees/myEmployeesSlice";
import { useDispatch, useSelector } from "react-redux";

function JobDetailsView() {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const { employeesData, employeeLoading, singleEmployee, employeeEditId } =
    useSelector((state) => state.employee);
  const { user } = useSelector((state) => state.auth);
  async function fetchEmployeeDetails() {
    await dispatch(
      fetchEmployees({
        emplInstId: user?.usrInstId,
      })
    );
  }

  const [receivedEditId, setReceivedEditId] = useState(
    Object?.keys(singleEmployee)?.length > 0
      ? singleEmployee?.emplId
      : employeeEditId
  );

  const singleEmployeeInfo = employeesData?.find(
    (item) => item?.emplId === receivedEditId
  );

  useEffect(() => {
    setReceivedEditId(
      Object?.keys(singleEmployee)?.length > 0
        ? singleEmployee?.emplId
        : employeeEditId
    );
  }, [singleEmployee, employeeEditId]);

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);
  return (
    <>
      <div className="mt-[38px]">
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="grid grid-cols-2">
            <div className="grid grid-cols-2">
              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Highest Education Level
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singleEmployeeInfo?.emplHigherEducation}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Highest Training Award
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singleEmployeeInfo?.emplHighestTrainingAward}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Specific Skill Area
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singleEmployeeInfo?.emplSpecificSkillArea}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Job Title
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singleEmployeeInfo?.emplJobTitle}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Name of Employer
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singleEmployeeInfo?.emplInstId}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Employer Address
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singleEmployeeInfo?.emplEmployerAddress}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Employer Telephone
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singleEmployeeInfo?.emplEmployerPhone}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default JobDetailsView;
