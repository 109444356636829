import React from 'react'
import Header from '../../components/signup/Header'
import PasswordField from '../../components/signup/PasswordField'

function PasswordFields() {
  return (
    <div className='slanted-background'>
    <Header />
    <PasswordField />
</div>
  )
}

export default PasswordFields