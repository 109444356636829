import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import "../../../../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { saveJobApplication } from "../../../../../features/applicantJob/applicantJobSlice";
 

const { TextArea } = Input;
const InterviewScoreModal = ({
  isModalOpen,
  setIsModalOpen,
  applicantEdit,
  prodd,
  fetchFunction,
  fetchCount,
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { jobApplicationLoading } = useSelector((state) => state.applicantJob);
  const { agencyObj } = useSelector((state) => state.agency);
  const { singleJob } = useSelector((state) => state.jobs);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formdata, setFormData] = useState(applicantEdit);

  const onChange = async (e) => {
    if (e.target.value < 0 || e.target.value > 100) {
      return;
    }
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onChangeOther = (value, fieldName) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  function handleSelectChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      passportStatus: value,
    }));
  }

  const onFinish = async () => { 
    const res = await dispatch(
      saveJobApplication({
        japId: applicantEdit?.japId,
        japProgressStatus: prodd,
        jobUpdatedBy: user?.usrId,
        japInterviewScore: formdata?.japInterviewScore,
        japInterviewRemarks: formdata?.japInterviewRemarks,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      form.resetFields();
      setFormData("");
      fetchFunction();
      fetchCount();
      handleCancel();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  useEffect(() => {
    setFormData(applicantEdit);
  }, [applicantEdit]);

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={
          "Add Interview Score for candidate" + " " + applicantEdit?.japFullName
        }
        open={isModalOpen}
        onCancel={handleCancel}
        width={850}
      >
        <div className="">
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            className=""
            style={{
              maxWidth: "100%",
            }}
            form={form}
          >
            <Form.Item
              label="Score (%)"
              rules={[
                {
                  required: true,
                  message: "Please add score",
                },
              ]}
            >
              {/* <Input
                required
                name="japInterviewScore"
                onChange={onChange}
                value={formdata?.japInterviewScore} 
                max={100}
                min={0} 
                className="rounded-[4px] h-[52px] w-full border border-black"
              /> */}

              <InputNumber
                required
                name="japInterviewScore"
                value={formdata?.japInterviewScore}
                min={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => onChangeOther(value, "japInterviewScore")}
                className="input"
              />
            </Form.Item>

            <Form.Item
              label="Interview Comment"
              rules={[
                {
                  required: true,
                  message: "Please add interview comments",
                },
              ]}
            >
              <TextArea
                required
                name="japInterviewRemarks"
                onChange={onChange}
                value={formdata?.japInterviewRemarks}
                rows={6}
                className="rounded-[4px] h-[52px] w-full border border-black"
              />
            </Form.Item>

            {/* {agencyObj?.instLicId === 3 && singleJob?.jobCountry != "Kenya" && (
              <Form.Item
                label="Passport Status"
                rules={[
                  {
                    required: agencyObj.instLicId === 3 ? true : false,
                    message: "Please select passport status",
                  },
                ]}
              >
                <Select
                  required
                  name="passportStatus"
                  value={formdata?.passportStatus}
                  className="activity-select-tag activity-select-icon h-[44px] w-full"
                  allowClear
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  placeholder="Please select"
                  onChange={(value) => {
                    handleSelectChange(value);
                  }}
                  options={
                    passportData?.length > 0 &&
                    passportData?.map((item) => ({
                      value: item?.value,
                      label: item?.label,
                    }))
                  }
                  onDropdownVisibleChange={() => {}}
                />
              </Form.Item>
            )} */}

            <div className="flex flex-col items-center mt-[100px] gap-y-2 mb-[66px]">
              <button type="submit" key="submit" className="w-[200px] save-btn">
                {jobApplicationLoading ? <Spin /> : "Save"}
              </button>
              <button
                type="button"
                key="back"
                onClick={handleCancel}
                className="w-[200px] cancel-btn border border-blueDark flex items-center justify-center"
              >
                Cancel
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default InterviewScoreModal;
