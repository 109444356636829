import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  filePath: "",
  directors: [],
  isLoading: false,
  message: "",
  editId: 0,
  manager: {},
  agency: [],
  agencyCompanyReg: {},
  crTwelve: {},
  taxCompliance: {},
  businessReg: {},
  leaseAgreement: {},
};

export const saveFile = createAsyncThunk(
  "applicationSlice/saveFile",
  async (data) => {
    const res = await axios.post(`${url}/nea/saveFile.action`, data);
    return res.data;
  }
);

export const saveDirectorAndManager = createAsyncThunk(
  "applicationSlice/saveDirectorAndManager",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveSecretariat.action`, data);
    return res.data;
  }
);

export const fetchDirectorAndManager = createAsyncThunk(
  "applicationSlice/fetchDirectorAndManager",
  async (secInstId) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchSecretariats.action?secInstId=${secInstId}&secType=DIRECTOR`
    );
    return res.data;
  }
);

export const fetchManager = createAsyncThunk(
  "applicationSlice/fetchManager",
  async (secInstId) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchSecretariats.action?secInstId=${secInstId}&secType=MANAGER`
    );
    return res.data;
  }
);

export const saveAgencyDetails = createAsyncThunk(
  "applicationSlice/saveDirectorAndManager",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveSecretariat.action`, data);
    return res.data;
  }
);

export const saveAgencyInfo = createAsyncThunk(
  "applicationSlice/saveAgencyInfo",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveAgencyDetail.action`, data);
    return res.data;
  }
);

export const fetchAgencyDetails = createAsyncThunk(
  "applicationSlice/fetchAgencyDetails",
  async (obj) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchAgencyDetails.action?agcInstId=${obj?.agcInstId}&agcAdsId=${obj?.agcAdsId ?? ''}`
    );
    return res.data;
  }
);

export const deleteDirectorAndManager = createAsyncThunk(
  "applicationSlice/deleteDirectorAndManager",
  async (secId) => {
    const res = await axiosInstance.get(
      `${url}/nea/deleteSecretariat.action?secId=${secId}`
    );
    return res.data;
  }
);

export const applicationSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    setEditId: (state, action) => {
      state.editId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDirectorAndManager.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDirectorAndManager.fulfilled, (state, action) => {
        state.isLoading = false;
        state.directors = action?.payload?.data?.result;
        state.message = action?.payload?.messages?.message;
      })
      .addCase(fetchDirectorAndManager.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action?.payload?.messages?.message;
      })

      .addCase(fetchManager.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchManager.fulfilled, (state, action) => {
        state.isLoading = false;
        state.manager = action?.payload?.data?.result[0] || {};
        state.message = action?.payload?.messages?.message;
      })
      .addCase(fetchManager.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action?.payload?.messages?.message;
      })

      .addCase(fetchAgencyDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAgencyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        // const arrList = action?.payload?.data?.result || [];
        // state.agency = arrList;
        // state.agencyCompanyReg =
        //   arrList.find((item) => item?.agcType === "COMPANY_REGISTRATION") ||
        //   {};
        // state.crTwelve =
        //   arrList.find((item) => item?.agcType === "CR_TWELVE") || {};
        // state.taxCompliance =
        //   arrList.find((item) => item?.agcType === "TAX_COMPLIANCE") || {};
        // state.businessReg =
        //   arrList.find((item) => item?.agcType === "BUSINESS_REGISTRATION") ||
        //   {};
        // state.leaseAgreement =
        //   arrList.find((item) => item?.agcType === "LEASE_AGREEMENT") || {};
      })
      .addCase(fetchAgencyDetails.rejected, (state, action) => {
        state.isLoading = false;
        // state.agency = [];
        // state.agencyCompanyReg = {};
        // state.crTwelve = {};
        // state.taxCompliance = {};
        // state.businessReg = {};
        // state.leaseAgreement = {};
      });
  },
});

export default applicationSlice.reducer;
export const { handleSidenavFullWidth, setEditId } = applicationSlice.actions;
