import { Checkbox, Form } from "antd";
import React, { useEffect, useRef } from "react";
import {
  deleteRecruitmentProcessMenus,
  fetchRecruitmentProcessMenus,
  fetchRecruitmentProcesss,
  saveRecruitmentProcessMenu,
} from "../../../../features/settings/settingSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

function RecruitmentProcess({singleJobItem}) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const { recruitmentData, recruitmentProcess } = useSelector(
    (state) => state.setting
  );
  const { user } = useSelector((state) => state.auth);
  const { singleCreateJob, jobEditId } = useSelector((state) => state.jobs);
  const { agencyObj } = useSelector((state) => state.agency);
 

  const onChange = async (e, name, id, rpmId) => {
    if (e.target.checked) {
      const res = await dispatch(
        saveRecruitmentProcessMenu({
          rpmInstId: user?.usrInstId,
          rpmJobId: jobEditId ? jobEditId : singleCreateJob?.jobId,
          rpmStageName: name,
          rpmRptId: id,
        })
      );
      if (res?.payload?.success) { 
        fetchRecruitmentProcessMenusDetails();
      } else {
        toast.error(res?.payload?.messages?.message);
      }
    } else {
      const res = await dispatch(
        deleteRecruitmentProcessMenus({
          rpmId: rpmId,
        })
      );
      if (res?.payload?.success) { 
        fetchRecruitmentProcessMenusDetails();
      } else {
        toast.error(res?.payload?.messages?.message);
      }
    }
  };

 
  async function fetchRecruitmentProcesssDetails() {
    await dispatch(fetchRecruitmentProcesss({ 
      rtpLicenseType:agencyObj?.licenseName == 'LOCAL LICENSE' ? 'BOTH' : ''
    }));
  }

  async function fetchRecruitmentProcessMenusDetails() {
    await dispatch(
      fetchRecruitmentProcessMenus({
        rpmJobId: jobEditId ? jobEditId : singleCreateJob?.jobId,
      })
    );
  }

  useEffect(() => {
    fetchRecruitmentProcesssDetails();
  }, []);

  useEffect(() => {
    fetchRecruitmentProcessMenusDetails();
  }, []);

  return (
    <div className="mt-[38px] px-5">
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        // onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      ></Form>
      <div className="grid grid-cols-2 gap-y-5">
        { 
        recruitmentData?.length > 0 
        &&
          recruitmentData?.map((item) => {
            const isChecked = recruitmentProcess?.some(
              (processItem) => processItem?.rpmRptId === item?.rtpId
            );
            const rpmId = recruitmentProcess?.find(
              (processItem) => processItem?.rpmRptId === item?.rtpId
            )?.rpmId;  

            return (
              <Checkbox
                key={item?.rtpId}
                onChange={(e) =>
                  onChange(e, item?.rtpStageName, item?.rtpId, rpmId)
                }
                checked={isChecked}
                disabled={(
                  singleJobItem?.jobAdvertStatus === "ACTIVE" || new Date(singleJobItem?.jobAdvertEndDate) < new Date()) ?false : true}
              >
                {item?.rtpStage} 
              </Checkbox>
            );
          })}
      </div>
    </div>
  );
}

export default RecruitmentProcess;
