import { useSelector } from "react-redux";
import IncompleteDash from "./IncompleteDash";
import ActiveDashBoard from "./ActiveDashboard";
import { useEffect, useState } from "react";

export default function DashBoard() {
  const { agencyObj } = useSelector((state) => state.agency);
  const [processStep, setProcessStep] = useState(false);

  const statusList = [
    "AWAITING_BACKGROUND_CHECK_RESULTS",
    "SEND_TO_BACKGROUND_CHECK",
    "SEND_TO_ACCOUNT_INSPECTION",
    "SEND_TO_ACCOUNT_VETTING",
    "APPROVE_ACCOUNT_APPLICATION",
    "ACCOUNT_VERIFICATION",
    "APPROVE_ACCOUNT_CREATION",
    "UPDATED_ACCOUNT_CREATION",
    "REVOKE",
    "DECLINE_ACCOUNT_APPLICATION",
    "DEFERMENT",
    "PAYMENT_PENDING_CONFIRMATION",
    "REJECT_RECEIPT",
    "PARTIAL_APPROVE_ACCOUNT_VERIFICATION",
    "ACCOUNT_VERIFICATION_ASSIGNED",
    "PENDING",
  ];

  useEffect(() => {
    setProcessStep(statusList.includes(agencyObj?.instStatus));
  }, [agencyObj]);

  return processStep ? <IncompleteDash /> : <ActiveDashBoard />;
}
