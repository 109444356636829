import { useEffect, useState } from 'react';
import BillingInfo from './BillingInfo';
import { Button, Radio, Space, Spin } from 'antd';
import LicenseCard from '../../../application/steps/LicenseCard';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchAgencyLicense,
	fetchAgencyLicenseRenewal,
} from '../../../../features/license/licenseSlice';
import PaymentInfo from './PaymentInfo';
import PaymentOptions from './PaymentOptions';
import StepTwoInvoice from './StepTwoInvoice';
import {
	fetchAgencyData,
	saveAgency,
} from '../../../../features/agency/agencySlice';

export default function StepOne() {
	const dispatch = useDispatch();

	const { agentLicence, licenseLoading } = useSelector(
		(state) => state.license
	);
	const { agencyObj } = useSelector((state) => state.agency);
	const { user } = useSelector((state) => state.auth);

	const [currentStep, setcurrentStep] = useState(0);
	const [payOtion, setpayOtion] = useState(1);
	const [selectedValue, setSelectedValue] = useState('');

	function handlePayOption(val) {
		setpayOtion(val);
	}

	async function handleSelectLicense(val) {
		setSelectedValue(val);
		let instCopy = {
			instId: agencyObj?.instId,
			instLicId: Number(val),
		};
		await dispatch(saveAgency(instCopy));
		await dispatch(fetchAgencyData(user?.usrInstId));
	}

	async function handleFetchAgencyLicense() {
		if (
			agencyObj?.instStatus === 'RENEWING_LICENSE' ||
			agencyObj?.instStatus === 'EXPIRED'
		) {
			await dispatch(fetchAgencyLicenseRenewal());
		} else {
			await dispatch(fetchAgencyLicense());
		}
	}

	function handleStep() {
		setcurrentStep(0);
	}

	function handleStepOne() {
		setcurrentStep(1);
	}

	function handleStepTwo() {
		setcurrentStep(2);
	}

	function handleNext() {
		setcurrentStep((currentStep) => currentStep + 1);
	}

	const license = agentLicence?.find(
		(item) => item?.licId === agencyObj?.instLicId
	);

	const bgColorClass = (bgColor) => {
		return {
			backgroundColor: bgColor,
		};
	};

	useEffect(() => {
		handleFetchAgencyLicense();
	}, []);

	useEffect(() => {}, [agencyObj, agentLicence]);

	return (
		<>
			<div className='flex items-start'>
				<div className='flex flex-col items-center pr-10 h-full border-r-2 border-[#ECECEC]'>
					<div
						onClick={handleStep}
						className={
							currentStep === 0
								? 'billing-active'
								: currentStep > 0
								? 'billing-done'
								: 'billing'
						}>
						<span className='billing-active-icon rounded-full'>1</span>
						<span className='billing-active-text'>Billing Info</span>
					</div>
					<div
						onClick={handleStepOne}
						className={
							currentStep === 1
								? 'billing-active my-10'
								: currentStep > 1
								? 'billing-done my-10'
								: 'billing my-10'
						}>
						<span
							className={
								currentStep > 0
									? 'billing-active-icon rounded-full'
									: 'billing-icon rounded-full'
							}>
							2
						</span>
						<span
							className={
								currentStep > 0 ? 'billing-active-text' : 'billing-text'
							}>
							Invoice
						</span>
					</div>
					<div
						onClick={handleStepTwo}
						className={
							currentStep === 2
								? 'billing-active'
								: currentStep > 2
								? 'billing-done'
								: 'billing'
						}>
						<span
							className={
								currentStep > 1
									? 'billing-active-icon rounded-full'
									: 'billing-icon rounded-full'
							}>
							3
						</span>
						<span
							className={
								currentStep > 1 ? 'billing-active-text' : 'billing-text'
							}>
							Payment
						</span>
					</div>
				</div>

				<div className='mx-10 w-full flex flex-col'>
					{currentStep === 2 ? (
						<PaymentInfo handlePayOption={handlePayOption} />
					) : currentStep === 1 ? (
						<StepTwoInvoice />
					) : (
						<BillingInfo />
					)}

					<div className='my-14'>
						{currentStep === 2 ? null : (
							<Button
                title={selectedValue.length === 0 ? 'Select license to continue' : null}
								disabled={selectedValue.length === 0 && agencyObj?.instStatus === 'RENEWING_LICENSE'}
								className='flex justify-center items-center bg-blueDark rounded-[35px] text-white w-[200px] p-5 ml-4'
								onClick={handleNext}>
								{!'loading' ? <Spin /> : 'Next'}
							</Button>
						)}
					</div>
				</div>

				{currentStep === 0 ? (
					<>
						<div className='w-[75%] bg-[#FAFAFA] p-5'>
							<div className=' pl-[20%] w-full'>
								<h3 className='license-header mb-10'>{agencyObj?.instStatus === 'RENEWING_LICENSE' ? 'Select License' : 'License Summary'}</h3>

								{agencyObj?.instStatus === 'RENEWING_LICENSE' ? (
									<>
										{licenseLoading ? (
											<Spin size='large' />
										) : (
											<Radio.Group
												className=''
												name='radiogroup'
												value={selectedValue}
												size='large'
												onChange={(e) => handleSelectLicense(e.target.value)}>
												<Space direction='vertical'>
													{agentLicence?.map((item) => {
														return (
															<>
																<LicenseCard
																	style={bgColorClass(item?.licDesc)}
																	licenseType={item?.licType}
																	price={item?.licAmount}
																	selectedButton={item?.licId}
																/>
															</>
														);
													})}
												</Space>
											</Radio.Group>
										)}
									</>
								) : (
									<>
										{licenseLoading ? (
											<Spin size='large' />
										) : (
											<LicenseCard
												hide={true}
												style={bgColorClass(license?.licDesc)}
												licenseType={license?.licType}
												price={license?.licAmount}
												selectedButton={license?.licId}
											/>
										)}
									</>
								)}
							</div>
						</div>
					</>
				) : currentStep === 2 ? (
					<>
						<div className='w-[75%] bg-[#FAFAFA] p-5'>
							<h3 className='license-header mb-10'>Choosen License</h3>
							<div
								style={{
									borderRadius: '9.232px',
									border: '2.77px solid #0B1E2A',
								}}
								className='p-7 w-full bg-white'>
								<div>
									<div
										style={bgColorClass(license?.licDesc)}
										className='text-lg font-[600] rounded px-2 py-1 w-fit'>
										{license?.licType}
									</div>
									<div className='license-agent-text mb-3'>Agent License</div>
								</div>

								<div>
									<div className='font-bold text-3xl'>
										KES {license?.licAmount}
									</div>
									<p className='text-sm text-black'>Annual payment</p>
								</div>
							</div>
							<PaymentOptions amount={license?.licAmount} option={payOtion} />
						</div>
					</>
				) : null}
			</div>
		</>
	);
}
