import React from "react";
import backArrow from "../../../assets/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import MaterialIcon from "material-icons-react";
import DemandViewStepper from "./DemandViewStepper";
import { useSelector } from "react-redux";
import { formatPath } from "../../../utils";

function DemandLetterView() {
  const navigate = useNavigate();
  const {id} =  useParams();
  const { demandLetters } = useSelector((state) => state.letter);
 
  const singleDemandLetter = demandLetters?.filter((item)=>item.dmlId == id)
 
 
  return (
    <>
      <div className="mr-3 h-36  bg-white flex items-center justify-between">
        <div className="flex h-36  items-center ml-[54px]">
          <div className="text-blackest text-[26px] font-medium font-dmSans leading-9 flex items-center gap-2">
           {formatPath(singleDemandLetter[0].dmlFile)}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clip-path="url(#clip0_3343_23364)">
                <path
                  d="M3 17.2486V20.9986H6.75L17.81 9.93859L14.06 6.18859L3 17.2486ZM20.71 7.03859C21.1 6.64859 21.1 6.01859 20.71 5.62859L18.37 3.28859C17.98 2.89859 17.35 2.89859 16.96 3.28859L15.13 5.11859L18.88 8.86859L20.71 7.03859Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_3343_23364">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div className="flex-end mr-[26px] ">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => navigate("/letter-list")}
          >
            <div
              style={{
                height: "35px",
                width: "35px",
                background: "#EDF8FF",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={backArrow}
                alt="back-arrow"
                style={{ height: "40px", width: "40px" }}
              />
            </div>

            <span className="text-black2 text-[22px] font-bold font-dmSans leading-relaxed">
              Go back
            </span>
          </div>
        </div>
      </div>

      <DemandViewStepper title={singleDemandLetter[0].dmlFile}/>
    </>
  );
}

export default DemandLetterView;
