import React, { useState } from "react";
import { useDispatch } from "react-redux";
import UsersTable from "./UsersTable";
import UsersAddModal from "./UsersAddModal";
import Header from "./Header";
import InsideHeader from "../../components/InsideHeader";
import { Breadcrumb, Input } from "antd";
import { useNavigate } from "react-router-dom";

function UsersList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <InsideHeader
        title="List of Users"
        subtitle="Manage all users here"
        back={false}
      />

      <div className="mt-[2.5rem] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer "
                  onClick={() => navigate("/admin-landing")}
                >
                  Administrator
                </span>
              ),
            },
            {
              title: (
                <span className="text-blueDark font-dmSans">Users list</span>
              ),
            },
          ]}
        />
      </div>

      <UsersTable />

      <UsersAddModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
}

export default UsersList;
