import React, { useEffect, useRef, useState } from "react";
import backArrow from "../../../assets/Back.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Breadcrumb,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  theme,
} from "antd";
import AddEmployeeNameModal from "./modals/AddEmployeeNameModal";
import toast from "react-hot-toast";
import {
  fetchEmployees,
  saveEmployee,
  saveEmployeeNew,
} from "../../../features/myEmployees/myEmployeesSlice";
import { checkEditPermission, formatImgPath } from "../../../utils";
import {
  fetchAwards,
  fetchCourses,
  fetchGender,
  fetchGrades,
  fetchInstitutionTypes,
  fetchJobTypes,
  fetchMaritalStatus,
  fetchNationalitys,
  fetchStudyAreas,
} from "../../../features/dropDowns/dropDownSlice";
import { customToast } from "../../../notifications";
import dayjs from "dayjs";
import PhoneInput from "react-phone-input-2";

function AddEmployee() {
  const { token } = theme.useToken();
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { employeeLoading, employeeReturnsPage } = useSelector(
    (state) => state.employee
  );
  const { menuSidebarData } = useSelector((state) => state.admin);
  const {
    dropDownLoading,
    instTypes,
    studyAreas,
    awards,
    courses,
    grades,
    jobTypes,
    maritalStatus,
    genders,
    nationalityOptions,
  } = useSelector((state) => state.dropDown);
  const { user } = useSelector((state) => state.auth);
  const { agencyObj } = useSelector((state) => state.agency);

  const [open, setopen] = useState(false);
  const [data, setdata] = useState({});

  const today = new Date();
  let dd = String(today.getDate()).padStart(2, 0);
  let mm = String(today.getMonth() + 1).padStart(2, 0);
  let yyyy = today.getFullYear() - 18;

  let minDate = `${yyyy}-${mm}-${dd}`;

  const handleChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  function handleGetNames(values) {
    setdata(values);
    setopen(false);
  }

  const handleNumberChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      instWorkTel: String(e),
    }));
  };

  function handleGoBack() {
    navigate(-1);
  }

  function handleCancel() {
    if (employeeReturnsPage) {
      navigate("/employment-returns");
    } else {
      navigate("/employment-returns");
    }
  }

  async function handleFetchCourses(name) {
    let ardObj = awards?.find(
      (item) =>
        String(item?.awdName).toUpperCase() === String(name).toUpperCase()
    );
    await dispatch(fetchCourses(ardObj?.awdId));
  }

  const panelStyle = {
    marginBottom: 24,
    background: "#fff",
    borderRadius: token.borderRadiusLG,
    border: "1px solid #147CBC",
  };

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#147CBC" />
            <circle cx="12.7904" cy="12.7943" r="2.32558" fill="#147CBC" />
          </svg>

          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Employee Details
          </div>
        </div>
      ),
      children: (
        <div className="p-10">
          <span className="accordion-sub-title">Basic Info</span>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{
              maxWidth: "100%",
              marginTop: "30px",
            }}
            form={form}
          >
            <div className="grid grid-cols-2 gap-x-10 w-full mb-5">
              <Form.Item label="First Name">
                <Input
                  onChange={handleChange}
                  name="emplFirstName"
                  value={data?.emplFirstName}
                  className="input"
                />
              </Form.Item>
              <Form.Item label="Middle Name">
                <Input
                  onChange={handleChange}
                  name="emplMiddleName"
                  value={data?.emplMiddleName}
                  className="input"
                />
              </Form.Item>
              <Form.Item label="Last Name">
                <Input
                  onChange={handleChange}
                  name="emplLastName"
                  value={data?.emplLastName}
                  className="input"
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                name="emplDesc"
                label="Occupation Name"
              >
                <Input className="input" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="emplTitle"
                label="Job Title"
              >
                <Input className="input" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="emplNationalId"
                label="National ID"
              >
                <Input className="input" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="emplKraPin"
                label="KRA PIN"
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                name="emplEmploymentNo"
                label="Employee No."
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                label="Terms of Service"
                name="emplServiceTerms"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  className=""
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    jobTypes?.length > 0 &&
                    jobTypes?.map((item) => ({
                      value: item.jtpName,
                      label: item.jtpName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                name="emplGender"
                label="Gender"
                rules={[
                  {
                    required: true,
                    message: "Gender is required",
                  },
                ]}
              >
                <Select
                  placeholder="Select gender"
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    genders?.length &&
                    genders?.map((item) => {
                      return {
                        label: item?.gndName,
                        value: item?.gndName,
                      };
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                name="emplMaritalStatus"
                label="Marital Status"
                rules={[
                  {
                    required: false,
                    message: "Please select your marital status",
                  },
                ]}
              >
                <Select
                  placeholder="Select your marital status"
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    maritalStatus?.length &&
                    maritalStatus?.map((item) => {
                      return {
                        label: item?.marName,
                        value: item?.marName,
                      };
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                name="emplNationality"
                label="Nationality"
                rules={[
                  {
                    required: false,
                    message: "Nationality is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  maxCount={1}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  allowClear
                  options={
                    nationalityOptions?.length > 0 &&
                    nationalityOptions?.map((item) => ({
                      value: item?.natName,
                      label: item?.natName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                name="emplDob"
                label="Date of Birth"
              >
                <DatePicker maxDate={dayjs(minDate)} className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                name="emplContractCommencement"
                label="Hired Date"
              >
                <DatePicker maxDate={dayjs(new Date())} className="input" />
              </Form.Item>

              {/* <Form.Item
								rules={[
									{
										required: false,
										message: 'Field is required',
									},
								]}
								name='emplStatus'
								label='Employment Status'
							>
								<Select
									style={{
										width: '100%',
										height: '44px',
									}}
									options={employmentStatus}
									onChange={(value) => {
										handleChange(value);
									}}
									onDropdownVisibleChange={() => {
									}}
								/>
							</Form.Item> */}
            </div>

            <span className="accordion-sub-title">Highest Academic Info</span>

            <div className="grid grid-cols-2 gap-5 mb-5 w-full mt-5">
              <Form.Item
                label="Institution Type"
                name="emplInsttype"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  className="mt-2"
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    instTypes?.length > 0 &&
                    instTypes?.map((item) => ({
                      value: item.instpName,
                      label: item.instpName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                label="Area of Study"
                name="emplAreaOfStudy"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  className="mt-2"
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    studyAreas?.length > 0 &&
                    studyAreas?.map((item) => ({
                      value: item.stdyName,
                      label: item.stdyName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                label="Award"
                name="emplAward"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  className=""
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  onChange={(val) => {
                    handleFetchCourses(val);
                  }}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    awards?.length > 0 &&
                    awards?.map((item) => ({
                      value: item.awdName,
                      label: item.awdName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item label="Course">
                <Input
                  onChange={handleChange}
                  name="emplCourse"
                  value={data?.emplCourse}
                  className="input"
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                label="Grade"
                name="emplGrade"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  className=""
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    grades?.length > 0 &&
                    grades?.map((item) => ({
                      value: item.grdeName,
                      label: item.grdeName,
                    }))
                  }
                />
              </Form.Item>

              {/* <Form.Item
								rules={[
									{
										required: false,
										message: 'Field is required',
									},
								]}
								name='grdStartDate'
								label='Start Date'>
								<DatePicker className='input' />
							</Form.Item>

							<Form.Item
								rules={[
									{
										required: false,
										message: 'Field is required',
									},
								]}
								name='grdGradDate'
								label='Graduation Date'>
								<DatePicker className='input' />
							</Form.Item> */}
            </div>

            <span className="accordion-sub-title">
              Professional Training Info
            </span>

            <div className="grid grid-cols-2 gap-5 mb-5 w-full mt-5">
              <Form.Item
                label="Professional Qualification"
                name="emplProfessionalQualification"
                className="flex flex-col w-[100%]"
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                label="Highest Training by Employer"
                name="emplTrainingObtained"
                className="flex flex-col w-[100%]"
              >
                <Input className="input" />
              </Form.Item>
            </div>

            <span className="accordion-sub-title mt-10">
              Employee Contact Info
            </span>

            <div className="grid grid-cols-2 gap-x-10 w-full mt-5">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="emplEmail"
                label="Email"
              >
                <Input className="input" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="emplMobileNo"
                label="Phone number"
              >
                {/* <InputNumber
									maxLength={10}
									minLength={9}
									onChange={handleNumberChange}
									className='rounded-[8px] mt-2 h-[44px] w-full border border-black'
								/> */}
                <PhoneInput
                  country="ke"
                  onlyCountries={["ke"]}
                  countryCodeEditable={false}
                  className="input"
                />
              </Form.Item>
            </div>

            <div className="flex items-center justify-end mt-14 mb-14 w-full">
              <button
                onClick={handleCancel}
                disabled={employeeLoading}
                className="w-[200px] cancel-btn"
                type="button"
              >
                Cancel
              </button>
              {checkEditPermission(menuSidebarData, "EMPLOYMENT_RETURN") && (
                <button
                  disabled={employeeLoading}
                  className="w-[200px] save-btn"
                  type="submit"
                >
                  {employeeLoading ? <Spin /> : "Save"}
                </button>
              )}
            </div>
          </Form>
        </div>
      ),
      showArrow: true,
      style: panelStyle,
    },
  ];

  const onFinish = async (values) => {
    values.emplContractCommencement = values?.emplContractCommencement?.$d;
    values.emplDob = values?.emplDob?.$d;

    const res = await dispatch(
      saveEmployeeNew({
        ...data,
        ...values,
      })
    );
    if (res?.payload?.success) {
      await customToast({
        content: "Employees added",
        header: "Success",
        bdColor: "success",
      });
      await form.resetFields();
      if (employeeReturnsPage) {
        await navigate("/employment-returns");
      } else {
        navigate("/employees");
      }
    } else {
      await customToast({
        content: res?.payload?.messages?.message,
        header: "Error",
        bdColor: "error",
      });
    }
  };
  const onFinishFailed = (errorInfo) => {
    toast.error("Please fill in all required fields correctly.");
  };

  async function handleDropdownOptions() {
    await dispatch(fetchJobTypes());
    await dispatch(fetchMaritalStatus());
    await dispatch(fetchGender());
    await dispatch(fetchNationalitys());
    await dispatch(fetchInstitutionTypes());
    await dispatch(fetchAwards());
    await dispatch(fetchStudyAreas());
    await dispatch(fetchGrades());
  }

  useEffect(() => {
    handleDropdownOptions();
  }, []);

  useEffect(() => {}, [
    instTypes,
    studyAreas,
    awards,
    courses,
    grades,
    jobTypes,
    maritalStatus,
    genders,
    nationalityOptions,
  ]);

  useEffect(() => {
    if (Object.keys(data).length >= 3) {
      setopen(false);
    } else {
      setopen(true);
    }
  }, [data]);

  return (
    <>
      <div className="bg-white mr-3 mb-[10px] flex  flex-col h-[115px] px-[39px]">
        <div className="items-center flex text-center gap-x-5">
          <img
            className="h-[91px] w-[91px] rounded-full object-contain border-2 border-black2"
            src={
              data?.emplIcon
                ? formatImgPath(data?.emplIcon)
                : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
            }
            alt="employee-profile"
          />
          <span className="text-black1 text-2xl font-bold font-dmSans leading-[54px]">
            {data?.emplFirstName} {data?.emplMiddleName} {data?.emplLastName}
          </span>
        </div>

        <div className="mt-5">
          <Breadcrumb
            items={[
              {
                title: (
                  <span
                    className="font-dmSans cursor-pointer "
                    onClick={() => navigate("/employment-returns")}
                  >
                    Employees
                  </span>
                ),
              },

              {
                title: (
                  <span className="text-blueDark font-dmSans">
                    Add employees
                  </span>
                ),
              },
            ]}
          />
        </div>
      </div>

      <div className="bg-white mr-3 mb-[10px] flex items-center justify-between h-auto px-[39px]">
        <div className="flex flex-col w-[100%]">
          <h3 className="font-[700] text-[24px] text-black1 dash-title mt-[51px]">
            Fill in the following details
          </h3>
          <Collapse
            bordered={false}
            expandIconPosition="end"
            defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
            style={{
              background: "#fff",
            }}
            items={getItems(panelStyle)}
          />
        </div>
      </div>

      <AddEmployeeNameModal open={open} handleGetNames={handleGetNames} />
    </>
  );
}

export default AddEmployee;
