import React, { useEffect, useState } from "react";
import { fetchJobApplicationsApplicantById } from "../../../features/applicantJob/applicantJobSlice";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { formatImgPath, timeAmPm, timeAmPmUtc } from "../../../utils";
import moment from "moment";

function ApplicantTable({japJaaId}) {
  const { jobApplicationPerJob, jobApplicationLoading,applicantsCount } = useSelector((state) => state.applicantJob  );
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Applicant Name",
      render: (item) => (
        <> 
            <div className="flex items-center">
              <img
                className="w-9 h-9 rounded-full object-cover"
                src={
                  item?.usrProfileImage
                    ? formatImgPath(item?.usrProfileImage)
                    : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
                }
                alt="Avatar"
              />
              <span className="table-name mx-3">{item?.usrFirstName} {item?.usrLastName}</span>
            </div>  
        </>
      ),
      // width: "280px",
    },
    {
      title: "RSVP",
      render: (item) => {
        return (
          <div className={`px-[18px] py-[2px] flex justify-center items-center ${
            item?.japInterviewReservation === "YES" ? 'bg-[#02A548] text-white' :
            item?.japInterviewReservation === "NO" ? 'bg-[#D64747] text-white' :
            'bg-[#FEC84B]'
        } text-black1`}>
        
           {item?.japInterviewReservation}
           </div>
        );
      },
      // width: "180px",
    },
    
    {
      title: "Email",
      dataIndex: "usrEmail",
      // width: "280px",
    },
    {
      title: "Phone Number",
      dataIndex: "usrMobileNumber",
      // width: "280px",
    },
    {
      title: "Start Time",
      render:(item)=>{
        return(
          <div>{timeAmPm(item?.japInterviewStartTime)}</div>
        )
      },
      // width: "280px", 
    }, 
    {
      title: "End Time",
      render:(item)=>{
        return(
          <div>{timeAmPm(item?.japInterviewEndTime)}</div>
        )
      }, 
      // width: "280px",
    },
    {
      title: "Session",
      dataIndex: "japInterviewSession",
      // width: "280px",
      // ellipsis: true,
      // tooltip: { placement: "topLeft" },
    },

  ];

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);


  async function fetchCandidateJobApplications(page,size) {
    const res = await dispatch(
      fetchJobApplicationsApplicantById({
        japJaaId:japJaaId,
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }
  useEffect(()=>{
    fetchCandidateJobApplications()
  },[japJaaId])

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));
  return (
    <div>
    <Table
        rowSelection={true}
        size={"middle"}
        bordered={true}
        pagination={{
          position: ["bottomCenter"],
          current: pageIndex + 1,
          total: applicantsCount,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPageIndex(page - 1);
            setPageSize(size);   
            fetchCandidateJobApplications(page - 1, size); 
          },
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
        rowKey={(record) => record?.japId}
        columns={tableColumns}
        dataSource={jobApplicationPerJob}
        loading={jobApplicationLoading}
        scroll={"scroll"}
        className="custom-table"
      />
    </div>
  );
}

export default ApplicantTable;
