import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  notesData: [],
  noteLoading: false, 
};

export const saveNote = createAsyncThunk(
  "note/saveNote",
  async (data) => {
    const res = await axiosInstance.post(`${url}/emp/saveNote.action`, data);
    return res.data;
  }
);

export const fetchNotes = createAsyncThunk(
  "note/fetchNotes",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/emp/fetchNotes.action?notCntId=${data?.notCntId}`
    );
    return res.data?.data?.result;
  }
);

export const noteSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    
    
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveNote.pending, (state) => {
        state.noteLoading = true;
      })
      .addCase(saveNote.fulfilled, (state, action) => {
        state.noteLoading = false;
      })
      .addCase(saveNote.rejected, (state) => {
        state.noteLoading = false;
      })

      .addCase(fetchNotes.pending, (state) => {
        state.noteLoading = true;
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.noteLoading = false;
        state.notesData = action.payload;
      })
      .addCase(fetchNotes.rejected, (state) => {
        state.noteLoading = false;
      });
  },
});

export default noteSlice.reducer;
export const {
  handleSidenavFullWidth, 
} = noteSlice.actions;
