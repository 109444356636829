import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	fetchReceivedInboxs,
	inboxCleanUp,
	setInboxObj,
} from '../../features/inbox/inboxSlice';
import { Badge } from 'antd';
import { formatImgPath } from '../../utils';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import InboxMessages from './inbox_files/InboxMessages';

export default function Inbox() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { receivedInbox } = useSelector((state) => state.inbox);
	const { user } = useSelector((state) => state.auth);

	const [inboxHeader, setinboxHeader] = useState(receivedInbox);

	async function handleFetchListData() {
		await dispatch(inboxCleanUp());
		await dispatch(fetchReceivedInboxs(user?.usrId));
	}

	async function handleSingleHeader(item) {
		await dispatch(setInboxObj(item));
		await dispatch(await dispatch(fetchReceivedInboxs(user?.usrId)))
	}

	function handleGoBack() {
		navigate(-1);
	}

	useEffect(() => {
		handleFetchListData();
	}, []);

	let inboxHeaderCopy = [...inboxHeader]

	useEffect(() => {}, [inboxHeader]);

	useEffect(() => {
		setinboxHeader(receivedInbox);
	}, [receivedInbox]);

	return (
		<>
			<div className='bg-white min-h-full h-auto'>
				<div className='grid grid-cols-12'>
					<div className='col-span-4 px-7 min-h-[700px] h-full bg-white'>
						<div className='w-full flex justify-end mt-5 bg-blueDark p-3'>
							<button
								onClick={() => navigate('/notifications/compose')}
								type='button'
								className='border-none text-blueDark text-[21px] font-medium flex items-center'
							>
								<div className='bg-white p-3 flex justify-center items-center rounded-full'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
									>
										<path
											d='M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z'
											fill='#147CBC'
										/>
									</svg>
								</div>
							</button>
						</div>

						<div className='flex flex-col mt-9 h-[65vh]  max-h-[80vh] cv-scroll overflow-y-scroll'>
							{inboxHeader?.length ? (
								inboxHeaderCopy
									?.sort((a, b) => b.inbhUpdatedTime - a.inbhUpdatedTime)
									?.map((item) => {
										return (
											<>
												<button
													key={item?.inbhId}
													onClick={() => handleSingleHeader(item)}
													className='flex items-center justify-between border-b-2 border-[#ECECEC] pb-5 mb-5'
												>
													<div className='flex items-start w-[200px]'>
														{item?.inbhSenderIcon ? (
															<>
																<img
																	className='w-14 h-14 rounded-full object-fill'
																	src={formatImgPath(item?.inbhSenderIcon)}
																	alt='sender icon'
																/>
															</>
														) : (
															<UserOutlined className='text-[30px] border border-blueDark p-3 rounded-full object-fill' />
														)}

														<div className='flex flex-col justify-start items-start ml-3'>
															<span className='text-[22px] text-black333 font-bold text-left w-full truncate'>
																{/* {item?.sendernName} */}
																{item?.inbhName}
															</span>
															<span className='text-[18px] text-black333 font-medium text-left w-full truncate'>
																{/* {item?.sendernName} */}
																{item?.institutionName}
															</span>
															<span className='text-[14px] text-[#C0C0C0]'>
																{moment(item?.inbhUpdatedTime).format(
																	'DD/MM, h:mm A',
																)}
															</span>
														</div>
													</div>
													<Badge
														className=''
														count={item?.inbhChatsCount}
														style={{
															backgroundColor: '#0873b9',
														}}
													/>
												</button>
											</>
										);
									})
							) : (
								<div className='flex justify-center items-center flex-col'>
									<span className='mt-10 text-blueDark text-[21px] font-semibold text-center'>
										All your inbox headers <br /> will appear here
									</span>

									<button
										onClick={() => navigate('/notifications/compose')}
										className='get-started-btn mt-7 p-3'
										type='button'
									>
										Contact NEA Office
									</button>
								</div>
							)}
						</div>
					</div>

					<div className='col-span-8 w-full px-5 h-[85vh] max-h-[80vh] cv-scroll overflow-y-scroll'>
						<div className='w-[100%] bg-white p-5 flex justify-between items-center'>
							<span className='font-bold text-[26px] text-[#141414]'>
								Inbox
							</span>

							<div className='flex items-center'>
								<button
									type='button'
									onClick={handleGoBack}
									className='bg-transparent flex items-center'
								>
									<div className='flex justify-center items-center rounded-full w-[40px] h-[40px] p-2 bg-[#EDF8FF]'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='28'
											height='24'
											viewBox='0 0 28 24'
											fill='none'
										>
											<path
												d='M17.7692 18L11.0469 12L17.7692 6'
												stroke='#147CBC'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
											/>
										</svg>
									</div>
									<span className='ml-5 font-bold text-[22px] text-[#222222]'>
										Go Back
									</span>
								</button>
							</div>
						</div>
						<InboxMessages />
					</div>
				</div>
			</div>
		</>
	);
}
