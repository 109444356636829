import { Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEmployees,
  fetchSinglePlacements,
} from "../../../features/myEmployees/myEmployeesSlice";
import { calculateAge } from "../../../utils";

function EmployeeDetailsView() {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const { singlePlacement } = useSelector((state) => state.employee);

  return (
    <>
      <div className="mt-[38px]">
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="grid grid-cols-2">
            <div className="grid grid-cols-2">
              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Gender
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singlePlacement?.usrGender ?? "N/A"}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Age
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {calculateAge(singlePlacement?.usrDob) ?? "N/A"}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Passport Number
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singlePlacement?.usrPassportNo ?? "N/A"}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Next of Kin in Kenya
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
                {singlePlacement?.kinName ?? "N/A"}
              </div>

              <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[20px]">
                Next of Kin Contact
              </div>
              <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[20px]">
               {singlePlacement?.kinPhone ?? "N/A"}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default EmployeeDetailsView;
