import moment from 'moment';
 
import { useSelector } from 'react-redux';
import { calculateContractEndDate, numberWithCommas } from '../../../utils';

export default function EmploymentTermsDescription() {
	const { singleRunaway } = useSelector((state) => state.runaway);
	return (
		<>
			<div className='bg-white px-[.3rem] lg:px-[2.5rem] py-[2rem] w-full'>
				<div className='flex justify-between items-center'>
					<span className='heading_heading_4'>Employment Terms </span>
				</div>

				<div
					className='mt-[1.5rem]'
					style={{
						width: '100%',
						height: '0.0625rem',
						background: 'rgba(216, 216, 216, 0.54)',
					}}></div>

				<div className='mt-[2rem] grid grid-cols-1 gap-[1.5rem]'>
					<div className='flex flex-col'>
						<span className='label_label_1'>Salary</span>
						<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
						{singleRunaway?.jobCurrency} {numberWithCommas(singleRunaway?.jobGrossPay)}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Job title</span>

						<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
							{singleRunaway?.jobPosition ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Contract period</span>

						<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
							{singleRunaway?.jobDuration ?? 'N/A'} {singleRunaway?.jobDuration && "Months"} 
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Contract start date</span>

						<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
							{singleRunaway?.japValidityStartDate
								? moment(singleRunaway?.japValidityStartDate).format('LL')
								: 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Contract end date</span>

						<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
							{calculateContractEndDate(
								singleRunaway?.jobDuration,
								singleRunaway?.japValidityStartDate
							)}
						</span>
					</div>
				</div>
			</div>
		</>
	);
}
