import { Breadcrumb, Button } from "antd";
import React, { useEffect } from "react";
import MaterialIcon from "material-icons-react"; 
import backArrow from "../../assets/Back.svg";
import NeaTables from "../../standards/NeaTables";
import DemandTable from "./DemandTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDemandLetters } from "../../features/demand/demandLetterSlice";
import InsideHeader from "../../components/InsideHeader";
 
function DemandLettersList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { clientEditId,refetchKey, clientData, refetch, tabsActiveKey } = useSelector(
    (state) => state.client
  );

  const handleCancel = ()=>{
    if(clientEditId){ 
      navigate('/client-tabs')
    }else{
      navigate('/letter-list')
    }
   
  }

  return (
    <>
    <InsideHeader
        title="Demand Letter"
        subtitle="Here's is a list of all uploaded demand letters"
        back={false}
      />
 

    

      <div className="mt-[20px]">
        <DemandTable />
      </div>
    </>
  );
}

export default DemandLettersList;
