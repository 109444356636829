import { Button, Table, Spin, Dropdown,Form,	Input,
	Select, } from "antd";
import React, { useEffect, useRef, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanUpJobEditId,
  cleanupJob,
  fetchJobs,
  setEditJobDescription,
  setEditJobDetails,
  setEditTermsEmployment,
  setJobEditId,
  setViewJobDescription,
  setViewJobDetails,
  setViewModeTable,
  setViewTermsEmployment,
} from "../../../../features/jobs/jobSlice";
import { cashConverter, dateForHumans } from "../../../../utils";
import toast from "react-hot-toast";
import { Skeleton } from "antd"; 
import { fetchJobRecommendations } from "../../../../features/recommedations-referals/recommedationsSlice";
 
const options = [
  {
    label: 'Select 1',
    value: 1,
  },
  {
    label: 'Select 2',
    value: 2,
  },
  {
    label: 'Select 3',
    value: 3,
  },
];

function Recommedations() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
	const formRef = useRef(null);

  const { jobData, jobLoading } = useSelector((state) => state.jobs);

  const { recommedationData,recommedationLoading,refetch } = useSelector((state) => state.recommedation);

  const { user } = useSelector((state) => state.auth);

  const [applicantJobRecommedation, setApplicantJobRecommedation] = useState(
    recommedationData
  );
  useEffect(() => {
    setApplicantJobRecommedation(recommedationData);
  }, [recommedationData]);

  const [searchText, setSearchText] = useState("");

  const [open, setOpen] = useState(false);
  const [prodd, setProdd] = useState("");
  const clean = async () => {
    await dispatch(cleanUpJobEditId());
  };

  const showDrawer = async (id) => {
    await dispatch(setJobEditId(id));
    await dispatch(setViewJobDetails(true));
    await dispatch(setEditJobDetails(false));
    await dispatch(setViewTermsEmployment(true));
    await dispatch(setEditTermsEmployment(false));
    await dispatch(setViewJobDescription(true));
    await dispatch(setEditJobDescription(false));
    await dispatch(setViewModeTable(true));
    setOpen(true);
  };

  const columns = [
    {
      title: "Position name",
      render: (item) => (
        <>
          <div className="grid grid-cols-2">
            <div className="flex items-center">
              <span className="table-name mx-3">{item?.jobPosition} </span>
            </div>
            <div className="flex items-center">
              <button className="p-0 mx-4 flex items-end" type="button">
                <MaterialIcon
                  color="#000"
                  icon="visibility"
                  onClick={() => showDrawer(item?.japJobId)}
                />
              </button>
              <button className="p-0 m-0 flex items-end" type="button">
                <MaterialIcon color="#000" icon="more_vert" />
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Job Client",
      dataIndex: "japCntName",
    },
    {
      title: "Job Location",
      dataIndex: "jobCountry",
    },
    {
      title: "Vacancy Count	",
      dataIndex: "jobVacancyCount",
    },
    {
      title: "Salary",
      render: (item) => <div>{cashConverter(item?.jobGrossPay)}</div>,
    },
    {
      title: "Job Owner",
      render: (item) => {
        return <div>{user?.usrFullNames}</div>;
      },
    },
    {
      title: "Date Created",
      render: (item) => <div>{dateForHumans(item?.japCreatedDate)}</div>,
    },
  ];

  const filteredData = Array.isArray(applicantJobRecommedation)
    ? applicantJobRecommedation.filter((item) =>
        item?.jobPosition
          ?.toLowerCase()
          .includes((searchText || "").toLowerCase())
      )
    : [];

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const { applicantEditId, applicants, singleCreateApplicant } = useSelector(
    (state) => state.applicant
  );

  const [applicantsList, setapplicantsList] = useState(applicants);
  const [singleClient, setSingleClient] = useState("");
  const [receivedId, setReceivedId] = useState(
    applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
  );

  useEffect(() => {
    setapplicantsList(applicants);
  }, [applicants]);

  useEffect(() => {
    setReceivedId(
      applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
    );
  }, [applicantEditId, singleCreateApplicant?.usrId]);

  useEffect(() => {
    const singleItem = applicantsList?.find(
      (item) => item?.usrId == receivedId
    );
    setSingleClient(singleItem);
  }, [receivedId, applicantsList]);

  useEffect(() => {}, [singleClient]);

  function handleSelectChange(value) {
		setdata((prevData) => ({
			...prevData,
			objectName: value,
		}));
	}

  const [data, setdata] = useState({});
	const onFinish = async (data) => { 
	};

  async function applicantJobRecommendations(){
    dispatch(fetchJobRecommendations({
      jbrUsrId: applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
    }))
  }
  useEffect(() => {
    applicantJobRecommendations()
  }, [refetch]);

  return (
    <>
      {jobLoading ? (
        <>
          <Skeleton />
        </>
      ) : (
        <>
          <div className="min-h-[895px] bg-white border border-gray-200  mt-[1%]">
            <div className=" p-[50px]">
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <div
                    className={`text-black text-[22px] font-bold font-dmSans leading-[33px] mb-0`}
                  >
                    Recommended jobs
                  </div>

                  <div className="text-black text-[22px] font-normal font-dmSans leading-[33px] mb-[31px]  ">
                    Search jobs that may match this candidate
                  </div>
                </div>
                <div className="items-end flex gap-x-5 ">
                  <div className=" h-12 px-[18px] py-3 rounded-[46px]  justify-center items-center gap-2 inline-flex">
                    <div className="text-blueDark text-lg font-medium font-dmSans leading-normal cursor-pointer">
                      Reset
                    </div>
                  </div>
                  <div className=" h-12 px-[18px] py-3 rounded-[46px]  justify-center items-center gap-2 inline-flex cursor-pointer">
                    <div className="text-blueDark text-lg font-medium font-dmSans leading-normal">
                      Clear all
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between ">
                <div className="flex items-start">
                  <form class="flex items-center px-10">
                    <div className="flex ">
                    <Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					style={{
						maxWidth: '100%',
					}}
          className="flex gap-x-2 items-center flex-inline"
					form={form}
				>
			 
						<Select
               defaultValue="Job Position"
               className="recommedation-selector recommedation-selection recommedation-arrow recommedation-icon"
							style={{
								width: '197px',
								height: '52px',
							}}
							options={options}
							onChange={(value) => {
								handleSelectChange(value);
							}}
							onDropdownVisibleChange={() => {
								//trigger something
							}} 
						/>
			 
   
						<Select
               defaultValue="Employers"
               className="recommedation-selector recommedation-selection recommedation-arrow"
							style={{
								width: '197px',
								height: '52px',
							}}
							options={options}
							onChange={(value) => {
								handleSelectChange(value);
							}}
							onDropdownVisibleChange={() => {
								//trigger something
							}}
						/>
	  
						<Select
               defaultValue="Experience"
               className="recommedation-selector recommedation-selection recommedation-arrow"
							style={{
								width: '197px',
								height: '52px',
							}}
							options={options}
							onChange={(value) => {
								handleSelectChange(value);
							}}
							onDropdownVisibleChange={() => {
								//trigger something
							}}
						/> 
 
						<Select
               defaultValue="Salary Expectation"
               className="recommedation-selector recommedation-selection recommedation-arrow"
							style={{
								width: '197px',
								height: '52px',
							}}
							options={options}
							onChange={(value) => {
								handleSelectChange(value);
							}}
							onDropdownVisibleChange={() => {
								//trigger something
							}}
						/> 
 
						<Select
               defaultValue="Languages"
               className="recommedation-selector recommedation-selection recommedation-arrow"
							style={{
								width: '197px',
								height: '52px',
							}}
							options={options}
							onChange={(value) => {
								handleSelectChange(value);
							}}
							onDropdownVisibleChange={() => {
								//trigger something
							}}
						/> 
 
						<Select
               defaultValue="Skills"
               className="recommedation-selector recommedation-selection recommedation-arrow"
							style={{
								width: '197px',
								height: '52px',
							}}
							options={options}
							onChange={(value) => {
								handleSelectChange(value);
							}}
							onDropdownVisibleChange={() => {
								//trigger something
							}}
						/> 
         
						 <button
                      type="submit"
                      className="text-white text-[1rem] font-medium font-dmSans leading-normal mr-4
    w-[174px] h-[62px] px-[18px] py-[12px] bg-blueDark rounded shadow border border-[#F6F6F6] justify-center items-center gap-2 inline-flex"
                    >
                      Search job
                    </button> 

 
				 
				</Form>
                    </div>

                    {/* <div class="relative w-full">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 mr-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="voice-search"
                        className="bg-gray-50 !border-none text-black1 text-[19px] font-normal font-dmSans leading-7 rounded-lg  block w-full pl-10 p-2.5  h-[52px] px-[18px]
                        dark:placeholder-gray-400 dark:text-white "
                        placeholder="Looking for a specific job?"
                        required
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div> */}
                   
                  </form>
                </div>
              </div>
            </div>

            <div className="mt-[6px] ml-[45px] mr-[10px]">
              <Table
                rowSelection={true}
                size={"middle"}
                bordered={true}
                pagination={{
                  position: ["bottomCenter"],
                }}
                rowKey={(record) => record?.jbrId}
                columns={tableColumns}
                dataSource={filteredData}
                scroll={"scroll"}
                className="custom-table"
                loading={recommedationLoading}
              />
            </div>
          </div>
        </>
      )}

    </>
  );
}

export default Recommedations;
