import { Button, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRefetch, setRefetchKey } from "../../features/runaway/runawaySlice";
import { useNavigate } from "react-router-dom";
import InsideHeader from "../../components/InsideHeader";
import ForeignPlacementTable from "./ForeignPlacementTable";
import Runaway from "./Runaway";

function RunawayList() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleAddActivity = async () => {
    navigate("/activity-add");
  };

  const [active, setactive] = useState("1");

  async function refetching(activeKey) {  
    dispatch(setRefetch());
    dispatch(setRefetchKey(activeKey));
    await setactive(activeKey);
  }

  const { TabPane } = Tabs;

  const tabConfigurations = [
    {
      label: (
        <span className="flex items-center">
          <span className=" ">Foreign Placement</span>
        </span>
      ),
      key: "1",
      children: <ForeignPlacementTable/>,
    },

    {
      label: (
        <span className="flex items-center">
          <span className="">Abscondment</span>
        </span>
      ),
      key: "2",
      children:<Runaway/>,
    },
  ];

  return (
    <>
      <InsideHeader
        title="Abscondment"
        subtitle="Report abscondment employees here"
        back={false}
      />

      <div className="h-[111px] px-10">
        <Tabs
          items={tabConfigurations}
          defaultActiveKey="1"
          activeKey={active}
          onChange={refetching}
          className="activity-tab activity-tab-wrap activity-bar"
        />
      </div>

    
    </>
  );
}

export default RunawayList;
