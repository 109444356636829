import { Button, Table ,Spin} from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanUpJobEditId,
  cleanupJob,
  fetchJobs,
  setEditJobDescription,
  setEditJobDetails,
  setEditTermsEmployment,
  setHideTableDuringJobRecommedationApplicant,
  setJobEditId,
  setViewJobDescription,
  setViewJobDetails,
  setViewModeTable,
  setViewTermsEmployment,
} from "../../../../features/jobs/jobSlice";
import { cashConverter, dateForHumans } from "../../../../utils";  
import toast from "react-hot-toast";
import DrawerModal from "../../../jobs/job-adverts/view/DrawerModal";
import { setApplicantJobViewTable } from "../../../../features/cv/cvSlice";
import { Skeleton } from 'antd';

function ApplicantJobsTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleAddJob = async() => {
    await dispatch(setApplicantJobViewTable(true));
    await navigate("/job-table");
  };

  const { jobData, jobLoading,jobEditId } = useSelector((state) => state.jobs);

  const { applicantJobViewTable } = useSelector((state) => state.cv);

  const { user } = useSelector((state) => state.auth); 
 
  
  const { referallData,referalLoading} = useSelector((state) => state.recommedation);

  const [applicantJobReferalList,setApplicantJobReferal] =  useState(referallData)
  useEffect(()=>{
    setApplicantJobReferal(referallData)
  },[referallData])

  const [searchText, setSearchText] = useState("");

  const [open, setOpen] = useState(false);
  const [prodd, setProdd] = useState("");
  const clean = async () => {
    await dispatch(cleanUpJobEditId());
  };

  const showDrawer = async (id) => { 
    await dispatch(setHideTableDuringJobRecommedationApplicant(true))
    await dispatch(setJobEditId(id));
    await dispatch(setViewJobDetails(true));
    await dispatch(setEditJobDetails(false));
    await dispatch(setViewTermsEmployment(true));
    await dispatch(setEditTermsEmployment(false));
    await dispatch(setViewJobDescription(true));
    await dispatch(setEditJobDescription(false));
    await dispatch(setViewModeTable(true));
     setOpen(true);
  };

  const columns = [
    {
      title: "Position name",
      render: (item) => (
        <>
          <div className="grid grid-cols-2">
            <div className="flex items-center">
              <span className="table-name mx-3">{item?.jobPosition} </span>
            </div>
            {
            
              <div className="flex items-center">
              <button className="p-0 mx-4 flex items-end" type="button">
                <MaterialIcon
                  color="#000"
                  icon="visibility"
                  onClick={() => showDrawer(item?.jbrJobId)}
                />
              </button>
              <button className="p-0 m-0 flex items-end" type="button">
                <MaterialIcon color="#000" icon="more_vert" />
              </button>
            </div>
         
          }
          </div>
        </>
      ),
    },
    {
      title: "Job Client",
      dataIndex: "jobCntName",
    },
    {
      title: "Job Location",
      dataIndex: "jobCountry",
    },
    {
      title: "Vacancy Count	",
      dataIndex: "jobVacancyCount",
    },
    {
      title: "Salary",
      render: (item) => <div>{cashConverter(item?.jobGrossPay)}</div>,
    },
    {
      title: "Job Owner",
      render: (item) => {
        return <div>{user?.usrFullNames}</div>;
      },
    },
    {
      title: "Date Created",
      render: (item) => <div>{dateForHumans(item?.japCreatedDate)}</div>,
    },
  ];

  // const filteredData = searchText
  //   ? applicantJobReferalList?.filter((item) =>
  //       item?.jobPosition?.toLowerCase().includes(searchText.toLowerCase())
  //     )
  //   : applicantJobReferalList;


const filteredData = Array?.isArray(applicantJobReferalList)
  ? applicantJobReferalList?.filter((item) =>
      item?.jobPosition?.toLowerCase().includes((searchText || '').toLowerCase())
    )
  : [];


  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const { applicantEditId, applicants, singleCreateApplicant } = useSelector(
    (state) => state.applicant
  );

  const [applicantsList, setapplicantsList] = useState(applicants);
  const [singleClient, setSingleClient] = useState("");
  const [receivedId, setReceivedId] = useState(
    applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
  );

  useEffect(() => {
    setapplicantsList(applicants);
  }, [applicants]);

  useEffect(() => {
    setReceivedId(
      applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
    );
  }, [applicantEditId, singleCreateApplicant?.usrId]);

  useEffect(() => {
    const singleItem = applicantsList?.find(
      (item) => item?.usrId == receivedId
    );
    setSingleClient(singleItem);
  }, [receivedId, applicantsList]);

  useEffect(() => { 
  }, [singleClient]);



  return (
    <>
      {jobLoading ? (
        <>
         <Skeleton /> 
        </>
      ) : (
        <> 
          <div className="min-h-[895px] bg-white border border-gray-200  mt-[1%]">
            <div className=" p-[50px]">
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
              <div
                className={`text-black text-[22px] font-bold font-dmSans leading-[33px] mb-0`}
              >
               Jobs added to {singleClient?.usrFullNames}
              </div>
              
                <div className="text-black text-[22px] font-normal font-dmSans leading-[33px] mb-[31px]">
                {singleClient?.usrFullNames}{" "} has been invited to apply to the following jobs.  
                </div>
                </div>
                {
                  !jobEditId ? (
                    <div className="items-end flex">
                    <Button
                      className="w-[274px] rounded-[56px] h-14 px-[28px] py-[16px] bg-blueDark  shadow justify-center items-center gap-2 inline-flex
          !text-blue-50 text-[1rem] font-medium font-dmSans leading-7"
                      onClick={handleAddJob}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                          fill="#EDF8FF"
                        />
                      </svg>
                      Invite to another job
                    </Button>
                  </div>
                  ):(null)
                }
              
             </div>


              <div className="flex items-center justify-between ">
                <div className="flex items-start">
                  <form class="flex items-center px-10">
                    <div class="relative w-full">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 mr-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="voice-search"
                        className="bg-gray-50 !border-none text-black1 text-[19px] font-normal font-dmSans leading-7 rounded-lg  block w-full pl-10 p-2.5  h-[52px] px-[18px]
                        dark:placeholder-gray-400 dark:text-white "
                        placeholder="Looking for a specific job?"
                        required
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                    <button
                      type="submit"
                      className="text-white text-[1rem] font-medium font-dmSans leading-normal
    w-[194px] h-[52px] px-[18px] py-[19px] bg-blueDark rounded shadow border border-[#F6F6F6] justify-center items-center gap-2 inline-flex"
                    >
                      Search job
                    </button>
                  </form>
                </div> 
                   
              </div>
            </div>

            <div className="mt-[6px] ml-[45px] mr-[10px]">
              <Table
                rowSelection={true}
                size={"middle"}
                bordered={true}
                pagination={{
                  position: ["bottomCenter"],
                }}
                rowKey={(record) => record?.jobId}
                columns={tableColumns}
                dataSource={filteredData}
                scroll={"scroll"}
                className="custom-table"
                loading={jobLoading}
              />
            </div>

           
          </div>
        </>
      )} 
      <DrawerModal open={open} setOpen={setOpen} prodd={prodd} />
    </>
  );
}

export default ApplicantJobsTable;
