import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Modal, Select, Spin } from "antd";
import "../../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { fetchJobs } from "../../../features/jobs/jobSlice";
import {
  fetchPanels,
  savePanel,
  setPanelDetails,
} from "../../../features/panel/panelSlice";

const AddPanelModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setdata] = useState({});
  const { jobData, jobLoading, jobEditId, singleCreateJob } = useSelector(
    (state) => state.jobs
  );

  const { panelDetails, panelActionLoading, panelInfoRedux } = useSelector(
    (state) => state.panel
  );
  const [formData, setFormData] = useState({ ...panelInfoRedux });
  function handleSelectChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      paJobId: value,
    }));
  }
  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  async function fetchJobsDetails() {
    const res = await dispatch(
      fetchJobs({
        jobInstId: user?.usrInstId,
        jobCntId: "",
      })
    );
  }

  async function fetchPanelDetailsData() {
    await dispatch(
      fetchPanels({
        paInstId: user?.usrInstId,
      })
    );
  }

  const onFinish = async () => {
    const res = await dispatch(
      savePanel({
        paId: formData?.paId ? formData.paId : null,
        paJobId: jobEditId ? jobEditId : formData?.paJobId,
        paName: formData?.paName,
        paInstId: user?.usrInstId,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      fetchPanelDetailsData();
      setFormData("");
      form.resetFields();
      handleCancel();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  useEffect(() => {
    fetchJobsDetails();
  }, []);

  useEffect(() => {
    setFormData(panelInfoRedux);
  }, [panelInfoRedux]);
  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title="Add Panel"
        open={isModalOpen}
        onCancel={handleCancel}
        width={850}
      >
        <div className="">
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            className=""
            style={{
              maxWidth: "100%",
            }}
            form={form}
          >
            {!jobEditId && (
              <Form.Item
                label="Select Job Advert"
                rules={[
                  {
                    required: !jobEditId ? true : false,
                    message: "Please select job advert",
                  },
                ]}
              >
                <Select
                  className="activity-select-tag activity-select-icon"
                  allowClear
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  placeholder="Please select job advert"
                  onChange={(value) => {
                    handleSelectChange(value);
                  }}
                  value={formData?.paJobId}
                  name="paJobId"
                  options={
                    jobData?.length > 0 &&
                    jobData?.map((item) => ({
                      value: item?.jobId,
                      label: item?.jobName,
                    }))
                  }
                  onDropdownVisibleChange={() => {
                    fetchJobsDetails();
                  }}
                />
              </Form.Item>
            )}

            <Form.Item
              label="Panel name"
              rules={[
                {
                  required: true,
                  message: "Please add panel name",
                },
              ]}
            >
              <Input
                required
                name="paName"
                value={formData?.paName}
                onChange={onChange}
                className="input"
              />
            </Form.Item>
            <div className="flex justify-between py-[50px]">
              <div className="justify-start"></div>
              <div className="justify-end flex items-center">
                <button
                  type="button"
                  key="back"
                  onClick={handleCancel}
                  className="w-[200px] cancel-btn"
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  key="submit"
                  className="w-[200px] save-btn"
                  disabled={panelActionLoading}
                >
                  {panelActionLoading ? <Spin /> : "Save"}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default AddPanelModal;
