import { Table, Skeleton, Button, Dropdown, Input } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateAge,
  checkEditPermission,
  dateForHumans,
  formatImgPath,
} from "../../utils";
import MyEmployeesAddModal from "./my-employees/modals/MyEmployeesAddModal";
import {
  cleanMyEmployees,
  fetchForeignPlacements,
  savePlacementReturn,
  setEmployeeEditId,
  setEmployeePlacementObj,
  setIsLocal,
  setViewBAsic,
  setViewContract,
  setViewJob,
} from "../../features/myEmployees/myEmployeesSlice";
import ConfirmationModal from "../../components/ConfirmationModal";
import toast from "react-hot-toast";
import emptyContainer from "../../assets/Container.png";

function LocalPlacementTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const {
    employeesForeignPlacementData,
    employeeLoading,
    employeeReturnLoading,
    refetchKey,
    refetch,
    foreignEmployeeCount,
  } = useSelector((state) => state.employee);

  const { user } = useSelector((state) => state.auth);
  const { menuSidebarData } = useSelector((state) => state.admin);

  const [searchText, setSearchText] = useState("");

  const handleSendLocalReturns = async () => {
    const res = await dispatch(
      savePlacementReturn({
        pcrType: 2,
        pcrInstId: user?.usrInstId,
      })
    );
    if (res?.payload?.success) {
      toast.success("Successfully sent foreign placement returns");
      setIsModalOpenConfirmation(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const handleView = async (item) => {
    dispatch(setViewBAsic(true));
    dispatch(setViewJob(true));
    dispatch(setViewContract(true));
    dispatch(setEmployeePlacementObj(item));
    dispatch(setIsLocal(false));
    navigate("/placement-view");
  };
  const handleViewDropdown = async () => {
    dispatch(setViewBAsic(true));
    dispatch(setViewJob(true));
    dispatch(setViewContract(true));
    dispatch(setEmployeeEditId(prodd));
    navigate("/placement-view");
  };

  const [prodd, setProdd] = useState();

  async function cleanEmployeeDetails() {
    dispatch(cleanMyEmployees());
  }

  useEffect(() => {
    cleanEmployeeDetails();
  }, []);

  const columns = [
    {
      title: "Employee Name",
      render: (item) => (
        <>
          <div className="flex justify-between">
            <div className="flex items-center">
              <img
                className="w-9 h-9 rounded-full object-cover"
                src={
                  item?.usrProfileImage
                    ? formatImgPath(item?.usrProfileImage)
                    : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
                }
                alt="Avatar"
              />
              <span className="table-name mx-3">
                {item?.usrFirstName} {item?.usrLastName}
              </span>
            </div>
            <div
              className="flex items-center"
              onClick={() => setProdd(item?.usrId)}
            >
              <button className="p-0 mx-4 flex items-end" type="button">
                <MaterialIcon
                  color="#000"
                  icon="visibility"
                  onClick={() => handleView(item)}
                />
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Gender",
      dataIndex: "usrGender",
    },
    {
      title: "Age",
      render: (item) => {
        return <div>{calculateAge(item?.usrDob)}</div>;
      },
    },
    {
      title: "Passport No.",
      dataIndex: "usrPassportNo",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
    },
    {
      title: "Highest Education Level",
      dataIndex: "educationLevel",
    },
    {
      title: "Highest Training Award",
      render: (item) => {
        return <div>{item?.achName ?? "N/A"}</div>;
      },
    },
    {
      title: "Name of Employer",
      dataIndex: "employerName",
    },
    {
      title: "Employer Address",
      dataIndex: "employerAddress",
    },
    {
      title: "Date of Departure from Kenya",
      render: (item) => (
        <div>{dateForHumans(item?.emplDepartureDate) ?? "N/A"}</div>
      ),
    },
    {
      title: "Contract Commencement",
      render: (item) => <div>{dateForHumans(item?.japValidityStartDate)}</div>,
    },
    {
      title: "Contract Duration in months",
      render: (item) => (
        <div className="flex items-center text-center gap-x-2">
          {item?.employmentDuration}
          <sub>(months)</sub>
        </div>
      ),
    },
    {
      title: "Contract Expiry",
      render: (item) => <div>{dateForHumans(item?.japValidityEndDate)}</div>,
    },
    {
      title: "Country of Work",
      dataIndex: "employerCountry",
    },
    {
      title: "Name of Next of Kin in Kenya",
      dataIndex: "kinName",
    },
    {
      title: "Next of Kin Contact",
      render: (item) => <div>+254 {item?.kinPhone}</div>,
    },
  ];

  const settingItems = [
    {
      key: "1",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={handleViewDropdown}
        >
          View
        </div>
      ),
    },
  ];

  const filteredData = searchText
    ? employeesForeignPlacementData?.filter((item) =>
        item?.emplFullNames?.toLowerCase().includes(searchText.toLowerCase())
      )
    : employeesForeignPlacementData;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
  const showModalConfirmation = async () => {
    setIsModalOpenConfirmation(true);
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchForeignPlacementsDetails(page, size) {
    await dispatch(
      fetchForeignPlacements({
        japInstId: user?.usrInstId,
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }

  useEffect(() => {
    if (refetchKey == 3) {
      fetchForeignPlacementsDetails();
    }
  }, [refetchKey, refetch]);

  return (
    <>
      <div className="px-[39px] mt-[14px]">
        {employeesForeignPlacementData &&
        employeesForeignPlacementData?.length > 0 ? (
          <>
            <h3 className="font-[700] text-[24px] text-black1 dash-title mt-[2.19rem]">
              List of foreign employees
            </h3>
            <div className="flex items-center justify-between w-full mt-[1.94rem]">
              <Input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search employee name or keyword"
                className="text-[16px] font-[400] flex-row-reverse"
                prefix={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                      fill="#333333"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                      fill="#333333"
                    />
                  </svg>
                }
              />
              {/* {checkEditPermission(menuSidebarData, "EMPLOYMENT_RETURN") && (
                <button
                  className="w-[289px] save-btn"
                  onClick={showModalConfirmation}
                >
                  Submit Returns
                </button>
              )} */}
            </div>

            <div className="mt-[20px]">
              {employeeLoading ? (
                <Skeleton />
              ) : (
                <Table
                  rowSelection={true}
                  size={"middle"}
                  bordered={true}
                  pagination={{
                    position: ["bottomCenter"],
                    current: pageIndex + 1,
                    total: foreignEmployeeCount,
                    pageSize: pageSize,
                    onChange: (page, size) => {
                      setPageIndex(page - 1);
                      setPageSize(size);
                      fetchForeignPlacementsDetails(page - 1, size);
                    },
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                  }}
                  rowKey={(record) => record?.usrId}
                  columns={tableColumns}
                  dataSource={filteredData}
                  scroll={{
                    x: "max-content",
                    // x:800
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <div className=" h-[885px] bg-white flex  flex-col">
            <div
              className=" bg-white full h-[471.33px] rounded-[15px] border border-solid
             border-[#F5F7FF] py-[60px] flex flex-col justify-center items-center cursor-pointer"
            >
              <div>
                <img
                  src={emptyContainer}
                  alt="group-pic"
                  className="object-contain h-[291.33px] w-[292px]"
                />
                <div className="empty-desc flex items-center justify-center">
                  No foreign placements
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <MyEmployeesAddModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        showModal={showModal}
      />

      <ConfirmationModal
        isModalOpen={isModalOpenConfirmation}
        setIsModalOpen={setIsModalOpenConfirmation}
        handleSubmit={handleSendLocalReturns}
        loading={employeeReturnLoading}
        content="Are you ready to submit the local placement returns"
      />
    </>
  );
}

export default LocalPlacementTable;
